import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmShellGrouping extends Component {
    static displayName = AdmShellGrouping.name;

    static modId = 0;
    static modName = "";
    static modArea = "";
    static modPortId = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmShellGrouping.modId = 0;
                AdmShellGrouping.modName = "";
                AdmShellGrouping.modArea = "";
                AdmShellGrouping.modPortId = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.groupings.find(x => x.grpId === parseInt(val));
                AdmShellGrouping.modId = myrec.grpId;
                AdmShellGrouping.modName = myrec.grpName;
                AdmShellGrouping.modArea = myrec.grpArea;
                AdmShellGrouping.modPortId = myrec.grpPortId;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmShellGrouping.groupingsave();
                await sleep(500);  // let the database catch up
                const data = await AdmShellGrouping.groupingGet();
                const dbgroupings = data.groupings;
                this.setState({ groupings: dbgroupings, loading: false, modal: false });
            } else {
                await AdmShellGrouping.groupingAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmShellGrouping.groupingGet();
                const dbgroupings = data.groupings;
                this.setState({ groupings: dbgroupings, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async groupingsave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/shellgroupings/' + AdmShellGrouping.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    grpId: AdmShellGrouping.modId, grpName: AdmShellGrouping.modName,
                    grpArea: AdmShellGrouping.modArea, grpPortId: AdmShellGrouping.modPortId
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async groupingAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/shellgroupings';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    grpName: AdmShellGrouping.modName,  
                    grpArea: AdmShellGrouping.modArea,
                    grpPortId: AdmShellGrouping.modPortId,
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async groupingDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/shellgroupings/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmShellGrouping.groupingDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmShellGrouping.groupingGet();
            const dbgroupings = data.groupings;
            this.setState({ groupings: dbgroupings, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { groupings: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static rendergroupingsTable(groupings, ports, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th data-sortable="true">grouping Name</th>
                            <th>Area Name</th>
                            <th>Port</th>
                            <th>Period</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupings.map((grouping) =>
                            <tr key={grouping.grpId}>
                                <td>{grouping.grpName}</td>
                                <td>{grouping.grpArea}</td>
                                <td>{(grouping.grpPortId == null)?"":ports.find(x => x.prtId == grouping.grpPortId).prtName}</td>
                                <td>{grouping.grpPeriod} </td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={grouping.grpId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={grouping.grpId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = [...this.state.groupings].sort((a, b) => a.grpName.localeCompare(b.grpName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmShellGrouping.rendergroupingsTable(oper, this.state.ports, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Shell Groupings &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>Shell uses groups like A or D to group the pricelists.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>grouping</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                grouping Name
                            </Label>
                            <Input onChange={(e) => AdmShellGrouping.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmShellGrouping.modName}
                            />
                            <Label for="modArea" style={{ "padding-top": "15px" }}>
                                grouping Area Name
                            </Label>
                            <Input onChange={(e) => AdmShellGrouping.modArea = e.target.value}
                                id="modArea"
                                type="text"
                                defaultValue={AdmShellGrouping.modArea}
                            />
                            <Label for="modPortId" style={{ "padding-top": "15px" }}>
                                PortId
                            </Label>
                            <Input onChange={(e) => AdmShellGrouping.modPortId = e.target.value}
                                id="modPortId"
                                type="text"
                                defaultValue={AdmShellGrouping.modPortId}
                            />
                            <Label for="modEmail" style={{ "padding-top": "15px" }}>
                                Email 
                            </Label>
                            <Input onChange={(e) => AdmShellGrouping.modEmail = e.target.value}
                                id="modEmail"
                                type="text"
                                defaultValue={AdmShellGrouping.modEmail}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmShellGrouping.modId}>
                            { (AdmShellGrouping.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async groupingGet() {
        const token = await authService.getAccessToken();

        const response = await fetch('mar1/shellgroupings', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbgroupings = await response.json();

        const response2 = await fetch('mar1/ports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbports = await response2.json();


        return { groupings: dbgroupings, ports: dbports };
    }

    async populateData() {
        const data = await AdmShellGrouping.groupingGet();
        const dbgroupings = data.groupings;
        const dbports = data.ports;
        this.setState({
            groupings: dbgroupings, ports: dbports,
            loading: false, modal: this.state.modal
        });
    }

}

