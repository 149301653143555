import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmIPL extends Component {
    static displayName = AdmIPL.name;

    static modId = 0;
    static modName = "";
    static modCountry = "";
    static modName2 = "";
    static modArea = "";
    static modPortId = "";
    static mdKg = "";
    static modLt = "";


    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmIPL.modId = 0;
                AdmIPL.modPortId = "";
                AdmIPL.modKg = "";
                AdmIPL.modLt = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.groupings.find(x => x.grpId === parseInt(val));
                AdmIPL.modId = myrec.spdId;
                AdmIPL.modPortId = myrec.spdPortID;
                AdmIPL.modkg = myrec.spdDiff100Kg;
                AdmIPL.modlt = myrec.spdDiff100Lt;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmIPL.groupingsave();
                await sleep(500);  // let the database catch up
                const data = await AdmIPL.diffGet();
                const dbgroupings = data.groupings;
                this.setState({ groupings: dbgroupings, loading: false, modal: false });
            } else {
                await AdmIPL.groupingAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmIPL.diffGet();
                const dbgroupings = data.groupings;
                this.setState({ groupings: dbgroupings, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async groupingsave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/shellgroupings/' + AdmIPL.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    grpId: AdmIPL.modId, grpName: AdmIPL.modName,
                    grpArea: AdmIPL.modArea, grpPortId: AdmIPL.modPortId
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async groupingAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/shellgroupings';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    grpName: AdmIPL.modName,  
                    grpArea: AdmIPL.modArea,
                    grpPortId: AdmIPL.modPortId,
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async groupingDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/shellgroupings/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmIPL.groupingDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmIPL.diffGet();
            const dbgroupings = data.groupings;
            this.setState({ groupings: dbgroupings, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { groupings: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static rendergroupingsTable(diffs, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th data-sortable="true">Port Name</th>
                            <th>Port Country</th>
                            <th>Port Name2</th>
                            <th>Port Area</th>
                            <th>Diff 100Kg</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {diffs.map((diff) =>
                            <tr key={diff.spdId}>
                                <td>{diff.portName}</td>
                                <td>{diff.portCountry}</td>
                                <td>{diff.portName2}</td>
                                <td>{diff.portArea}</td>
                                <td>{(diff.spdDiff100kg>0)?diff.spdDiff100kg:"STE"}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={diff.spdId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={diff.spdId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.diffs].sort((a, b) => a.portName.localeCompare(b.portName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmIPL.rendergroupingsTable(oper, this.handleModal, this.handleDelete);
        if ((!this.state.loading) && (pages > 1)) {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc + 1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                <PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Shell Port Differencials &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>Shell uses groups like A or D to group the pricelists.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>grouping</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                grouping Name
                            </Label>
                            <Input onChange={(e) => AdmIPL.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmIPL.modName}
                            />
                            <Label for="modArea" style={{ "padding-top": "15px" }}>
                                grouping Area Name
                            </Label>
                            <Input onChange={(e) => AdmIPL.modArea = e.target.value}
                                id="modArea"
                                type="text"
                                defaultValue={AdmIPL.modArea}
                            />
                            <Label for="modPortId" style={{ "padding-top": "15px" }}>
                                PortId
                            </Label>
                            <Input onChange={(e) => AdmIPL.modPortId = e.target.value}
                                id="modPortId"
                                type="text"
                                defaultValue={AdmIPL.modPortId}
                            />
                            <Label for="modEmail" style={{ "padding-top": "15px" }}>
                                Email 
                            </Label>
                            <Input onChange={(e) => AdmIPL.modEmail = e.target.value}
                                id="modEmail"
                                type="text"
                                defaultValue={AdmIPL.modEmail}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmIPL.modId}>
                            { (AdmIPL.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async diffGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/shellportdiffsex', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdiffs = await response.json();
        return { diffs: dbdiffs };
    }

    async populateData() {
        const data = await AdmIPL.diffGet();
        const dbdiffs = data.diffs;
        this.setState({
            diffs: dbdiffs,
            loading: false, modal: this.state.modal
        });
    }

}

