import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class MemVessels extends Component {
    static displayName = MemVessels.name;

    static modId = 0;
  //static modOperator = 0;
    static modCode = "";
    static modName = "";
    static modImo = "";
    static modMmsi = "";
    static modFlag = "";
    static modType = "";
    static modCompany = "";
    static modGtonnage = 0;
    static modYear = "";
    static modShipyard = "";
    static modHullNumber = "";

  //static fltOperator = 0;

    /* extra on Admin
    async handleFilter(event) {
        AdmVessels.fltOperator = event.target.value;
        const data = await AdmVessels.vesselGet();
        const dbvessels = data.vessels;
        this.setState({
            vessels: dbvessels,
            loading: false, modal: false
        });
    }
    */

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                MemVessels.modId = 0;
            //  AdmVessels.modOperator = 0;
                MemVessels.modCode = "";
                MemVessels.modName = "";
                MemVessels.modImo = "";
                MemVessels.modMmsi = "";
                MemVessels.modFlag = "";
                MemVessels.modType = "";
                MemVessels.modCompany = "";
                MemVessels.modGtonnage = 0;
                MemVessels.modYear = "";
                MemVessels.modShipyard = "";
                MemVessels.modHullNumber = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.vessels.find(x => x.vesId === parseInt(val));
                MemVessels.modId = myrec.vesId;
                //  AdmVessels.modOperator = myrec.vesOperator;
                MemVessels.modCode = myrec.vesCode;
                MemVessels.modName = myrec.vesCurrentName;
                MemVessels.modImo = myrec.vesImo;
                MemVessels.modMmsi = myrec.vesMmsi;
                MemVessels.modFlag = myrec.vesFlag;
                MemVessels.modType = myrec.vesType;
                MemVessels.modCompany = myrec.vesCompany;
                MemVessels.modGtonnage = myrec.vesGtonnage;
                MemVessels.modYear = myrec.vesYear;
                MemVessels.modShipyard = myrec.vesShipyard;
                MemVessels.modHullNumber = myrec.vesHullNumber;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val === "0") {
                const res = await MemVessels.vesselAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("vessel created successfully");
                }
                const data = await MemVessels.vesselGet();
                const dbvessels = data.vessels;
                this.setState({ vessels: dbvessels, loading: false, modal: false });
            } else {
                const res = await MemVessels.vesselSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("vessel updated successfully");
                }
                const data = await MemVessels.vesselGet();
                const dbvessels = data.vessels;
                this.setState({ vessels: dbvessels, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async vesselSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memvessels/' + MemVessels.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    vesId: MemVessels.modId, vesOperator: null,
                    VesCode: MemVessels.modCode, VesCurrentName: MemVessels.modName,
                    VesImo: MemVessels.modImo, VesMmsi: MemVessels.modMmsi,
                    VesFlag: MemVessels.modFlag, VesType: MemVessels.modType,
                    VesCompany: MemVessels.modCompany, VesGtonnage: MemVessels.modGtonnage,
                    VesYear: MemVessels.modYear, VesShipyard: MemVessels.modShipyard,
                    VesHullNumber: MemVessels.modHullNumber
                }
            )
        };
        const res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", reason: res.status };
        return { result: "ok", reason: "" };
    }

    static async vesselAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memvessels';
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    VesId: MemVessels.modId, VesOperator: null,
                    VesCode: MemVessels.modCode, VesCurrentName: MemVessels.modName,
                    VesImo: MemVessels.modImo, VesMmsi: MemVessels.modMmsi,
                    VesFlag: MemVessels.modFlag, VesType: MemVessels.modType,
                    VesCompany: MemVessels.modCompany, VesGtonnage: MemVessels.modGtonnage ?? 0,
                    VesYear: MemVessels.modYear, VesShipyard: MemVessels.modShipyard,
                    VesHullNumber: MemVessels.modHullNumber
                }
            )
        };
        var res = await fetch(callString, requestOptions);
        if (res.status !== 201) { 
            if (res.status == 500) {
                var t = await res.json();
                return { result: "error", reason: t.detail }
            }
            else
                return { result: "error", reason: "cannot create" }
        }
        return { result: "ok", reason: "" };
    }

    static async vesselDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/memvessels/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        var res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", reason: res.status };
        return { result: "ok", reason: "" };
    } 

    static async vesselRelease(id) {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memvessels/release/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", reason: res.status };
        return { result: "ok", reason: "" };
    }

    async handleDelete(event) 
    {
        let id = event.target.value;
        var res = await MemVessels.vesselDel(id);
        if (res.result === "error") {
            alert("Error: " + res.reason);
            return;
        }
        const data = await MemVessels.vesselGet();
        const dbvessels = data.vessels;
        this.setState({ vessels: dbvessels, loading: false, modal: false });
    }

    async handleRelease(event) {
        let id = event.target.value;
        var res = await MemVessels.vesselRelease(id);
        if (res.result === "error") {
            alert("Error: " + res.reason);
            return;
        }
        const data = await MemVessels.vesselGet();
        const dbvessels = data.vessels;
        this.setState({ vessels: dbvessels, loading: false, modal: false });
    }


    constructor(props) {
        super(props);
        this.state = {
            vessels: [],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleRelease = this.handleRelease.bind(this);
       //  this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderopprodrefTable(vessels, hModal, hDelete, hRelease) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Name</th>
                            <th>IMO</th>
                            <th>Year</th>
                            <th>Tonnage</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vessels.map((vessel) =>
                            <tr key={vessel.vesId}>
                                <td>{vessel.vesCode}</td>
                                <td>{vessel.vesCurrentName}</td>
                                <td>{vessel.vesImo}</td>
                                <td>{vessel.vesYear}</td>
                                <td>{vessel.vesGtonnage}</td>
                                <td> {vessel.vesIsActive
                                    ? (<div style={{ "height": "20", "width": 20, "backgroundColor": "green" }}>&nbsp;</div>)
                                    : (<div style={{ "height": "20", "width": 20, "backgroundColor": "red" }}>&nbsp;</div>)
                                }
                                </td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={vessel.vesId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    {vessel.vesIsActive
                                        ? <button className="btn btn-danger"
                                            onClick={hDelete} value={vessel.vesId}>
                                            &nbsp;&nbsp;Block&nbsp;&nbsp;
                                        </button>
                                        : <button className="btn btn-success"
                                            onClick={hRelease} value={vessel.vesId}>
                                            Activate
                                        </button>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var vessels = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.vessels].sort(
                (a, b) => a.vesCurrentName.localeCompare(b.vesCurrentName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                vessels = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                vessels = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : MemVessels.renderopprodrefTable(vessels,
                this.handleModal, this.handleDelete, this.handleRelease);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 id="tabelLabel" >Vessels &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>


















                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>vessel</ModalHeader>
                    <ModalBody>
                        <Form>











                            <Label for="modCode">
                                Code
                            </Label>
                            <Input onChange={(e) => MemVessels.modCode = e.target.value}
                                id="modCode"
                                type="text"
                                defaultValue={MemVessels.modCode}
                            />
                            <Label for="modName">
                                vessel Name
                            </Label>
                            <Input onChange={(e) => MemVessels.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={MemVessels.modName}
                            />
                            <div class="row">
                                <div class="col-lg-6">
                                    <Label for="modImo">
                                        IMO
                                    </Label>
                                    <Input onChange={(e) => MemVessels.modImo = e.target.value}
                                        id="modImo"
                                        type="text"
                                        defaultValue={MemVessels.modImo}
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <Label for="modMmsi">
                                        MMSI
                                    </Label>
                                    <Input onChange={(e) => MemVessels.modMmsi = e.target.value}
                                        id="modMmsi"
                                        type="text"
                                        defaultValue={MemVessels.modMmsi}
                                    />
                                </div>
                            </div>
                            <Label for="modFlag">
                                Flag
                            </Label>
                            <Input onChange={(e) => MemVessels.modFlag = e.target.value}
                                id="modFlag"
                                type="text"
                                defaultValue={MemVessels.modFlag}
                            />
                            <Label for="modType">
                                Type
                            </Label>
                            <Input onChange={(e) => MemVessels.modType = e.target.value}
                                id="modType"
                                type="text"
                                defaultValue={MemVessels.modType}
                            />
                            <Label for="modCompany">
                                Company
                            </Label>
                            <Input onChange={(e) => MemVessels.modCompany = e.target.value}
                                id="modCompany"
                                type="text"
                                defaultValue={MemVessels.modCompany}
                            />
                            <Label for="modGtonnage">
                                Gtonnage
                            </Label>
                            <Input onChange={(e) => MemVessels.modGtonnage = e.target.value}
                                id="modGtonnage"
                                type="text"
                                defaultValue={MemVessels.modGtonnage}
                            />
                            <Label for="modYear">
                                Year
                            </Label>
                            <Input onChange={(e) => MemVessels.modYear = e.target.value}
                                id="modYear"
                                type="text"
                                defaultValue={MemVessels.modYear}
                            />
                            <Label for="modShipyard">
                                Shipyard
                            </Label>
                            <Input onChange={(e) => MemVessels.modShipyard = e.target.value}
                                id="modShipyard"
                                type="text"
                                defaultValue={MemVessels.modShipyard}
                            />
                            <Label for="modHullNumber">
                                HullNumber
                            </Label>
                            <Input onChange={(e) => MemVessels.modHullNumber = e.target.value}
                                id="modHullNumber"
                                type="text"
                                defaultValue={MemVessels.modHullNumber}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemVessels.modId}>
                            { (MemVessels.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async vesselGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/memvessels/all', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvessels = await response.json();
        return { vessels: dbvessels };
    }

    async populateData() {
        const data = await MemVessels.vesselGet();
        const dbvessels = data.vessels;
        this.setState({
            vessels: dbvessels, loading: false, modal: this.state.modal
        });
    }

}

