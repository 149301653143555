import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class AdmVariance extends Component {
    static displayName = AdmVariance.name;

    static modId = 0;
    static modSupplier = 0;
    static modPeriod = 0;
    static modLargeArea = "";
    static modVariance = 0;

    static fltRef = "";
    static fltSupplier = 0;
    static fltPeriod = 0;
    static x = 0;

    async handleFilter(value) {
        AdmVariance.fltPeriod = value;
        const data = await AdmVariance.dataGet();
        const bvariance = data.variance;
        const variance = bvariance.filter(function (item) {
            return ( (item.varPeriod == parseInt(value))  );
        });
        this.setState({ variance: variance, point: value, loading: false, modal: false    });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmVariance.modId = 0;
                AdmVariance.modSupplier = 0;
                AdmVariance.modPeriod = 0;
                AdmVariance.modLargeArea = "";
                AdmVariance.modVariance = 0;
                this.setState({ modal: true, popPeriods: [], popValue: 0 });
            }
            else {
                let myrec = this.state.variance.find(x => x.varId === parseInt(val));
                AdmVariance.modId = myrec.varId;
                AdmVariance.modSupplier = myrec.varSupplier;
                AdmVariance.modPeriod = myrec.varPeriod;
                AdmVariance.modLargeArea = myrec.varLargeArea;
                AdmVariance.modVariance = myrec.varVariance;
                // fill in popPeriods 
                var pPeriods = this.state.allperiods.filter(
                    function (item) {
                        return (item.perSupplier == myrec.varSupplier);
                    }
                );      
                this.setState({ modal: true, popPeriods: pPeriods, popValue: myrec.varPeriod });
            }
        }
        else
        if (val !== "-1")
        {
            if (val !== "0") {
                if (AdmVariance.modPeriod == 0) {
                    alert("Please select a Period");
                    return;
                }
                const res = await AdmVariance.varianceSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Variance updated successfully");
                }
            } else {
                if (AdmVariance.modPeriod == 0) {
                    alert("Please select a Period");
                    return;
                } 
                const res = await AdmVariance.varianceAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Variance created successfully");
                }
            }
            await this.handleFilter(AdmVariance.fltPeriod);
        }
        else {
            this.setState({ modal: false });
        }   
    }

    static async varianceSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/variance/' + AdmVariance.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    varId: AdmVariance.modId, 
                    varSupplier: AdmVariance.modSupplier, varPeriod: AdmVariance.modPeriod,
                    varLargeArea: AdmVariance.modLargeArea, varVariance: AdmVariance.modVariance
                }
            )
        };
        var res = await fetch(callString, requestOptions);
        if (res.ok)
            return { result: "ok", reason: "" };
        else
            return { result: "error", reason: res.status };
     }

    static async varianceAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/variance';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    varSupplier: AdmVariance.modSupplier, varPeriod: AdmVariance.modPeriod,
                    varLargeArea: AdmVariance.modLargeArea, varVariance: AdmVariance.modVariance
                }       
            )
        };  
        var res = await fetch(callString, requestOptions);
        if (res.ok)
            return { result: "ok", reason: "" };
        else
            return { result: "error", reason: res.status };
    }

    static async VarianceDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/variance/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmVariance.VarianceDel(id);
            await this.handleFilter(AdmVariance.fltPeriod);
        }
    }

    async handlePopSupplier(event) {
        AdmVariance.modSupplier = event.target.value;
        var pPeriods = this.state.allperiods.filter(
            function (item) {
                return (item.perSupplier == AdmVariance.modSupplier);
            }
        );
        AdmVariance.modPeriod = 0;
        this.setState({ popPeriods: pPeriods, popValue: 0 });
    }

    async handleSupplier(value) {
        AdmVariance.fltSupplier = value;
        const data = await AdmVariance.dataGet();
        var xperiod = (data.periods).filter(
            function (item) {
                return (item.perSupplier == value);
            }
        );
        this.setState({
            variance: [], suppliers: this.state.suppliers, point : 0,
            periods: xperiod, loading: false, modal: false
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            variance: [], suppliers: [], periods: [], allperiods:[], popPeriods:[],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleSupplier = this.handleSupplier.bind(this);
        this.handlePopSupplier = this.handlePopSupplier.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static rendervarianceTable(variances, suppliers, periods, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Reference Code</th>
                            <th>Short Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variances.map((variance) =>
                            <tr key={variance.varId}>
                                <td>{variance.varLargeArea}</td>
                                <td>{variance.varVariance}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={variance.varId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <Button color="danger" onClick={hDelete} value={variance.varId}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var vars = [];
        var pages = 1;
        var page = 0;
        AdmVariance.popPeriods = this.state.allperiods.filter(
            function (item) {
                return (item.perSupplier == AdmVariance.fltSupplier);
            }
        )
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = this.state.variance;
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                vars = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                vars = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmVariance.rendervarianceTable(vars, this.state.suppliers,
                this.state.periods, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3">
                            <h1 id="tabelLabel" >Variances &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-9 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-2">
                                        <Label for="fltSupplier">
                                            Supplier
                                        </Label><br />
                                        <select Value={AdmVariance.fltSupplier}
                                            id = "fltSupplier"
                                            onChange={(e) => this.handleSupplier(e.target.value)}>
                                            <option value="0">Select One</option>
                                            {this.state.suppliers.map((supplier) =>
                                                <option value={supplier.supId}>{supplier.supName}</option>)}

                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <Label for="fltPeriod">
                                            Period
                                        </Label><br />
                                        <select value={this.state.point}
                                            id="fltPeriod"
                                            onChange={(e) => this.handleFilter(e.target.value)}>
                                            <option value="0">{(this.state.periods.length > 0) ? "Select One" : "" }</option>
                                            {this.state.periods.map((period) =>
                                                <option value={period.perId}>{period.perName}</option>)}

                                        </select>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Variance</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSupplier" style={{ "padding-top": "15px" }}>
                                Supplier
                            </Label>
                            <p><select onChange={this.handlePopSupplier} 
                                id="modSupplier"
                                defaultValue={AdmVariance.modSupplier}> 
                                
                                <option value="0">Select One</option>
                                {this.state.suppliers.map((supplier) =>
                                    <option value={supplier.supId}>{supplier.supName}</option>)}
                            </select></p>
                            <Label for="modPeriod" style={{ "padding-top": "15px" }}>
                                Period
                            </Label>
                            <p><select onChange={(e) => {
                                AdmVariance.modPeriod = e.target.value;
                                this.setState({ popValue: e.target.value })    }}
                                id="modPeriod"
                                value={this.state.popValue}> 
                                <option value="0">Select One</option>
                                {this.state.popPeriods.map((period) =>
                                    <option value={period.perId}>{period.perName}</option>)}
                            </select></p>
                            <Label for="modLargeArea" style={{ "padding-top": "15px" }}>
                                Large Area
                            </Label>
                            <Input type="text" name="modLargeArea" id="modLargeArea"
                                defaultValue={AdmVariance.modLargeArea}
                                onChange={(e) => AdmVariance.modLargeArea = e.target.value} />
                            <Label for="modVariance" style={{ "padding-top": "15px" }}>
                                Variance
                            </Label>
                            <Input type="text" name="modVariance" id="modVariance"
                                defaultValue={AdmVariance.modVariance}
                                onChange={(e) => AdmVariance.modVariance = e.target.value} />

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmVariance.modId}>
                            { (AdmVariance.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async dataGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/variance', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvariance = await response.json();
        const response2 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();
        const response3 = await fetch('mar1/periods', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbperiods = await response3.json();
        return { variance: dbvariance, suppliers: dbsuppliers, periods: dbperiods };
    }

    async populateData() {
        const data = await AdmVariance.dataGet();
        const dbsuppliers = data.suppliers;
        const dbperiods = [];
        const dbvariance = [];
        this.setState({
            variance: dbvariance, suppliers: dbsuppliers, point: 0, periods: dbperiods,
            allperiods: data.periods, popPeriods: [], popValue: 0,
            loading: false, modal: this.state.modal
        });
    }

}

