import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AdmDashboard } from './components/AdmDashboard';
import { Home } from "./components/Home";
import { AlassiaHome } from "./components/mem-home/Alassia";
import { EfnavHome } from "./components/mem-home/Efnav";
import { NomikosHome } from "./components/mem-home/Nomikos";
import { BlueseasHome } from "./components/mem-home/Blueseas";
import { AlcyonHome } from "./components/mem-home/Alcyon";
import { MmaritimeHome } from "./components/mem-home/Mmaritime";
import { SealestialHome } from "./components/mem-home/Sealestial";
import { SafetyHome } from "./components/mem-home/Safety";
import { SafebulkersHome } from "./components/mem-home/Safebulkers";
import { EquinoxHome } from "./components/mem-home/Equinox";
import { GoulandrisHome } from "./components/mem-home/Goulandris";
import { DianaHome } from "./components/mem-home/Diana";
import { FrancoHome } from "./components/mem-home/Franco";
import { DexterHome } from "./components/mem-home/Dexter";
import { GenoaHome } from "./components/mem-home/Genoa";
import { PapaHome } from "./components/mem-home/Papa";
import { EfshippingHome } from "./components/mem-home/Efshipping";
import { TestHome } from "./components/mem-home/Test";
import {Counter } from "./components/Counter";

const AppRoutes1 = [
  {
      index: true,
      path: '/',
      element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
  {
     path: '/efnav',
     element: <EfnavHome />
  },
  {
      path: '/alassia',
      element: <AlassiaHome />
  },
  {
      path: '/nomikos',
      element: <NomikosHome />
  },
  {
      path: '/blueseas',
      element: <BlueseasHome />
  },
  {
      path: '/alcyon',
      element: <AlcyonHome />
    },
    {
      path: '/mmaritime',
      element: <MmaritimeHome />
    },
    {
      path: '/sealestial',
      element: <SealestialHome />
    },
{
      path: '/safety',
      element: <SafetyHome />
    },
    {
      path: '/safebulkers',
      element: <SafebulkersHome />
    },
    {
      path: '/equinox',
      element: <EquinoxHome />
    },
    {
      path: '/goulandris',
      element: <GoulandrisHome />
    },
    {
      path: '/diana',
      element: <DianaHome />
    },
    {
      path: '/franco',
      element: <FrancoHome />
    },
    {
      path: '/dexter',
      element: <DexterHome />
    },
    {
      path: '/genoa',
      element: <GenoaHome />
    },
    {
      path: '/papa',
      element: <PapaHome />
    },
    {
      path: '/efshipping',
      element: <EfshippingHome />
    },
    {
      path: '/test',
      element: <TestHome />
    },
    {
      path: '/admdashboard',
      requireAuth: true,
      element: <AdmDashboard />
    },
   ...ApiAuthorzationRoutes
];

export default AppRoutes1;
