import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class MemPorts extends Component {
    static displayName = MemPorts.name;

    static modId = 0;
    static modMemName = "";
    static modSysPortId = 0;

    static async refreshData(modal) {
        const data = await MemPorts.opPortsGet();
        const dbopPorts = data.opPorts;
        return {
            opports: dbopPorts
        };
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                MemPorts.modId = 0;
                MemPorts.modMemName = "";
                MemPorts.modSysPortId = this.state.ports[0].prtId;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.opports.find(x => x.opoId === parseInt(val));
                MemPorts.modId = myrec.opoId;
                MemPorts.modMemName = myrec.opoOperName;
                MemPorts.modSysPortId = myrec.opoSysPort;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await MemPorts.opPortSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Port Conversion updated successfully");
                }
                const ret = await MemPorts.refreshData();
                this.setState({ opports: ret.opports, loading: false, modal: false });
            } else {
                const res = await MemPorts.opPortAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Port Conversion created successfully");
                }
                await sleep(50);  // let the database catch up
                const ret = await MemPorts.refreshData();
                this.setState({ opports: ret.opports, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async opPortSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memports/' + MemPorts.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    opoId: MemPorts.modId, opoOperator: null,
                    opoSysPort: MemPorts.modSysPortId, opoOperName: MemPorts.modMemName
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                   return  { result: "error", reason: response.status } ;
                }
            })
            .catch(error => {
                return { result: "error", reason: error};
            });
        return { result: "ok", reason: "" };
    }

    static async opPortAdd() {
        if (MemPorts.modMemName === "") {
            return { result: "error", reason: "Port Name cannot be blank" };
        }
        const token = await authService.getAccessToken();
        var callString = 'mem1/memports';
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    opoId: MemPorts.modId, opoOperator: null,
                    opoSysPort: MemPorts.modSysPortId, opoOperName: MemPorts.modMemName
                }
            )
        };
        await fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    return { result: "error", reason: response.status };
                }
            })
            .catch(error => {
                return { result: "error", reason: error };
            });
        return { result: "ok", reason: "" };
    }

    static async portDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/memports/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await MemPorts.portDel(id);
            // await sleep(100);  // let the database catch up
            const ret = await MemPorts.refreshData();
            this.setState({ opports: ret.opports, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { opPorts: [], operators: [], ports: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderOpPortTable(opports, hModal, hDelete) {
        console.log(opports);
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Member Port Name</th>
                            <th>System Port</th>
                            <th>Country</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opports.map((opport) =>
                            <tr key={opport.opoId}>
                                <td>{opport.opoOperName}</td>
                                <td>{opport.prtName}</td>
                                <td>{opport.prtCountry}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opport.opoId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <Button color="danger" onClick={hDelete} value={opport.opoId}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var opports = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "boxShadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.opports].sort(
                (a, b) => a.opoOperName.localeCompare(b.opoOperName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opports = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opports = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : MemPorts.renderOpPortTable(opports, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <h1 id="tabelLabel" >Port Conversion &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Port Conversion</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modMemName">
                                Member Name
                            </Label>
                            <Input onChange={(e) => MemPorts.modMemName = e.target.value}
                                id="modMemName"
                                type="text"
                                defaultValue={MemPorts.modMemName}
                            />
                            <Label for="modSysPortId" style={{ "paddingTop": "15px" }}>
                                System Name
                            </Label>
                            <p><select onChange={(e) => MemPorts.modSysPortId = e.target.value}
                                id="modSysPortId"
                                defaultValue={MemPorts.modSysPortId}>
                                {this.state.ports.map((port) =>
                                    <option value={port.prtId}>{port.prtName}</option>)}
                            </select></p>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemPorts.modId}>
                            { (MemPorts.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async opPortsGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/memports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopPorts = await response.json();

        const response2 = await fetch('mem1/readports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbports = await response2.json();
        return { opPorts: dbopPorts, ports: dbports };
    }

    async populateData() {
        const data = await MemPorts.opPortsGet();
        const dbopPorts = data.opPorts;
        const dbports = data.ports;
        const stports = dbports.sort(
            (a, b) => a.prtName.localeCompare(b.prtName));
        this.setState({
            opports: dbopPorts, ports: stports, loading: false, modal: this.state.modal
        });
    }

}

