import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class AdmProducts extends Component {
    static displayName = AdmProducts.name;

    static modId = 0;
    static modSupplier = 0;
    static modRef = "";
    static modSupCat = 0;
    static modSysCat = 0;
    static modAssociation = 0;
    static modShortDesc = "";   

    static fltRef = "";
    static fltSupplier = 0;
    static x = 0;

    async handleFilter(value) {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response.json();
        const filtered = dbproducts.filter(function (item) {
            return (item.prdSupCat == value);
        });
        this.setState({ products: filtered, point: value, loading: false, modal: false    });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmProducts.modId = 0;
                AdmProducts.modRef = "";
                AdmProducts.modSupplier = AdmProducts.fltSupplier;
                AdmProducts.modShortDesc = "";
                AdmProducts.modSupCat = this.state.point;
                AdmProducts.modSysCat = 1;
                AdmProducts.modAssociation = null;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.products.find(x => x.prdId === parseInt(val));
                AdmProducts.modId = myrec.prdId;
                AdmProducts.modRef = myrec.prdRefCode;
                AdmProducts.modSupplier = myrec.prdSupplier;
                AdmProducts.modShortDesc = myrec.prdShortDesc;
                AdmProducts.modSupCat = myrec.prdSupCat;
                AdmProducts.modSysCat = myrec.prdSysCat;
                AdmProducts.modAssociation = myrec.prdAssociation;
                this.setState({ modal: true });
            }
        }
        else
        if (val !== "-1")
        {
            if (val !== "0") {
                const res = await AdmProducts.prodSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Product updated successfully");
                }
            } else {
                const res = await AdmProducts.prodAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("product created successfully");
                }
            }
            await sleep(1000);  // let the database catch up
            const data = await AdmProducts.productGet();
            const dbproducts = data.products;
            const dbsuppliers = data.suppliers;
            const dbcategories = data.categories;
            this.setState({
                products: dbproducts, suppliers: dbsuppliers,
                categories: dbcategories, loading: false, modal: false
            });
        }
        else {
            this.setState({ modal: false });
        }   
    }

    static async prodSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/products/' + AdmProducts.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    prdId: AdmProducts.modId, prdRefCode: AdmProducts.modRef,
                    prdType: 1, prdSupCat: AdmProducts.modCat,
                    prdSupplier: AdmProducts.modSupplier, prdShortDesc: AdmProducts.modShortDesc,
                    prdAssociation: AdmProducts.modAssociation
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                   return  { result: "error", reason: response.status } ;
                }
            })
            .catch(error => {
                return { result: "error", reason: error};
            });
        return { result: "ok", reason: "" };
    }

    static async prodAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/products';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    prdRefCode: AdmProducts.modRef,
                    prdSupplier: AdmProducts.modSupplier, prdShortDesc: AdmProducts.modShortDesc,
                    prdSupCat: AdmProducts.modCat, prdAssociation: AdmProducts.modAssociation,
                    prdType: 1
                }       
            )
        };  
        fetch(callString, requestOptions);
        return { result: "ok", reason: "" };
    }

    static async productDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/products/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmProducts.productDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmProducts.productGet();
            const dbproducts = data.products;
            const dbsuppliers = data.suppliers;
            const dbcategories = data.categories;
            this.setState({
                products: dbproducts, suppliers: dbsuppliers,
                categories: dbcategories, loading: false, modal: false
            });
        }
    }

    async handleSupplier(value) {
        AdmProducts.fltSupplier = value;
        const token = await authService.getAccessToken();
        const response3 = await fetch('mar1/prodsupcat/' + value, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbcategories = await response3.json();
        this.setState({
            products: [], suppliers: this.state.suppliers, point : 0,
            categories: dbcategories, loading: false, modal: false
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            products: [], suppliers: [], categories: [], sysCategories: [],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleSupplier = this.handleSupplier.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderproductsTable(products, suppliers, sysCategories, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Reference Code</th>
                            <th>System Category</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) =>
                            <tr key={product.prdId}>
                                <td>{product.prdRefCode}</td>
                                <td>{sysCategories.find((e) => e.pscId == product.prdSysCat).pcsCategory} {sysCategories.find((e) => e.pscId == product.prdSysCat).pcsSubCat}
                                </td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={product.prdId}>
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var prods = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const x = this.state.point;
            const filtered =    [...this.state.products].filter(function (item)
                {
                return (item.prdSupCat == x);
                });
            const sorted = filtered.sort(
                (a, b) => a.prdRefCode.localeCompare(b.prdRefCode));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                prods = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                prods = sorted;
            }
        }

        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmProducts.renderproductsTable(prods, this.state.suppliers,
                this.state.sysCategories, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2">
                            <h1 id="tabelLabel" >Products &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-10 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-2">
                                        <Label for="fltSupplier">
                                            Supplier
                                        </Label><br />
                                        <select Value={AdmProducts.fltSupplier}
                                            id = "fltSupplier"
                                            onChange={(e) => this.handleSupplier(e.target.value)}>
                                            <option value="0">Select One</option>
                                            {this.state.suppliers.map((supplier) =>
                                                <option value={supplier.supId}>{supplier.supName}</option>)}

                                        </select>
                                    </div>
                                    <div class="col-lg-6">
                                        <Label for="fltCat">
                                            Category
                                        </Label><br />
                                        <select value={this.state.point}
                                            id="fltCat"
                                            onChange={(e) => this.handleFilter(e.target.value)}>
                                            <option value="0">{(this.state.categories.length > 0) ? "Select One" : "" }</option>
                                            {this.state.categories.map((category) =>
                                                <option value={category.psuId}>{category.psuCat1 +
                                                    ((category.psuCat2 == null) ? "" : " / " + category.psuCat2)}</option>)}

                                        </select>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>product</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSupplier" style={{ "padding-top": "15px" }}>
                                Supplier
                            </Label>
                            <p><select onChange={(e) => AdmProducts.modSupplier = e.target.value}
                                id="modSupplier"
                                defaultValue={AdmProducts.modSupplier}>
                                {this.state.suppliers.map((supplier) =>
                                    <option value={supplier.supId}>{supplier.supName}</option>)}
                            </select></p>
                            <Label for="modSupCat">
                                Supplier Category
                            </Label>
                            <p><select onChange={(e) => AdmProducts.modSupCat = e.target.value}
                                id="modSupCat"
                                defaultValue={AdmProducts.modSupCat}>
                                {this.state.categories.map((category) =>
                                    <option value={category.psuId}>{category.psuCat1} {category.psuCat2}</option>)}
                            </select></p>
                            <Label for="modType">
                                Type
                            </Label>
                            <p><select id="modType" disabled>
                                <option value="1">Lubricant</option>
                            </select></p>
                            <Label for="modSysCat">
                                System Category
                            </Label>
                            <p><select onChange={(e) => AdmProducts.modSysCat = e.target.value}
                                id="modSysCat"
                                defaultValue={AdmProducts.modSysCat}>
                                {this.state.sysCategories.map((scat) =>
                                    <option value={scat.pscId}>{scat.pcsCategory} {scat.pcsSubCat}</option>)}
                            </select></p>
                            <Label for="modRef">
                                Reference Code
                            </Label>
                            <Input onChange={(e) => AdmProducts.modRef = e.target.value}
                                id="modRef"
                                type="text"
                                defaultValue={AdmProducts.modRef}
                            />
                            <Label for="modShortDesc" style={{ "padding-top": "15px" }}>
                                Short Description
                            </Label>
                            <Input onChange={(e) => AdmProducts.modShortDesc = e.target.value}
                                id="modShortDesc"
                                type="text"
                                defaultValue={AdmProducts.modShortDesc}
                            />  
                            <Label for="modAssociation" style={{ "padding-top": "15px" }}>
                                Association
                            </Label>
                            <Input onChange={(e) => AdmProducts.modAssociation = e.target.value}
                                id="modAssociation"
                                type="text"
                                defaultValue={AdmProducts.modAssociation}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmProducts.modId}>
                            { (AdmProducts.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async productGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response.json();
        const response2 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();
        const response3 = await fetch('mar1/prodsupcat/' + this.fltSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbcategories = await response3.json();
        const response4 = await fetch('mar1/prodSysCategories', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsysCategories = await response4.json();
        return {
            products: dbproducts, suppliers: dbsuppliers, categories: dbcategories,
            sysCategories: dbsysCategories  };
    }

    async populateData() {
        const data = await AdmProducts.productGet();
        const dbproducts = data.products;
        const dbsuppliers = data.suppliers;
        const dbcategories = data.categories;
        const dbsysCategories = data.sysCategories;
        this.setState({
            products: dbproducts, suppliers: dbsuppliers, point: 0,
            categories: dbcategories, sysCategories: dbsysCategories,
            loading: false, modal: this.state.modal
        });
    }

}

