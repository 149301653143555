import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const pageSize = 10;

export class AdmItemConversions extends Component {
    static displayName = AdmItemConversions.name;

    static modId = 0;
    static modOperator = 0;
    static modSupplier = 0;
    static modRef = 0;
    static modOperName = "";   

    static fltOperator = 0;

    async handleFilter(event) {
        AdmItemConversions.fltOperator = event.target.value;
        const data = await AdmItemConversions.opItemsUpdate();
        const dbopItems = data.opItems;
        const filtered = dbopItems.filter(function (item) {
            return (item.itxMemberID === parseInt(AdmItemConversions.fltOperator));
        });
        this.setState({ opItems: filtered, loading: false, modal: false  });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmItemConversions.modId = 0;
                AdmItemConversions.modSupplier = this.state.suppliers[0].supId;
                var formProducts = this.state.products.filter(function (item) {
                    return (item.prdSupplier === parseInt(AdmItemConversions.modSupplier));
                });
                AdmItemConversions.modRef = formProducts[0].prodId;
                AdmItemConversions.modOperName = "";
                this.setState({
                    formProducts: formProducts, popValue: formProducts[0].prdId, modal: true });
            }
            else {
                let myrec = this.state.opItems.find(x => x.itxId === parseInt(val));
                AdmItemConversions.modId = myrec.itxId;
                AdmItemConversions.modSupplier = myrec.itxSupplierID;
                var formProducts2 = this.state.products.filter(function (item) {
                    return (item.prdSupplier === parseInt(AdmItemConversions.modSupplier));
                });
                AdmItemConversions.modRef = parseInt(myrec.itxProduct);
                AdmItemConversions.modOperName = myrec.itxOpName;
                this.setState({ formProducts: formProducts2, popValue: parseInt(myrec.itxProduct), modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                if (AdmItemConversions.modRef == 0) {
                    alert("Please select a supplier product name");
                    return;
                }
                const res = await AdmItemConversions.itemConvSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("Item conversion updated successfully");
                }
                const data = await AdmItemConversions.opItemsUpdate();
                var dbopItems = data.opItems;
                console.log(dbopItems);
                const filtered = dbopItems.filter(function (item) {
                    return (item.itxMemberID === parseInt(AdmItemConversions.fltOperator));
                });
                this.setState({ opItems: filtered, loading: false, modal: false });

            } else {
                if (AdmItemConversions.modRef == 0) {
                    alert("Please select a supplier product name");
                    return;
                }
                const res = await AdmItemConversions.itemConvAdd();
                console.log(res);
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("item conversion created successfully");
                }
                const data = await AdmItemConversions.opItemsUpdate();
                var dbopItems2 = data.opItems;
                const filtered = dbopItems2.filter(function (item) {
                    return (item.itxMemberID === parseInt(AdmItemConversions.fltOperator));
                });
                this.setState({ opItems: filtered, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
    }

    static async itemConvSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/opitemconversions/' + AdmItemConversions.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    ItcId: AdmItemConversions.modId, ItcOperator: AdmItemConversions.fltOperator,
                    ItcProduct: AdmItemConversions.modRef, ItcAltRef: AdmItemConversions.modOperName
                }
            )
        };
        var res = await fetch(callString, requestOptions)
        if (!res.ok) {
            return { result: "error", reason: res.status}
        }
        return { result: "ok", reason: "" };
    }

    static async itemConvAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/opitemconversions';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    ItcId: AdmItemConversions.modId, ItcOperator: AdmItemConversions.fltOperator,
                    ItcProduct: AdmItemConversions.modRef, ItcAltRef: AdmItemConversions.modOperName
                }       
            )
        };  
        var res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", reason: res.status };
        return { result: "ok", reason: "" };
   }

    static async itemConvDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/opitemconversions/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        var res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "ok", reason: res.status };
        return { result: "error", reason: res.status };
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmItemConversions.itemConvDel(id);
            const data = await AdmItemConversions.opItemsUpdate();
            var dbopItems = data.opItems;
            const filtered = dbopItems.filter(function (item) {
                return (item.itxMemberID === parseInt(AdmItemConversions.fltOperator));
            });
            this.setState({ opItems: filtered, loading: false, modal: false });
        }
    }

    async handleFormSup(event) {
        AdmItemConversions.modSupplier = event.target.value;
        var formProducts = this.state.products.filter(function (item) {
            return (item.prdSupplier === parseInt(AdmItemConversions.modSupplier));
        });
        AdmItemConversions.modRef = 0;
        this.setState({ formProducts: formProducts, popValue: 0 });
    }

    constructor(props) {
        super(props);
        this.state = {
            opItems: [], products: [], operators: [], suppliers: [],
            formProducts: [], popValue: undefined,
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleFormSup = this.handleFormSup.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderopprodrefTable(opItems, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Supplier</th>
                            <th>Product Reference</th>
                            <th>Operator Reference</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opItems.map((opItem) =>
                            <tr key={opItem.itxId}>
                                <td>{opItem.itxSupplierName}</td>
                                <td>{opItem.itxProdName}</td>
                                <td>{opItem.itxOpName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opItem.itxId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={opItem.itxId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var opItems = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.opItems].sort(
                (a, b) => a.itxProdName.localeCompare(b.itxProdName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opItems = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opItems = sorted;
            }
        }

        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmItemConversions.renderopprodrefTable(opItems,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 id="tabelLabel" >Item Conversions &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-6 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <Label for="fltCat">
                                            Operator Name
                                        </Label><br />
                                        <select onChange={this.handleFilter}
                                            defaultValue={AdmItemConversions.fltOperator}>
                                            {this.state.operators.map((operator) =>
                                                <option value={operator.oprId}>{operator.oprName}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Item Conversion</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSupplier">
                                Supplier
                            </Label>
                            <p><select onChange={this.handleFormSup}
                                id="modSupplier"
                                defaultValue={AdmItemConversions.modSupplier}>
                                {this.state.suppliers.map((supplier) =>
                                    <option value={supplier.supId}>{supplier.supName}</option>)}
                            </select></p>

                            <Label for="modRef" style={{ "paddingTop": "15px" }}>
                                Product Supplier Name
                            </Label>
                            <p><select onChange={(e) => {
                                AdmItemConversions.modRef = e.target.value;
                                this.setState({ popValue: e.target.value }); } }
                                id="modRef"
                                value={this.state.popValue}>
                                <option value="0">Select One</option>
                                {this.state.formProducts.map((prod) =>
                                    <option value={prod.prdId}>{prod.prdRefCode}</option>)}
                            </select></p>
                            <Label for="modOperName" style={{ "paddingTop": "15px" }}>
                                Member Product Name
                            </Label>
                            <Input onChange={(e) => AdmItemConversions.modOperName = e.target.value}
                            id="modOperName"
                                type="text"
                                defaultValue={AdmItemConversions.modOperName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmItemConversions.modId}>
                            { (AdmItemConversions.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }


    static async opItemsUpdate() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/items-ext', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopItems = await response.json();
        return { opItems: dbopItems };
    }
    
    static async opItemsGet() {
        const token = await authService.getAccessToken();
        const response2 = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response2.json();
        const response3 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response3.json();

        const response4 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response4.json();

        return { products: dbproducts, operators: dboperators, suppliers: dbsuppliers };
    }

    async populateData() {
        const data = await AdmItemConversions.opItemsGet();
        const dbproducts = data.products;
        const dboperators = data.operators;
        const dbsuppliers = data.suppliers;
        this.setState({
            opItems: [], products: dbproducts, operators: dboperators, suppliers: dbsuppliers,
            loading: false, modal: this.state.modal, popValue: undefined 
        });
    }

}

