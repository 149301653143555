import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 9;

export class AdmPricelist extends Component {
    static displayName = AdmPricelist.name;

    static modId = 0;
    static modSupplier = 0;
    static modprice = 0;
    static modZone = 0;
    static modPack = "";
    static modUnit = "";
    static modPrice = 0;
    static modPeriod = "";
    static modGrouping = "";

    static fltProdName = "";
    static fltPeriod = "";
    static fltSupplier = 0;
    static fltZone = 0;
    static fltPack = "";
    static fltUnit = "";
    static fltGrouping = "";

    async handleFilter(event) {
        const data = await AdmPricelist.priceGet();
        const dbprices = data.prices;
        const dbsuppliers = data.suppliers;
        const dbproducts = data.products;
        const filtered = dbprices.filter(function (item) {
            return (
         (AdmPricelist.fltProdName.trim() !== "") ? item.prcProdName.includes(AdmPricelist.fltProdName.trim()) : true &&
         (AdmPricelist.fltSupplier !== 0) ? item.prcSupplier === AdmPricelist.fltSupplier : true && 
         (AdmPricelist.fltZone !== 0) ? item.prcZone === AdmPricelist.fltZone : true &&
         (AdmPricelist.fltPack !== 0) ? item.prcPack === AdmPricelist.fltPack : true &&
         (AdmPricelist.fltUnit !== 0) ? item.prcUnit === AdmPricelist.fltUnit : true &&
         (AdmPricelist.fltGrouping.trim() !== "") ? item.prcCat === AdmPricelist.fltGrouping : true 
                   );
        });
        this.setState({
            prices: filtered, suppliers: dbsuppliers,
            products: dbproducts, loading: false, modal: false
        });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmPricelist.modId = this.state.prices[val].prcId;
                AdmPricelist.modSupplier = this.state.prices[val].prcSupplier;
                AdmPricelist.modProduct = this.state.prices[val].prcProduct;
                AdmPricelist.modZone = this.state.prices[val].prcZone;
                AdmPricelist.modPack = this.state.prices[val].prcPack;
                AdmPricelist.modUnit = this.state.prices[val].prcUnit;
                AdmPricelist.modPrice = this.state.prices[val].prcPrice;
                AdmPricelist.modPeriod = this.state.prices[val].prcPeriod;
                AdmPricelist.modGrouping = this.state.prices[val].prcGrouping;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.prices.find(x => x.prcId === parseInt(val));
                AdmPricelist.modId = myrec.prcId;
                AdmPricelist.modSupplier = myrec.prcSupplier;
                AdmPricelist.modProduct = myrec.prcProduct;
                AdmPricelist.modZone = myrec.prcZone;
                AdmPricelist.modPack = myrec.prcPack;
                AdmPricelist.modUnit = myrec.prcUnit;
                AdmPricelist.modPrice = myrec.prcPrice;
                AdmPricelist.modPeriod = myrec.prcPeriod;
                AdmPricelist.modGrouping = myrec.prcGrouping;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await AdmPricelist.priceSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("price created successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await AdmPricelist.priceGet();
                const dbprices = data.prices;
                const dbsuppliers = data.suppliers;
                const dbproducts = data.products;
                this.setState({
                    prices: dbprices, suppliers: dbsuppliers,
                    products: dbproducts, loading: false, modal: false
                });

            } else {
                const res = await AdmPricelist.priceSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("price updated successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await AdmPricelist.priceGet();
                const dbprices = data.prices;
                const dbsuppliers = data.suppliers;
                const dbproducts = data.products;
                this.setState({
                    prices: dbprices, suppliers: dbsuppliers,
                    products: dbproducts, loading: false, modal: false
                });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async priceSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/prices/' + AdmPricelist.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    prcId: AdmPricelist.modId, prcSupplier: AdmPricelist.modSupplier,
                    prcProduct: AdmPricelist.modProduct, prcZone: AdmPricelist.modZone,
                    prcPack: AdmPricelist.modPack, prcUnit: AdmPricelist.modUnit,
                    prcPrice: AdmPricelist.modPrice, prcPeriod: AdmPricelist.modPeriod,
                    prcGrouping: AdmPricelist.modGrouping
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                   return  { result: "error", reason: response.status } ;
                }
            })
            .catch(error => {
                return { result: "error", reason: error};
            });
        return { result: "ok", reason: "" };
    }

    static async priceAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/prices';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    prcId: AdmPricelist.modId, prcSupplier: AdmPricelist.modSupplier,
                    prcProduct: AdmPricelist.modProduct, prcZone: AdmPricelist.modZone,
                    prcPack: AdmPricelist.modPack, prcUnit: AdmPricelist.modUnit,
                    prcPrice: AdmPricelist.modPrice, prcPeriod: AdmPricelist.modPeriod,
                    prcGrouping: AdmPricelist.modGrouping
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async priceDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/prices/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmPricelist.priceDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmPricelist.priceGet();
            const dbprices = data.prices;
            const dbsuppliers = data.suppliers;
            const dbproducts = data.products;
            this.setState({
                prices: dbprices, suppliers: dbsuppliers,
                products: dbproducts, loading: false, modal: false
            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            prices: [], suppliers: [], products: [],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderpricesTable(prices, suppliers, products, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Supplier</th>
                            <th>Product</th>
                            <th>Zone</th>
                            <th>Pack</th>
                            <th>Unit</th>
                            <th>Grouping</th>
                            <th>Price</th>
                            <th>Period</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prices.map((price) =>
                            <tr key={price.prcId}>
                                <td><select defaultValue={price.prcSupplier} disabled
                                    style={{
                                        color: "black", "-webkit-appearance": "none", "border": "none",
                                        "-moz-appearance": "none", "background": "transparent"
                                    }}>
                                    {suppliers.map((supplier) =>
                                        <option value={supplier.supId}>{supplier.supName}</option>)}
                                </select></td>
                                <td>{price.prcProdName}</td>
                                <td>{price.prcZone}</td>
                                <td>{price.prcPack}</td>
                                <td>{price.prcUnit}</td>
                                <td>{price.prcGrouping}</td>
                                <td>{price.prcPrice}</td>
                                <td>{price.prcPeriod}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={price.prcId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={price.prcId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var prices = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.prices].sort(
                (a, b) => a.prcProdName.localeCompare(b.prcProdName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                prices = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                prices = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmPricelist.renderpricesTable(prices, this.state.suppliers,
                this.state.products, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="column">
                            <h1 id="tabelLabel" >Pricelist &nbsp;&nbsp;&nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="column border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-1">
                                        <Label for="fltSupplier">
                                            Supplier
                                        </Label><br />
                                        <select Value={AdmPricelist.fltSupplier}>
                                            <option value="0"></option>
                                            {this.state.suppliers.map((supplier) =>
                                                <option value={supplier.supId}>{supplier.supName}</option>)}
                                        </select>
                                    </div>
                                    <div class="col-lg-2">
                                        <Label for="fltProdName">
                                            Product Name
                                        </Label><br />
                                        <Input onChange={(e) => AdmPricelist.fltProdName = e.target.value}
                                            id="fltProdName"
                                            type="text"
                                            defaultValue={AdmPricelist.fltProdName}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        <Label for="fltZone">
                                            Zone
                                        </Label>
                                        <Input onChange={(e) => AdmPricelist.fltZone = e.target.value}
                                            id="fltZone"
                                            type="text"
                                            defaultValue={AdmPricelist.fltZone}
                                        />
                                    </div>
                                    <div class="col-lg-2">
                                        <Label for="fltPack">
                                            Pack
                                        </Label>
                                        <Input onChange={(e) => AdmPricelist.fltZone = e.target.value}
                                            id="fltPack"
                                            type="text"
                                            defaultValue={AdmPricelist.fltPack}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        <Label for="fltUnit">
                                            Unit
                                        </Label>
                                        <Input onChange={(e) => AdmPricelist.fltZone = e.target.value}
                                            id="fltUnit"
                                            type="text"
                                            defaultValue={AdmPricelist.fltUnit}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        <Label for="fltGrouping">
                                            Grouping
                                        </Label>
                                        <Input onChange={(e) => AdmPricelist.fltZone = e.target.value}
                                            id="fltGrouping"
                                            type="text"
                                            defaultValue={AdmPricelist.fltGrouping}
                                        />
                                    </div>
                                    <div class="col-lg-2">
                                        <Label for="fltPeriod">
                                            Period
                                        </Label>
                                        <Input onChange={(e) => AdmPricelist.fltZone = e.target.value}
                                            id="fltPeriod"
                                            type="text"
                                            defaultValue={AdmPricelist.fltPeriod}
                                        />
                                    </div>
                                    <div class="col-lg-1">
                                        <br />
                                        <Button color="secondary" style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={this.handleFilter}>
                                            Filter
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>price</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSupplier" style={{ "padding-top": "15px" }}>
                                Supplier
                            </Label>
                            <select defaultValue={AdmPricelist.modSupplier} disabled
                                style={{
                                    color: "black", "-webkit-appearance": "none", "border": "none",
                                    "-moz-appearance": "none", "background": "transparent"
                                }}>
                                {this.state.suppliers.map((supplier) =>
                                    <option value={supplier.supId}>{supplier.supName}</option>)}
                            </select>
                            <Label for="modProduct" style={{ "padding-top": "15px" }}>
                                Product
                            </Label>
                            <Input onChange={(e) => AdmPricelist.modProduct = e.target.value}
                                id="modCountry"
                                type="text"
                                defaultValue={AdmPricelist.modProduct}
                            />
                            <Label for="modZone" style={{ "padding-top": "15px" }}>
                                Zone
                            </Label>
                            <Input onChange={(e) => AdmPricelist.modZone = e.target.value}
                                id="modZone"
                                type="text"
                                defaultValue={AdmPricelist.modZone}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmPricelist.modId}>
                            { (AdmPricelist.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async priceGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/prices-ext', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprices = await response.json();
        const response2 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();
        const response3 = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response3.json();
        return { prices: dbprices, suppliers: dbsuppliers, products: dbproducts };
    }

    async populateData() {
        const data = await AdmPricelist.priceGet();
        const dbprices = data.prices;
        const dbsuppliers = data.suppliers;
        const dbproducts = data.products;
        this.setState({
            prices: dbprices, suppliers: dbsuppliers,
            products: dbproducts, loading: false, modal: this.state.modal
        });
    }

}

