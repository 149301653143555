import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmGenerate extends Component {
    static displayName = AdmGenerate.name;

    static modId = 0;
    static modEmail = "";
    static modCode = "";
    static modMemRoleId = 0;
    static modSysRole = "Member";
    static modMemId = 0;

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmGenerate.modId = 0;
                AdmGenerate.modEmail = "*";
                AdmGenerate.modCode = "";
                var oprId = this.state.operators[0].oprId;
                AdmGenerate.modMemId = oprId;
                AdmGenerate.modMemRoleId = this.state.opRoles
                    .find(x => x.mrlMember == oprId).mrlId;
                AdmGenerate.modSysRole = "Member";
                // filter oproles for the selected operator
                var opRolesSel = this.state.opRoles.filter(function (opRole) {
                    return opRole.mrlMember === oprId;
                });
                this.setState({ modal: true, opRolesSel: opRolesSel});
            }
            else {
                let myrec = this.state.genCodes.find(x => x.perId === parseInt(val));
                AdmGenerate.modId = myrec.perId;
                AdmGenerate.modName = myrec.perName;
                AdmGenerate.modStart = myrec.perStart;
                AdmGenerate.modEnd = myrec.perEnd;
                AdmGenerate.modSupplier = myrec.perSupplier;
                AdmGenerate.modBasedOn = myrec.perBasedOn;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmGenerate.genCodeSave();
                await sleep(500);  // let the database catch up
                const data = await AdmGenerate.genCodeGet();
                const dbgenCodes = data.genCodes;
                this.setState({ genCodes: dbgenCodes, loading: false, modal: false });
            } else {
                await AdmGenerate.genCodeAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmGenerate.genCodeGet();
                const dbgenCodes = data.genCodes;
                this.setState({ genCodes: dbgenCodes, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async genCodeSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/syscodes/' + AdmGenerate.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    perId: AdmGenerate.modId, perName: AdmGenerate.modName,
                    perStart: AdmGenerate.modStart, perEnd: AdmGenerate.modEnd
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async genCodeAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/syscodes';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    scoEmail: AdmGenerate.modEmail,
                    scoCode: AdmGenerate.modCode,
                    scoMemId: AdmGenerate.modMemId,
                    scoMemRole: AdmGenerate.modMemRoleId.toString(),
                    scoSysRole: AdmGenerate.modSysRole
                }       
            )
        };  
        console.log(requestOptions);
        alert(AdmGenerate.modMemRoleId);
        fetch(callString, requestOptions);
    }

    static async genCodeDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/syscodes/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmGenerate.genCodeDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmGenerate.genCodeGet();
            const dbgenCodes = data.genCodes;
            this.setState({ genCodes: dbgenCodes, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { genCodes: [], operators: [], loading: true, modal: false, page: 0, opRolesSel:[] };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static rendergenCodesTable(genCodes, operators, opRoles, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Member</th>
                            <th>Member Role</th>
                            <th>Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {genCodes.map((genCode) =>
                            <tr key={genCode.scoId}>
                                <td>{genCode.scoSysRole}</td>
                                <td>{operators.find(x => x.oprId == genCode.scoMemId).oprName}</td>
                                <td>{opRoles.find(x => x.mrlId == genCode.scoMemRole).mrlRoleName}</td>
                                <td>{genCode.scoCode}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={genCode.perId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={genCode.scoId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = this.state.genCodes;
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmGenerate.rendergenCodesTable(oper, this.state.operators, this.state.opRoles, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Generated Codes &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>An generated Code is needed to register a new user.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Generated Code</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modEmail">
                                Email
                            </Label>
                            <Input onChange={(e) => AdmGenerate.modEmail = e.target.value}
                                id="modEmail"
                                type="text"
                                defaultValue={AdmGenerate.modEmail}
                            />
                            <Label for="modCode" style={{ "padding-top": "15px" }}>
                                Code
                            </Label>
                            <Input onChange={(e) => AdmGenerate.modCode = e.target.value}
                                id="modCode"
                                type="text"
                                defaultValue={AdmGenerate.modCode}
                            />
                            <Label for="modMemId" style={{ "padding-top": "15px" }}>
                                Member
                            </Label>
                            <Input type="select" name="modMemId" id="modMemId"
                                defaultValue={AdmGenerate.modOperator}
                                onChange={(e) => {
                                    AdmGenerate.modMemId = e.target.value;   
                                    AdmGenerate.modMemRoleId = this.state.opRoles
                                        .find(x => x.mrlMember == parseInt(e.target.value)).mrlId;
                                    this.setState({
                                        modal: true,
                                        opRolesSel: this.state.opRoles.filter(function (opRole) {
                                            return opRole.mrlMember === parseInt(e.target.value);
                                        })
                                    });
                                }}>
                                {this.state.operators.map((operator) =>
                                    <option value={operator.oprId}>{operator.oprName}</option>)}
                            </Input>
                            <Label for="modMemRoleId" style={{ "padding-top": "15px" }}>
                                Inhouse Role
                            </Label>
                            <Input type="select" name="modMemRoleId" id="modMemRoleId" 
                                onChange={(e) => AdmGenerate.modMemRoleId = parseInt(e.target.value)}
                                defaultValue={AdmGenerate.modMemRoleId} >
                                {this.state.opRolesSel.map((opRole) =>
                                    <option value={opRole.mrlId}>{opRole.mrlRoleName}</option>)}
                            </Input>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmGenerate.modId}>
                            { (AdmGenerate.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async genCodeGet() {

        const token = await authService.getAccessToken();
        const response = await fetch('mar1/syscodes', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbgenCodes = await response.json();

        const response2 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response2.json();

        const response3 = await fetch('mar1/memberRoles', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopRoles = await response3.json();

        return { genCodes: dbgenCodes, operators: dboperators, opRoles: dbopRoles };
    }

    async populateData() {
        const data = await AdmGenerate.genCodeGet();
        const dbgenCodes = data.genCodes;
        const dboperators = data.operators;
        const dbopRoles = data.opRoles;

        this.setState({
            genCodes: dbgenCodes, operators: dboperators, opRoles: dbopRoles,
            loading: false, modal: this.state.modal
        });
    }

}

