import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function

export class AdmUpload extends Component {
    static displayName = AdmUpload.name;
    static myMember = 0;
    static totLines = 0;
    static holdLine = 0;
    static procLines = 0;
    static cleared = false;
    static readlines = 0;

  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.state = { myfile: null, members: [], mes:"", bstatus:false, part: 0 };
    }

    async handleUpload(event) {
        if ((this.myMember == null) || (this.myMember === 0) ) {
            alert("No member selected");
            return;
        }
        if (this.state.myfile == null) {
            alert("No file selected");
            return;
        }
        this.cleared = false;
        this.readcomplete = false;
        this.readlines = 0;
        this.setState({ mes: "Clearing previous data", bstatus: "true", part: 1 });
    }


    async procData() {
        var inc = 10;
        var start = this.procLines + 1;
        var end = (this.procLines + inc > this.totLines) ? this.totLines : this.procLines + inc;
        var mymes = "";
        const formData = new FormData();
        formData.append("member", this.myMember);
        formData.append("start", start);
        formData.append("end", end);
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/fileupload',
            { method: 'PUT', headers: !token ? {} : { 'Authorization': `Bearer ${token}` }, body: formData });
        if (response.ok) {
            this.procLines = end;
            if (this.procLines < this.totLines) {
                mymes = "Processed " + end + " of " + this.totLines + " lines";
                this.setState({ mes: mymes, bstatus: true, part: 2 });
            } else {
                sessionStorage.setItem("myMember", this.myMember);
                mymes = "Processed all " + this.totLines + " lines"
                        + "\n\nPlease, Ask a System Assistant to process the Raw Data";
                this.setState({ mes: mymes, bstatus: false, part: 3 });
            }
        }
        else
        {
            mymes = "Processed " + end + " lines out of "+this.totLines+", then failed";
            this.setState({ mes: mymes, bstatus: false, part: 0 });
        }
    }

    async dopart(x) {
        if (x === 0) // initial state
            return;
        if (x === 1) //read file
        {
            this.readFile();
            return;
        }
        if (x === 2) // process lines
        { this.procData(); return; }
        if (x === 3) // final state
            return;
        if (x === 4) // still loading
        {
            await sleep(4000);
            this.checkstatus(0);
            return;
        }
    }

    async checkstatus(x) {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/fileupload/status/' + this.myMember,
            { method: 'GET', headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        if (response.ok) {
            this.totLines = parseInt(await response.json());
            if (this.totLines === 0) {
                this.setState({ mes: "Failed: Cannot Load File", bstatus: false, part: 0 });
                return;
            }
            if (this.totLines > 0) {
                if (this.totLines > this.holdLine) {
                    this.holdLine = this.totLines;
                    this.setState({ mes: "Still Loading, now at " + this.holdLine + " lines", bstatus: true, part: 4 });
                    return;
                } else
                    this.setState({ mes: "Failed: Cannot Load", bstatus: false, part: 0 });
                return;
            }
            this.totLines = - this.totLines;
            this.procLines = 0;
            var mymes = "Processing " + this.totLines + " lines";
            this.setState({ mes: mymes, bstatus: true, part: 2 });
        } else {
            if (x >= 2)
                this.setState({ mes: "Failed: Cannot Load File", bstatus: false, part: 0 });
            else
                await this.checkstatus(x + 1);
        }
    }

    async readFile() {
        if (parseInt(this.myMember) === 0) {
            alert("No member selected");    return;
        }
        if (!this.cleared) {
            const token = await authService.getAccessToken();
            const response = await fetch('mar1/fileupload/' + this.myMember,
                { method: 'DELETE', headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            if (response.ok) {
                this.cleared = true;
                this.setState({ mes: "Loading from Excel", bstatus: true, part: 1 });
            } else {
                this.cleared = false;
                this.setState({ mes: "Cannot Clean Data", bstatus: false, part: 0 });
            }
        }
        else {
            const formData = new FormData();
            formData.append("file", this.state.myfile);
            formData.append("member", this.myMember);
            const token = await authService.getAccessToken();
            const response = await fetch('mar1/fileupload',
                {
                    method: 'POST',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    body: formData
                });
            if (response.ok) {
                this.totLines = await response.json();
                this.procLines = 0;
                var mymes = "Processing " + this.totLines + " lines";
                this.setState({ mes: mymes, bstatus: true, part: 2 });
            }
            else {
                if (response.status === 504) {
                    this.holdLine = 0;
                    this.setState({ mes: "Still loading", bstatus: true, part: 4 });
                    return;
                }
                var data = await response.json();
                if (response.status === 500)
                    this.setState({ mes: "Failed: " + data.detail, bstatus: false, part: 0 });
                else
                    this.setState({ mes: "Failed: " + data.title, bstatus: false, part: 0 });
            }
        }
    }

    render() {
        if (this.state.members.length === 0)
            return (<p><em>Loading...</em></p>);
        this.dopart(this.state.part);
        return (
            <div>
                <h1>Upload File</h1>
                <p></p>
                <p>1. Select the Member</p>
                <select id="myMember" disabled={this.state.bstatus} onChange={(e) => this.myMember = e.target.value}>
                <option key="0" value="0">Select Member</option>
                    { this.state.members.map((member) =>
                         <option key={member.oprId} value={member.oprId}>{member.oprName}</option>)
                    }
                </select>
                <br />
                <br />
                <br />
                <p>2. Select the File</p>
                <input type="file" id="myFile"
                    onChange={(e) => this.setState({ myfile: e.target.files[0] })}></input>
                <br />
                <br />
                <br />
                <p>3. Click Upload</p>
                <Button color="primary" disabled={this.state.bstatus} onClick={this.handleUpload}>Upload File</Button>
                <br />
                <br />
                <br />
                <p>4. Follow the messages:</p>
                <label id="mes" style={{ "color": "red", "font-size": "x-large", "white-space": "pre-wrap"} }>{this.state.mes}</label>
            </div>
        );
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch    ('mar1/operators',
            { method: 'GET', headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        const data = await response.json();
        this.setState({ myfile: null, members: data, mes: "", bstatus: false, part: 0 });
    }   

}
