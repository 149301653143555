import React, { Component } from 'react';

export class AdmDashboard extends Component {
    static displayName = "Dashboard";

    render() {
        return (
            <div class="container px-4 py-5" id="hanging-icons">
                <h2 class="pb-2 border-bottom">Dashboard Temporary Page</h2>
                <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div class="col d-flex align-items-start">
                        <div class="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3" style={{ width: '48px', color: '#000', backgroundColor: '#e2e3e5', borderRadius: '.75rem', height: '48px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>                       </div>
                        <div>
                            <h2>Please select a table</h2>
                            <p>Testing Data Entry</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
