import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu4 } from './NavMenu4';

export class Layout4 extends Component {
  static displayName = Layout4.name;

  render() {
    return (
      <div>
        <NavMenu4 />
        <Container style={{"minHeight": "745px"} }>
          {this.props.children}
        </Container>
        <footer className="footer">
            <hr />
            <div className="container">
                <div className="row">
                <div className="col-md-10">
                   (c) 2024 Service Platform. All Rights Reserved.
                </div>
                    <div className="col-md-2">
                        <a href="https://servplat.com/en/" >
                            <img src="logoR-SP-Text-Web-Neo.png" height="40px" alt="Service Platform" />
                        </a>
                    </div>
                </div>
            </div>
            <br />
        </footer>
      </div>
    );
  }
}
