import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink, FormGroup
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class AdmProdSupCat extends Component {
    static displayName = AdmProdSupCat.name;

    static modId = 0;
    static modSupplierID = 0;
    static modVersion = "";
    static modOrder = 0;
    static modCat1 = "";
    static modCat2 = "";
    static modSysCat = 0;
    static modMainEngineOil = false;

    static fltSupplier = 0;


    static async refreshData(modal) {
        const data = await AdmProdSupCat.prodSupCatGet();
        const dbprodSupCat = data.prodSupCat;
        return {
            prodSupCat: dbprodSupCat
        };
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        console.log("modal: " + mod + " val: " + val);
        if (mod === false) {
            if (val === "-1") {
                AdmProdSupCat.modId = 0;
                AdmProdSupCat.modSupplierID = 0;
                AdmProdSupCat.modVersion = "";
                AdmProdSupCat.modOrder = 0;
                AdmProdSupCat.modCat1 = "";
                AdmProdSupCat.modCat2 = "";
                AdmProdSupCat.modSysCat = 0;
                AdmProdSupCat.modMainEngineOil = false;
                this.state.check = false;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.prodSupCat.find(x => x.psuId === parseInt(val));
                AdmProdSupCat.modId = myrec.psuId;
                AdmProdSupCat.modSupplierID = myrec.psuSupplier;
                AdmProdSupCat.modVersion = myrec.psuVersion;
                AdmProdSupCat.modOrder = myrec.psuOrder;
                AdmProdSupCat.modCat1 = myrec.psuCat1;
                AdmProdSupCat.modCat2 = myrec.psuCat2;
                AdmProdSupCat.modSysCat = myrec.psuSysCat;
                AdmProdSupCat.modMainEngineOil = myrec.psuMainEngineOil;
                this.state.check = myrec.psuMainEngineOil? true : false;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await AdmProdSupCat.prodSupCatSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Product Supplier Category updated successfully");
                }
                const ret = await AdmProdSupCat.refreshData();
                this.setState({ prodSupCat: ret.prodSupCat, loading: false, modal: false });
            } else {
                const res = { result: "error", reason: "System User has blocked this operation" };// await AdmProdSupCat.prodSupCatAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Product Supplier Category created successfully");
                }
                await sleep(50);  // let the database catch up
                const ret = await AdmProdSupCat.refreshData();
                this.setState({ prodSupCat: ret.prodSupCat, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async prodSupCatSave() {
        if (AdmProdSupCat.modSupplierID == 0)
            return { result: "error", reason: "Please, Select a Supplier" };
        if (AdmProdSupCat.modSysCat == 0)
            return { result: "error", reason: "Please, Select a System Category" };

        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdSupCat/' + AdmProdSupCat.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    psuId: AdmProdSupCat.modId, psuSupplier: AdmProdSupCat.modSupplierID,
                    psuVersion: AdmProdSupCat.modVersion, psuOrder: AdmProdSupCat.modOrder,
                    psuCat1: AdmProdSupCat.modCat1, psuCat2: AdmProdSupCat.modCat2,
                    psuSysCat: AdmProdSupCat.modSysCat, psuMainEngineOil: AdmProdSupCat.modMainEngineOil
                }
            )
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok) {
            return { result: "error", reason: res.status };
        }
        return { result: "ok", reason: "" };
    }

    static async prodSupCatAdd() {
        if (AdmProdSupCat.modSupplierID == "0") 
            return { result: "error", reason: "Please, Select a Supplier" };
        if (AdmProdSupCat.modSysCat == "0")
            return { result: "error", reason: "Please, Select a System Category" };

        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdSupCat';
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    psuSupplier: AdmProdSupCat.modSupplierID, psuVersion: AdmProdSupCat.modVersion,
                    psuOrder: AdmProdSupCat.modOrder, psuCat1: AdmProdSupCat.modCat1,
                    psuCat2: AdmProdSupCat.modCat2, psuSysCat: AdmProdSupCat.modSysCat,
                    psuMainEngineOil: AdmProdSupCat.modMainEngineOil
                }
            )
        };
        await fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    return { result: "error", reason: response.status };
                }
            })
            .catch(error => {
                return { result: "error", reason: error };
            });
        return { result: "ok", reason: "" };
    }

    static async unitBaseDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/AdmProdSupCat/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmProdSupCat.unitBaseDel(id);
            // await sleep(100);  // let the database catch up
            const ret = await AdmProdSupCat.refreshData();
            this.setState({ prodSupCat: ret.prodSupCat, loading: false, modal: false });
        }
    }

    async handleOper(event) {   
        AdmProdSupCat.fltSupplier = event;
        const data = await AdmProdSupCat.prodSupCatGet();
        const dbprodSupCat = data.prodSupCat;
        this.setState({
            prodSupCat: dbprodSupCat, Suppliers: this.state.Suppliers, loading: false, modal: this.state.modal
        });
    }

    constructor(props) {
        super(props);
        this.state = { prodSupCat: [], Suppliers: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOper = this.handleOper.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderOpPortTable(prodSupCat, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Main Engine Oil</th>
                            <th>M Category</th>
                            <th>S Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prodSupCat.map((supcat) =>
                            <tr key={supcat.psuId}>
                                <td>{supcat.psuVersion}</td>
                                <td><Input type="switch" checked={supcat.psuMainEngineOil ? true : false} disabled /></td>
                                <td>{supcat.psuCat1}</td>
                                <td>{supcat.psuCat2}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={supcat.psuId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var prodSupCat = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "boxShadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.prodSupCat].sort(
                (a, b) => a.psuOrder - b.psuOrder);
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                prodSupCat = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                prodSupCat = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmProdSupCat.renderOpPortTable(prodSupCat, this.handleModal, this.handleDelete);

        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1 id="tabelLabel" >Product Categories (per Supplier) &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-4 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <Label for="fltCat">
                                            Supplier Name
                                        </Label><br />
                                        <select onChange={(e) => this.handleOper(e.target.value)}
                                            defaultValue={AdmProdSupCat.fltSupplier}>
                                            <option value="0">--Select--</option>
                                            {this.state.Suppliers.map((Supplier) =>
                                                <option value={Supplier.supId}>{Supplier.supName}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Product Categories (Sup)</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSupplierID">
                                Supplier
                            </Label>
                            <Input onChange={(e) => AdmProdSupCat.modSupplierID = e.target.value}
                            id="modSupplierID"
                                type="select"
                                defaultValue={AdmProdSupCat.modSupplierID}
                            >
                                <option value="0">--Select--</option>
                                {this.state.Suppliers.map((Supplier) =>
                                    <option value={Supplier.supId}>{Supplier.supName}</option>)}
                            </Input>
                            <Label for="modVersion">
                                Version
                            </Label>
                            <Input onChange={(e) => AdmProdSupCat.modVersion = e.target.value}
                            id="modVersion"
                                type="text"
                                defaultValue={AdmProdSupCat.modVersion}
                            />
                            <Label for="modOrder" style={{ "paddingTop": "15px" }}>
                                Order
                            </Label>
                            <Input onChange={(e) => AdmProdSupCat.modOrder = e.target.value}
                                id="modOrder"
                                type="text"
                                defaultValue={AdmProdSupCat.modOrder}
                            />
                            <Label for="modCat1" style={{ "paddingTop": "15px" }}>
                                Main Category
                            </Label>
                            <Input onChange={(e) => AdmProdSupCat.modCat1 = e.target.value}
                                id="modCat1"
                                type="text"
                                defaultValue={AdmProdSupCat.modCat1}
                            />
                            <Label for="modCat2" style={{ "paddingTop": "15px" }}>
                                Sub Category
                            </Label>
                            <Input onChange={(e) => AdmProdSupCat.modCat2 = e.target.value}
                            id="modCat2"
                                type="text"
                                defaultValue={AdmProdSupCat.modCat2}
                            />
                            <FormGroup switch>
                                <Input type="switch" role="switch"
                                    style={{ "marginTop": "20px" }}
                                    id="modMainEngineOil"
                                    checked={this.state.check ? true : false}
                                    onClick=
                                    {(e) => this.setState({ check: ! this.state.check } ) }
                                />
                                <Label for="modMainEngineOil" style={{ "paddingTop": "15px" }}>
                                    Main Engine Oil
                                </Label>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmProdSupCat.modId}>
                            { (AdmProdSupCat.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async prodSupCatGet() {
        const token = await authService.getAccessToken();
        const response2 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbSuppliers = await response2.json();
      
        const response = await fetch('mar1/prodSupCat/'+this.fltSupplier, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodSupCat = await response.json();

        return { prodSupCat: dbprodSupCat, Suppliers: dbSuppliers };
    }

    async populateData() {
        const data = await AdmProdSupCat.prodSupCatGet();
        const dbprodSupCat = data.prodSupCat;
        const dbSuppliers = data.Suppliers;
        this.setState({
            prodSupCat: dbprodSupCat, Suppliers: dbSuppliers, 
            loading: false, modal: this.state.modal
        });
    }

}

