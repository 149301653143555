import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const pageSize = 10;

export class MemItems extends Component {
    static displayName = MemItems.name;

    static modId = 0;
    static modSupplier = 0;
    static modItemId = 0;
    static modOperName = "";




    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                MemItems.modId = 0;
                MemItems.modSupplier = this.state.suppliers[0].supId;
                var lst = this.state.products.filter(function (item) {
                    return (item.prdSupplier === MemItems.modSupplier)
                });
                MemItems.modItemId = lst[0].prdId;
                MemItems.modOperName = "";
                this.setState({ modProducts: lst, popValue:lst[0].prdId, modal: true });
            }
            else {
                let myrec = this.state.opItems.find(x => x.itxId === parseInt(val));
                MemItems.modId = myrec.itxId;
                MemItems.modSupplier = myrec.itxSupplierID;
                var lst2 = this.state.products.filter(function (item) {
                    return ( item.prdSupplier === parseInt(myrec.itxSupplierID) )
                });
                
                MemItems.modItemId = parseInt(myrec.itxProduct);
                MemItems.modOperName = myrec.itxOpName;
                this.setState({ modProducts: lst2, popValue: parseInt(myrec.itxProduct), modal: true });
            }
        }
        else if (val !== "-1") {
            // eslint-disable-next-line
            if (val == "0") {
                if (parseInt(MemItems.modItemId) === 0) {
                    alert("Please select a product reference code");
                    return;
                }
                const res = await MemItems.itemAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("Item conversion was created successfully");
                }
                const data = await MemItems.convGet();
                const dbopItems = data.opItems;
                this.setState({
                    opItems: dbopItems, loading: false, modal: false
                });
            } else {
                if (parseInt(MemItems.modItemId) === 0) {
                    alert("Please select a product reference code");
                    return;
                }
                const res = await MemItems.itemSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("product updated successfully");
                }
                const data = await MemItems.convGet();
                const dbopItems = data.opItems;
                this.setState({
                    opItems: dbopItems, loading: false, modal: false
                });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    async handleModSupplier(event)
    {
        MemItems.modSupplier = event.target.value;
        var lst = this.state.products.filter(function (item) {
            return (item.prdSupplier == parseInt(MemItems.modSupplier))
        });
        MemItems.modItemId = 0;
        this.setState({ modProducts: lst, popValue: 0 });
    }


    static async itemSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/item-conv/'+ MemItems.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    itcId : MemItems.modId,
                    itcProduct: MemItems.modItemId,
                    ItcAltRef: MemItems.modOperName
                })
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", reason: res.status };
        else
            return { result: "ok", reason: "" };
    }

    static async itemAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/item-conv';
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    itcProduct: MemItems.modItemId,
                    ItcAltRef: MemItems.modOperName
                })
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok) {
            return { result: "error", reason: res.status };
        } else
            return { result: "ok", reason: "" };
    }

    static async itemDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/item-conv/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await MemItems.itemDel(id);

            const data = await MemItems.convGet();
            const dbopItems = data.opItems;

            this.setState({
                opItems: dbopItems,
                    loading: false, modal: false
            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            opItems: [], products: [], operators: [], suppliers: [],
            modProducts: [], popValue: undefined,
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleModSupplier = this.handleModSupplier.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderopprodrefTable(opItems, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Supplier</th>   
                            <th>Product Reference</th>
                            <th>My Reference</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opItems.map((opItem) =>
                            <tr key={opItem.itxId}>
                                <td>{opItem.itxSupplierName}</td>
                                <td>{opItem.itxProdName}</td>
                                <td>{opItem.itxOpName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opItem.itxId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={opItem.itxId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var opItems = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.opItems].sort(
                (a, b) => a.itxProdName.localeCompare(b.itxProdName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opItems = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opItems = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : MemItems.renderopprodrefTable(opItems,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 id="tabelLabel" >Product Names &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Item Conversion</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSupplier">
                                Supplier
                            </Label>
                            <p><select onChange={this.handleModSupplier}
                            id="modSupplier"
                                type="text"
                                defaultValue={MemItems.modSupplier}
                            >
                                {this.state.suppliers.map((sup) =>
                                    <option value={sup.supId}>{sup.supName}</option>
                                )}
                            </select></p>
                            <Label for="modItemId">
                                Product Supplier Name
                            </Label>
                            <p><select onChange={(e) => {
                                MemItems.modItemId = e.target.value;
                                this.setState({ popValue: e.target.value }) }}
                                id="modItemId"
                                type="text"
                                value = {this.state.popValue}
                                defaultValue={MemItems.modItemId} 
                            >
                            <option value="0">Select Product</option>
                                {this.state.modProducts.map((item) =>
                                    <option value={item.prdId}>{item.prdRefCode}</option>
                                )}
                            </select></p>
                            <Label for="modOperName">
                                My Name
                            </Label>
                            <Input onChange={(e) => MemItems.modOperName = e.target.value}
                                id="modOperName"
                                type="text"
                                defaultValue={MemItems.modOperName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemItems.modId}>
                            { (MemItems.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async convGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/items-conv-ext', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopItems = await response.json();

        return { opItems: dbopItems } ;
    }

    static async dataGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/items-conv-ext', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopItems = await response.json();
        const response2 = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response2.json();

        const response3 = await fetch('mar1/suppliers', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
        const dbSuppliers = await response3.json();

        return { opItems: dbopItems, products: dbproducts, suppliers: dbSuppliers };

    }

    async populateData() {
        const data = await MemItems.dataGet();
        const dbopItems = data.opItems;
        const dbproducts = data.products;
        const dbsuppliers = data.suppliers;
        this.setState({
            opItems: dbopItems, products: dbproducts, suppliers: dbsuppliers,
            loading: false, modal: this.state.modal
        });
    }

}

