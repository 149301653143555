import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmProdCategories extends Component {
    static displayName = AdmProdCategories.name;

    static modId = 0;
    static modName = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmProdCategories.modId = 0;
                AdmProdCategories.modType = "";
                AdmProdCategories.modCategory = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.prodCategories.find(x => x.pctId === parseInt(val));
                AdmProdCategories.modId = myrec.pctId;
                AdmProdCategories.modType = myrec.pctType;
                AdmProdCategories.modCategory = myrec.pctCategory;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmProdCategories.prodCategoriesave();
                await sleep(500);  // let the database catch up
                const data = await AdmProdCategories.prodCategoryGet();
                const dbprodCategories = data.prodCategories;
                this.setState({ prodCategories: dbprodCategories, loading: false, modal: false });
            } else {
                await AdmProdCategories.prodCategoryAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmProdCategories.prodCategoryGet();
                const dbprodCategories = data.prodCategories;
                this.setState({ prodCategories: dbprodCategories, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async prodCategoriesave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/prodcategories/' + AdmProdCategories.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    pctId: AdmProdCategories.modId, pctType: AdmProdCategories.modType,
                    pctCategory: AdmProdCategories.modCategory
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async prodCategoryAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/prodcategories';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    pctType: AdmProdCategories.modType,
                    pctCategory: AdmProdCategories.modCategory
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async prodCategoryDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/prodcategories/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmProdCategories.prodCategoryDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmProdCategories.prodCategoryGet();
            const dbprodCategories = data.prodCategories;
            this.setState({ prodCategories: dbprodCategories, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { prodCategories: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderprodCategoriesTable(prodCategories, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Product Type</th>
                            <th>Product Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prodCategories.map((prodCategory) =>
                            <tr key={prodCategory.pctId}>
                                <td>{prodCategory.pctMainType}</td>
                                <td>{prodCategory.pctCategory}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={prodCategory.pctId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={prodCategory.pctId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = [...this.state.prodCategories].sort((a, b) => a.pctCategory.localeCompare(b.pctCategory));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmProdCategories.renderprodCategoriesTable(oper, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Product Categories &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>A Product Category provides some information about the product.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>prodCategory</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modType">
                                Product Type
                            </Label>
                            <Input onChange={(e) => AdmProdCategories.modType = e.target.value}
                                id="modType"
                                type="text"
                                defaultValue={AdmProdCategories.modType}
                            />
                            <Label for="modName">
                                Product Category
                            </Label>
                            <Input onChange={(e) => AdmProdCategories.modCategory = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmProdCategories.modCategory}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmProdCategories.modId}>
                            { (AdmProdCategories.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async prodCategoryGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/prodcategories', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodCategories = await response.json();
        return { prodCategories: dbprodCategories };
    }

    async populateData() {
        const data = await AdmProdCategories.prodCategoryGet();
        const dbprodCategories = data.prodCategories;
        this.setState({
            prodCategories: dbprodCategories,
            loading: false, modal: this.state.modal
        });
    }

}

