import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Label, Input, Form, FormFeedback,
    Pagination, PaginationItem, PaginationLink, FormGroup
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 1;

export class MemImport extends Component {
    static displayName = MemImport.name;

    static modId = 0;
    static modInvoiceNumber = "";
    static modDateStr = "";
    static modSupplier = "";
    static modVesselCode = "";
    static modVesselName = "";
    static modPort = "";
    static modApplication = "";
    static modUnit = "";
    static modCount = 0;
    
    constructor(props) {
        super(props);
        this.state = {
            impInvoices: [], suppliers: [], operators: [],
            loading: true, modal: false, page: 0
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleParse = this.handleParse.bind(this);
        this.handleImpAll = this.handleImpAll.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderImported(i, suppliers)
    {
        if (i == null) return (<div> No data </div>);

        MemImport.modId = i.minvId;
        MemImport.modDateStr = (i.minvDateStr.indexOf(" ") !== -1) ?
            i.minvDateStr.substring(0, i.minvDateStr.indexOf(" ")) : i.minvDateStr;
        MemImport.modInvoiceNumber = i.minvInvoiceNumber;
        MemImport.modSupplier = i.minvSupplier;
        MemImport.modVesselCode = i.minvVesselCode;
        MemImport.modVesselName = i.minvVesselName;
        MemImport.modPort = i.minvPort;
        MemImport.modApplication = i.minvApplication;
        MemImport.modUnit = i.minvUnit;
        MemImport.modCount = i.minvCount;
        var dateOk = (i.rDate.substring(0,3) !== "000");

        // turn to 4 decimal places at same point
        const calc = (Math.round(i.rQuantity * i.rPrice * 10000) / 10000 === i.rLineTotal);
        return (
            <div class="container">
            <Form>
            <div class="row">
                <div class="col-lg-3">
                    <Label><b>Invoice</b></Label>
                    <Input onChange={(e) => MemImport.minvInvoiceNumber = e.target.value}
                        id="modInvoiceNumber"
                        type="text"
                        value={i.minvInvoiceNumber}
                    >
                    </Input>
                    <Input onChange={(e) => MemImport.modDateStr = e.target.value}
                        id="modDateStr"
                        type="text"
                        value={MemImport.modDateStr}
                        valid={(dateOk) ? "1" : ""} invalid={(!dateOk) ? "1" : ""} >
                    </Input>
                </div> 
                <div class="col-lg-3">
                    <Label><b>Supplier</b></Label>
                    <Input onChange={(e) => MemImport.modSupplier = e.target.value}
                        id="modSupplier"
                        type="text"
                        value={MemImport.modSupplier}
                        valid={(i.minvSupplierId != null) ? "1" : ""} invalid={(i.minvSupplierId == null) ? "1" : ""}
                    > </Input>
                    <Input value={ (i.minvSupplierId == null) ? "" :
                              (suppliers.find(x => x.supId === i.minvSupplierId).supName) } disabled />
                </div>
                <div class="col-lg-3">
                    <Label><b>Vessel</b></Label>
                    <Input onChange={(e) => MemImport.modVesselCode = e.target.value}
                        id="modVesselCode"
                        type="text"
                        value= {MemImport.modVesselCode}
                        valid={(i.minvVesselId != null) ? "1" : ""} invalid={(i.minvVesselId == null) ? "1" : ""}
                    > </Input>
                    <Input onChange={(e) => MemImport.modVesselName = e.target.value}
                        id="modVesselName"
                        type="text"
                        value={MemImport.modVesselName}
                        valid={(i.minvVesselId != null) ? "1" : ""} invalid={(i.minvVesselId == null) ? "1" : ""}
                     > </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Port/Country</b></Label>
                    <Input onChange={(e) => MemImport.modPort = e.target.value}
                        id="modPort"
                        type="text"
                        value={MemImport.modPort}
                        valid={(i.minvPortId != null) ? "1" : ""} invalid={(i.minvPortId == null) ? "1" : ""}
                    > </Input>
                        <Input value={i.minvPortCountry ?? ""} disabled></Input>
                </div>
            </div>
            <div class="row" style={{"marginTop":"10px"} }>
                <div class="col-lg-3">
                    <Label><b>Type</b></Label>
                    <Input onChange={(e) => MemImport.modApplication = e.target.value}
                        id="modApplication"
                        type="text"
                        value={MemImport.modApplication}
                        valid={(i.minvType !== "none") ? "1" : ""}
                        invalid={(i.minvType === "none") ? "1" : ""}
                    > </Input>
                    <Input value={i.minvType} disabled> </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Item</b></Label>
                    <Input value={i.minvItemRefCode}
                            valid={((i.minvItemId != null) && ((i.minvType === "Item"))) ? "1" : ""}
                            invalid={((i.minvItemId == null) && ((i.minvType === "Item"))) ? "1" : ""}
                    > </Input>
                    <Input value={i.minvSysName ?? ""} disabled
                    > </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Categories</b></Label>
                        <Input value={i.minvExpType ?? ""} disabled
                    > </Input>
                        <Input value={i.minvExpDesc ?? "" } disabled
                    > </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Status</b></Label>
                        <Input value={i.minvStatus ?? ""} disabled
                            style={{ "background": (i.minvStatus === "hold") ? "orange" : "#e9ecef" } }
                    > </Input>
                        <Input value={i.minvAction ?? ""} disabled
                    > </Input>
                </div>
            </div>
            <div class="row" style={{ "marginTop": "10px" }}>
                <div class="col-lg-3">
                    <Label><b>Unit</b></Label>
                    <Input onChange={(e) => MemImport.modUnit = e.target.value}
                        id="modUnit"
                        type="text"
                        value={MemImport.modUnit}
                    > </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Grouping</b></Label>
                        <Input value={i.minvGrouping} disabled
                    > </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Packaging</b></Label>
                        <Input value={i.minvPackaging } disabled> 
                    </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Currency</b></Label>
                        <Input value={i.minvCurrency} disabled
                    > </Input>
                </div>
            </div>
            <div class="row" style={{ "marginTop": "10px" }}>
                <div class="col-lg-3">
                    <Label><b>Quantity</b></Label>
                        <Input value={i.minvQuantity} > </Input>
                        <Input value={i.rQuantity} style={{ "text-align": "right" }} disabled></Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Item Price</b></Label>
                        <Input value={i.minvPrice}> </Input>
                        <Input value={i.rPrice} style={{ "text-align": "right" }} disabled></Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Packaging if separate</b></Label>
                        <Input value="" disabled>
                    </Input>
                        <Input value="" style={{ "text-align": "right" }} disabled>
                    </Input>
                </div>
                <div class="col-lg-3">
                    <Label><b>Total</b></Label>
                        <Input value={i.minvLineTotal}> </Input>
                        <FormGroup className="position-relative">
                            <Input value={i.rLineTotal} style={{ "text-align": "right" }} disabled
                            valid={calc ? "1" : ""} invalid={calc ? "" : "1"}></Input>
                            <FormFeedback tooltip style={
                                { "width": "100%", "text-align": "right", "padding-right":"40px"}}>
                                {"Calc: " + (Math.round(i.rQuantity * i.rPrice * 10000) / 10000) }
                        </FormFeedback>
                        </FormGroup>

                </div>
                    </div>
            </Form>
        </div>
        );
    }

    async handleParse(event) {
        await this.doSave();
        const data = await MemImport.invGet();
        const dbimpInvoices = data.impInvoices;
        const dbsuppliers = data.suppliers;
        this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, loading: false });
    }


    async handleSave(event) {
        await this.doSave();
        const data = await MemImport.invGet();
        const dbimpInvoices = data.impInvoices;
        const dbsuppliers = data.suppliers;
        this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, loading: false  });
    }

    async doSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memimpinvoices/' + MemImport.modId;
        var requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    MinvId: MemImport.modId,
                    MinvInvoiceNumber: MemImport.modInvoiceNumber,
                    MinvDateStr: MemImport.modDateStr,
                    MinvSupplier: MemImport.modSupplier,
                    MinvVesselCode: MemImport.modVesselCode,
                    MinvVesselName: MemImport.modVesselName,
                    MinvPort: MemImport.modPort,
                    MinvApplication: MemImport.modApplication,
                    MinvUnit: MemImport.modUnit,
                    MinvStatus: "new",
                    MinvAction: ""
                }
            )
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok) {
            alert("Error: " + res.status);
            return;
        }

        const formData = new FormData();
        formData.append("start", MemImport.modCount);
        formData.append("end", MemImport.modCount);
        res = await fetch('mem1/memfileupload',
            { method: 'PUT', headers: !token ? {} : { 'Authorization': `Bearer ${token}` }, body: formData });
        if (!res.ok) {
            alert("Error: " + res.status);
        }

        return;
    }


    async handleImp1(event) {
        alert("Importing 1 ...");
    }

    async handleImpAll(event) {
        // call controller to import from impinvoices to arcinvoices
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/memimp2arc', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
            alert("All data imported");

            const data = await MemImport.invGet();
            const dbimpInvoices = data.impInvoices;
            const dbsuppliers = data.suppliers;
            this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, page: 0 });
        }
        else {
            alert("Error, transfer failed");
        } 
   }

    handleDel1() {
        alert("Deleting 1 ...");
    }

    handleDelAll() {
        alert("Deleting All");
    }


    render()
    {
        var invs = [];
        var page = 0;
        var pages = 1;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.impInvoices].sort(
                (a, b) => a.rDate > b.rDate);
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                invs = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                invs = sorted;
            }
        }


        var contents1 = "";
        if (this.state.loading)
            contents1 = <p><em>Loading...</em></p>
        else {
            contents1 = MemImport.renderImported(invs[0], this.state.suppliers);
        }

        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <h1 id="tabelLabel" >Raw Data
                            </h1>
                        </div>
                        <div class="col-lg-8 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <Button color="warning" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleSave} >
                                            Make Changes
                                        </Button>
                                        <Button color="warning" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleParse}>
                                            Parse Again
                                        </Button>
                                    </div>
                                    <div class="col-lg-4">
                                        <Button color="primary" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleImp1} >
                                              Import This Item
                                        </Button>
                                        <Button color="primary" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleImpAll} >
                                            Import All Valid
                                        </Button>
                                    </div>
                                    <div class="col-lg-4">
                                        <Button color="danger" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleDel1} >
                                            Delete This Item
                                        </Button>
                                        <Button color="danger" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleDelAll} >
                                            Delete All
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                <br />

                {contents1}

                <br />
                <br />
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
            </div>
        );
    }

    static async invGet() {
        console.log("getdata");
        const token = await authService.getAccessToken();

        const response = await fetch('mem1/memimpinvoicesex', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbimpInvoices = await response.json();

        const response2 = await fetch('mem1/readsuppliers', { 
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();

        return { impInvoices: dbimpInvoices, suppliers: dbsuppliers };
    }

    async populateData() {
        const data = await MemImport.invGet();
        const dbimpInvoices = data.impInvoices;
        const dbsuppliers = data.suppliers;
        this.setState({
            impInvoices: dbimpInvoices, suppliers: dbsuppliers, loading: false
        });
    }

}

