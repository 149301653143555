import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Button } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function

export class MemUpload extends Component {
    static displayName = MemUpload.name;

    static totLines = 0;
    static holdLine = 0;
    static procLines = 0;
    static cleared = false;
    static readlines = 0;

    constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.state = { myfile: null, mes:"", bstatus:false, part: 0 };
    }

    async handleUpload(event) {
        // admin check for member



        if (this.state.myfile == null) {
            alert("No file selected");
            return;
        }
        this.cleared = false;
        this.readcomplete = false;
        this.readlines = 0;
        this.setState({ mes: "Clearing previous data", bstatus: "true", part: 1});
    }


    async procData() {
        var start = this.procLines + 1;
        var end = (this.procLines + 10 > this.totLines) ? this.totLines : this.procLines + 10;
        var mymes = "";
        const formData = new FormData();

        formData.append("start", start);
        formData.append("end", end);
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/memfileupload',
            { method: 'PUT', headers: !token ? {} : { 'Authorization': `Bearer ${token}` }, body: formData });
        if (response.ok) {
            this.procLines = end;
            if (this.procLines < this.totLines) {
                mymes = "Processed " + end + " of " + this.totLines + " lines";
                this.setState({ mes: mymes, bstatus: true, part: 2 });
            } else {
                // Admin: sessionStorage.setItem("myMember", this.myMember);
                mymes = "Processed all " + this.totLines + " lines"
                        + "\n\nPlease, go to Menu: Imports >> Raw Data";
                this.setState({ mes: mymes, bstatus: false, part: 3 });
            }
        }
        else
        {
            mymes = "Processed " + end + " lines out of "+this.totLines+", then failed";
            this.setState({ mes: mymes, bstatus: false, part: 0 });
        }
    }

    async dopart(x) {
        if (x === 0) // initial state
            return;
        if (x === 1) //read file
        {
            this.readFile();
            return;
        }
        if (x === 2) // process lines
        { this.procData(); return; }
        if (x === 3) // final state
            return;
        if (x === 4) // still loading
        {
            await sleep(4000);
            this.checkstatus();
            return;
        }
    }

    async checkstatus() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/memfileupload/status',
            { method: 'GET', headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
        if (response.ok) {
            this.totLines = parseInt(await response.json());
            if (this.totLines === 0) {
                this.setState({ mes: "Failed: Cannot Load File", bstatus: false, part: 0 });
                return;
            } 
            if (this.totLines > 0) {
                if (this.totLines > this.holdLine) {
                    this.holdLine = this.totLines;
                    this.setState({ mes: "Still Loading, now at " + this.holdLine + " lines", bstatus: true, part: 4 });
                    return;
                } else
                    this.setState({ mes: "Failed: Cannot Load", bstatus: false, part: 0 });
                return;
            }
            this.totLines = - this.totLines;
            this.procLines = 0;
            var mymes = "Processing " + this.totLines + " lines";
            this.setState({ mes: mymes, bstatus: true, part: 2 });
        } else {
            this.setState({ mes: "Failed: Cannot Load File", bstatus: false, part: 0 });
        }
    }

    async readFile() {



        if (!this.cleared)
        {
            const token = await authService.getAccessToken();
            const response = await fetch('mem1/memfileupload',
                { method: 'DELETE', headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            if (response.ok) {
                this.cleared = true;
                this.setState({ mes: "Loading from Excel", bstatus: true, part: 1 });
            } else {
                this.cleared = false;
                this.setState({ mes: "Cannot Clean Data", bstatus: false, part: 0 });
            }
        }
        else
        {
            const formData = new FormData();
            formData.append("file", this.state.myfile);
            const token = await authService.getAccessToken();
            const response = await fetch('mem1/memfileupload',
                {
                    method: 'POST',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    body: formData
                });
            if (response.ok) {
                this.totLines = await response.json();
                this.procLines = 0;
                var mymes = "Processing " + this.totLines + " lines";
                this.setState({ mes: mymes, bstatus: true, part: 2 });
            }
            else {
                if (response.status === 504)
                {
                    this.holdLine = 0;
                    this.setState({ mes: "Still loading", bstatus: true, part: 4 });
                    return;
                }
                var data = await response.json();
                if (response.status === 500)
                    this.setState({ mes: "Failed: " + data.detail, bstatus: false, part: 0 });
                else
                    this.setState({ mes: "Failed: " + data.title, bstatus: false, part: 0 });
            }
        }
    }

    render() {
        this.dopart(this.state.part);
        console.log("bstatus: " + this.state.bstatus);
        return (
            <div>
                <h1>Upload File</h1>
                <p></p>
                <p>1. Select the File</p>
                <input type="file" id="myFile"
                    onChange={(e) => this.setState({ myfile: e.target.files[0] })}></input>
                <br />
                <br />
                <br />
                <p>2. Click Upload</p>
                <Button color="primary" disabled={this.state.bstatus} onClick={this.handleUpload}>Upload File</Button>
                <br />
                <br />
                <br />
                <p>3. Read the messages:</p>
                <label id="mes" style={{ "color": "red", "font-size": "x-large", "white-space": "pre-wrap"} }>{this.state.mes}</label>
            </div>
        );
    }
}
