import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function

export class AdmSuppliers extends Component {
    static displayName = AdmSuppliers.name;

    static modId = 0;
    static modName = "";
    static modContactName = "";
    static modTelephone = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmSuppliers.modId = 0;
                AdmSuppliers.modName = "";
                AdmSuppliers.modContactName = "";
                AdmSuppliers.modTelephone = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.suppliers.find(x => x.supId === parseInt(val));
                AdmSuppliers.modId = myrec.supId;
                AdmSuppliers.modName = myrec.supName;
                AdmSuppliers.modContactName = myrec.supContactName;
                AdmSuppliers.modTelephone = myrec.supTelephone;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmSuppliers.supplierSave();
                const data = await AdmSuppliers.supplierGet();
                const dbsuppliers = data.suppliers;
                this.setState({ suppliers: dbsuppliers, loading: false, modal: false });
            } else {
                await AdmSuppliers.supplierAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmSuppliers.supplierGet();
                const dbsuppliers = data.suppliers;
                this.setState({ suppliers: dbsuppliers, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async supplierSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/suppliers/' + AdmSuppliers.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    supId: AdmSuppliers.modId, supName: AdmSuppliers.modName,
                    supContactName: AdmSuppliers.modContactName, supTelephone: AdmSuppliers.modTelephone
                }
            )
        };
        var response = await fetch(callString, requestOptions).catch(error => {
            console.error('There was an error!', error);
            return "error";
        });
        if (!response.ok) {
            console.log(response.status);
            return "error";
        }
        return "ok";
    }

    static async supplierAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/suppliers';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    supName: AdmSuppliers.modName,  
                    supContactName: AdmSuppliers.modContactName,
                    supTelephone: AdmSuppliers.modTelephone
                }       
            )
        };  
        await fetch(callString, requestOptions);
    }

    static async supplierDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/suppliers/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        var ret = await fetch(callString, requestOptions);
        return ret.ok;
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            var ret = await AdmSuppliers.supplierDel(id);
            if (!ret) {
                    alert("Delete failed. The supplier is in use.");
            }
            const data = await AdmSuppliers.supplierGet();
            const dbsuppliers = data.suppliers;
            this.setState({ suppliers: dbsuppliers, loading: false, modal: false });
        }
    }


    constructor(props) {
        super(props);
        this.state = { suppliers: [], loading: true, modal: false };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderSuppliersTable(suppliers, hModal, hDelete) {
        const sorted = [...suppliers].sort((a, b) => a.supName.localeCompare(b.supName));
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Supplier Name</th>
                            <th>Contact Name</th>
                            <th>Telephone</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((supplier) =>
                            <tr key={supplier.supId}>
                                <td>{supplier.supName}</td>
                                <td>{supplier.supContactName}</td>
                                <td>{supplier.supTelephone}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={supplier.supId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={supplier.supId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : AdmSuppliers.renderSuppliersTable( this.state.suppliers, this.handleModal, this.handleDelete);
        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Suppliers &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>A Supplier provides supplies the Operator's Vessels.</p>
                {contents1}
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Supplier</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                Supplier Name
                            </Label>
                            <Input onChange={(e) => AdmSuppliers.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmSuppliers.modName}
                            />
                            <Label for="modContactName" style={{ "padding-top": "15px" }}>
                                Supplier Contact Name
                            </Label>
                            <Input onChange={(e) => AdmSuppliers.modContactName = e.target.value}
                                id="modContactName"
                                type="text"
                                defaultValue={AdmSuppliers.modContactName}
                            />
                            <Label for="modTelephone" style={{ "padding-top": "15px" }}>
                                Telephone
                            </Label>
                            <Input onChange={(e) => AdmSuppliers.modTelephone = e.target.value}
                                id="modTelephone"
                                type="text"
                                defaultValue={AdmSuppliers.modTelephone}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmSuppliers.modId}>
                            { (AdmSuppliers.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async supplierGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response.json();
        return { suppliers: dbsuppliers };
    }

    async populateData() {
        const data = await AdmSuppliers.supplierGet();
        const dbsuppliers = data.suppliers;
        this.setState({
            suppliers: dbsuppliers,
            loading: false, modal: this.state.modal
        });
    }

}

