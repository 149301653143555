import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class MemProdRef extends Component {
    static displayName = MemProdRef.name;
    input1 = "";

    static modId = 0;
    static modRef = 0;
    static modOperName = "";   

    static async newRefsGet() {

        const token = await authService.getAccessToken();
        const response = await fetch('mem1/MemNewRefs', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodrefs = await response.json();
        return dbprodrefs;
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                // find new Refs
                const newRefs = await MemProdRef.newRefsGet();
                if (newRefs.length === 0) {
                    alert("No new classifications to add");
                    return;
                }
                MemProdRef.modId = 0;
                MemProdRef.modRef = newRefs[0].prrsId;
                MemProdRef.modOperName = "";
                MemProdRef.input1 = 
                <div>
                    <select onChange={(e) => MemProdRef.modRef = e.target.value}
                        defaultValue={MemProdRef.modRef} >
                        {newRefs.map((newRef) =>
                            <option value={newRef.prrsId}>{newRef.prrsName}</option>)}
                    </select> <br /> <br />
                </div>
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.oprefs.find(x => x.oprsId === parseInt(val));
                MemProdRef.modId = myrec.oprsId;
                MemProdRef.modRef = myrec.oprsRef;
                MemProdRef.modOperName = myrec.oprsOperName;
                MemProdRef.input1 =
                       <div>
                            <select defaultValue={MemProdRef.modRef} disabled
                                style={{
                                    color: "black", "-webkit-appearance": "none",
                                    "-moz-appearance": "none", "background": "transparent"
                                }}>
                                {this.state.prodrefs.map((prodref) =>
                                    <option value={prodref.prrsId}>{prodref.prrsName}</option>)}
                            </select> <br /> <br />
                        </div>
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await MemProdRef.opRefSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("Classification updated successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await MemProdRef.opRefGet();
                const dbopRefs = data.opRefs;
                const dbprodRefs = data.prodRefs;
                this.setState({
                    oprefs: dbopRefs, prodrefs: dbprodRefs,
                    loading: false, modal: false
                });

            } else {
                const res = await MemProdRef.opRefAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Classification created successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await MemProdRef.opRefGet();
                const dbopRefs = data.opRefs;
                const dbprodRefs = data.prodRefs;
                this.setState({
                    oprefs: dbopRefs, prodrefs: dbprodRefs,
                    loading: false, modal: false
                });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async opRefSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memprodrefs/' + MemProdRef.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    oprsId: MemProdRef.modId,
                    oprsRef: MemProdRef.modRef, oprsOperName: MemProdRef.modOperName
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                   return  { result: "error", reason: response.status } ;
                }
            })
            .catch(error => {
                return { result: "error", reason: error};
            });
        return { result: "ok", reason: "" };
    }

    static async opRefAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memprodrefs';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    oprsId: MemProdRef.modId, oprsOperator: 0,
                    oprsRef: MemProdRef.modRef, oprsOperName: MemProdRef.modOperName
                }       
            )
        };  
        const res = await fetch(callString, requestOptions);
        return res;
    }

    static async opRefDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/memprodrefs/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await MemProdRef.opRefDel(id);
            await sleep(1000);  // let the database catch up
            const data = await MemProdRef.opRefGet();
            const dbopRefs = data.opRefs;
            const dbprodRefs = data.prodRefs;
            this.setState({
                oprefs: dbopRefs, prodrefs: dbprodRefs,
                loading: false, modal: false
            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            oprefs: [], prodrefs: [], operators: [],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderopprodrefTable(oprefs, prodrefs, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>System Reference</th>
                            <th>Member Reference</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oprefs.map((opref) =>
                            <tr key={opref.oprsId}>
                                <td><select defaultValue={opref.oprsRef} disabled
                                    style={{
                                        color: "black", "-webkit-appearance": "none", "border": "none",
                                        "-moz-appearance": "none", "background": "transparent"
                                    }}>
                                    {prodrefs.map((prodref) =>
                                        <option value={prodref.prrsId}>{prodref.prrsName}</option>)}
                                </select></td>
                                <td>{opref.oprsOperName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opref.oprsId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={opref.oprsId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oprefs = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.oprefs].sort(
                (a, b) => a.oprsOperName.localeCompare(b.oprsOperName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oprefs = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oprefs = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : MemProdRef.renderopprodrefTable(oprefs, this.state.prodrefs,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 id="tabelLabel" >Invoice Item Application &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Item Reference</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modRef">
                                System Name
                            </Label> <br />
                            { MemProdRef.input1 }
                            <Label for="modOperName">
                                Member Name
                            </Label>
                            <Input onChange={(e) => MemProdRef.modOperName = e.target.value}
                                id="modOperName"
                                type="text"
                                defaultValue={MemProdRef.modOperName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemProdRef.modId}>
                            { (MemProdRef.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async opRefGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/memprodrefs', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopRefs = await response.json();
        const response2 = await fetch('mar1/prodrefsystems', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodRefs = await response2.json();
        return { opRefs: dbopRefs, prodRefs: dbprodRefs };
    }

    async populateData() {
        const data = await MemProdRef.opRefGet();
        const dbopRefs = data.opRefs;
        const dbprodRefs = data.prodRefs;
        this.setState({
            oprefs: dbopRefs, prodrefs: dbprodRefs,
            loading: false, modal: this.state.modal
        });
    }

}

