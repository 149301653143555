import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';


const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmPorts extends Component {
    static displayName = AdmPorts.name;

    static modId = 0;
    static modName = "";
    static modCountry = "";
    static modZone = "";

    static fltName = "";
    static fltCountry = "";
    static fltOrder = "1";

    async handleFilter(event) {
        let val = event.currentTarget.value;
        const data = await AdmPorts.portGet();
        const dbports = data.ports;

        if (val === "10")
              AdmPorts.fltOrder = "1";
        else if (val === "20")
            AdmPorts.fltOrder = "2";
        const filtered = dbports.filter(function (item) {
            return (
                ((AdmPorts.fltName.trim() !== "") ? item.prtName.toLowerCase().includes(AdmPorts.fltName.toLowerCase().trim()) : true) &&
                ((AdmPorts.fltCountry.trim() !== "") ? item.prtCountry.toLowerCase().includes(AdmPorts.fltCountry.toLowerCase().trim()) : true)
                   );
        });
        this.setState({ ports: filtered, loading: false, modal: false });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmPorts.modId = 0;
                AdmPorts.modName = "";
                AdmPorts.modCountry = "";
                AdmPorts.modZone = "";
                this.setState({ modal: true });
            }
            else {

                let myrec = this.state.ports.find(x => x.prtId === parseInt(val));
                AdmPorts.modId = myrec.prtId;
                AdmPorts.modName = myrec.prtName;
                AdmPorts.modCountry = myrec.prtCountry;
                AdmPorts.modZone = myrec.prtChevronZone;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await AdmPorts.portSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("Port created successfully");
                }

                await sleep(500);  // let the database catch up
                const data = await AdmPorts.portGet();
                const dbports = data.ports;
                this.setState({ ports: dbports, loading: false, modal: false });
            } else {
                const res = await AdmPorts.portSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Port updated successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await AdmPorts.portGet();
                const dbports = data.ports;
                this.setState({ ports: dbports, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async portSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ports/' + AdmPorts.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    prtId: AdmPorts.modId, prtName: AdmPorts.modName,
                    prtCountry: AdmPorts.modCountry, prtChevronZone: AdmPorts.modZone
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                   return  { result: "error", reason: response.status } ;
                }
            })
            .catch(error => {
                return { result: "error", reason: error};
            });
        return { result: "ok", reason: "" };
    }

    static async portAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ports';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    prtName: AdmPorts.modName,  
                    prtCountry: AdmPorts.modCountry,
                    prtChevronZone: AdmPorts.modZone,
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async portDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/ports/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmPorts.portDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmPorts.portGet();
            const dbports = data.ports;
            this.setState({ ports: dbports, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { ports: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderportsTable(ports, hModal, hDelete, hFilter) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th><button class={(AdmPorts.fltOrder == "1") 
                               ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                onClick={hFilter} value="10"
                                   ><b>Name</b></button></th>
                            <th><button class={(AdmPorts.fltOrder == "2")
                                ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                onClick={hFilter} value="20"
                                   ><b>Country</b></button></th>
                            <th>Shell</th>
                            <th>Chevron</th>
                            <th>Total</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ports.map((port) =>
                            <tr key={port.prtId}>
                                <td>{port.prtName} </td>
                                <td>{port.prtCountry}</td>
                                <td>{port.prtNameShell}<br />{port.prtShellArea}</td>
                                <td>{port.prtNameChevron}<br />{port.prtChevronArea}</td>
                                <td>{port.prtNameTotal}<br />{port.prtTotalArea}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={port.prtId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={port.oprId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            var sorted = [];
            if (AdmPorts.fltOrder === "1")
                sorted = [...this.state.ports].sort((a, b) => a.prtName.localeCompare(b.prtName));
            else if (AdmPorts.fltOrder === "2")
                sorted = [...this.state.ports].sort((a, b) => a.prtCountry.localeCompare(b.prtCountry));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmPorts.renderportsTable(oper, this.handleModal, this.handleDelete, this.handleFilter);
        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2">
                            <h1 id="tabelLabel" >Ports &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-10 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <Label for="fltName">
                                            Port Name
                                        </Label>
                                        <Input onChange={(e) => AdmPorts.fltName = e.target.value}
                                            id="fltName"
                                            type="text"
                                            defaultValue={AdmPorts.fltName}
                                        />
                                    </div>
                                    <div class="col-lg-4">
                                        <Label for="fltCountry">
                                            Port Country
                                        </Label>
                                        <Input onChange={(e) => AdmPorts.fltCountry = e.target.value}
                                            id="fltCountry"
                                            type="text"
                                            defaultValue={AdmPorts.fltCountry}
                                        />
                                    </div>
                                    <div class="col-lg-2">
                                        <br />
                                        <Button color="secondary" style={{ "width": "100%", "margin-top": "7px" }}
                                             >
                                             Clear
                                        </Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <br />
                                        <Button color="secondary" style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={this.handleFilter} value={-1}>
                                            Filter
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>port</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                port Name
                            </Label>
                            <Input onChange={(e) => AdmPorts.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmPorts.modName}
                            />
                            <Label for="modCountry" style={{ "padding-top": "15px" }}>
                                port Country
                            </Label>
                            <Input onChange={(e) => AdmPorts.modCountry = e.target.value}
                                id="modCountry"
                                type="text"
                                defaultValue={AdmPorts.modCountry}
                            />
                            <Label for="modZone" style={{ "padding-top": "15px" }}>
                                Zone
                            </Label>
                            <Input onChange={(e) => AdmPorts.modZone = e.target.value}
                                id="modZone"
                                type="text"
                                defaultValue={AdmPorts.modZone}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmPorts.modId}>
                            { (AdmPorts.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async portGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/ports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbports = await response.json();
        return { ports: dbports };
    }

    async populateData() {
        const data = await AdmPorts.portGet();
        const dbports = data.ports;
        this.setState({
            ports: dbports,
            loading: false, modal: this.state.modal
        });
    }

}

