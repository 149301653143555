import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu3.css';

export class NavMenu3 extends Component {
  static displayName = NavMenu3.name;

  constructor (props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = { collapsed: true, isAuth: false, loading: true };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }


    render() {
    var val = sessionStorage.getItem("memberFront");
    var path = "/" + val;
    val = val.charAt(0).toUpperCase() + val.substring(1);
    return (
        <header style={{ background: "beige" }} >
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand tag={Link} to={path}>Marine Reports {val}</NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to={path}>{val} Home</NavLink>
                        </NavItem>
                        <LoginMenu>
                        </LoginMenu>
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );

  }
}
