import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function

export class AdmServCatSys extends Component {
    static displayName = AdmServCatSys.name;

    static modId = 0;
    static modName = "";
    static modType = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmServCatSys.modId = 0;
                AdmServCatSys.modName = "";
                AdmServCatSys.modType = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.Services.find(x => x.scsId === parseInt(val));
                AdmServCatSys.modId = myrec.scsId;
                AdmServCatSys.modName = myrec.scsName;
                AdmServCatSys.modType = myrec.scsType;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmServCatSys.ServicesSave();
                const data = await AdmServCatSys.servicesGet();
                const dbServices = data.Services;
                this.setState({ Services: dbServices, loading: false, modal: false });
            } else {
                await AdmServCatSys.servicesAdd();
                const data = await AdmServCatSys.servicesGet();
                const dbServices = data.Services;
                this.setState({ Services: dbServices, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async ServicesSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ServCatSys/' + AdmServCatSys.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    scsId: AdmServCatSys.modId,
                    scsName: AdmServCatSys.modName, scsType: AdmServCatSys.modType
                }
            )
        };
        var response = await fetch(callString, requestOptions).catch(error => {
            console.error('There was an error!', error);
            return "error";
        });
        if (!response.ok) {
            console.log(response.status);
            return "error";
        }
        return "ok";
    }

    static async servicesAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ServCatSys';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                scsName: AdmServCatSys.modName,
                scsType: AdmServCatSys.modType })
        };  
        await fetch(callString, requestOptions);
    }

    static async servicesDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/ServCatSys/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        var ret = await fetch(callString, requestOptions);
        return ret.ok;
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            var ret = await AdmServCatSys.servicesDel(id);
            if (!ret) {
                    alert("Delete failed. The Service is in use.");
            }
            const data = await AdmServCatSys.servicesGet();
            const dbServices = data.Services;
            this.setState({ Services: dbServices, loading: false, modal: false });
        }
    }


    constructor(props) {
        super(props);
        this.state = { Services: [], loading: true, modal: false };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderServices(Services, hModal, hDelete) {
        const sorted = [...Services].sort((a, b) => a.scsName.localeCompare(b.scsName));
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((serv) =>
                            <tr key={serv.scsId}>
                                <td>{serv.scsName}</td>
                                <td>{serv.scsType}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={serv.scsId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={serv.scsId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : AdmServCatSys.renderServices( this.state.Services, this.handleModal, this.handleDelete);
        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Services (System) &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>Sevice Categories by the Manager.</p>
                {contents1}
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Services (sys)</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                Name
                            </Label>
                            <Input onChange={(e) => AdmServCatSys.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmServCatSys.modName}
                            />
                            <Label for="modType">
                                Type
                            </Label>
                            <Input onChange={(e) => AdmServCatSys.modType = e.target.value}
                                id="modType"
                                    type="text"
                                defaultValue={AdmServCatSys.modType}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmServCatSys.modId}>
                            { (AdmServCatSys.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async servicesGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/ServCatSys', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbServices = await response.json();
        return { Services: dbServices };
    }

    async populateData() {
        const data = await AdmServCatSys.servicesGet();
        const dbServices = data.Services;
        this.setState({
            Services: dbServices,
            loading: false, modal: this.state.modal
        });
    }

}

