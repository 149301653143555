import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { parse, isValid, format } from 'date-fns';
import { Chart } from "react-google-charts";

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 13;

export class AdmReports extends Component {
    static displayName = AdmReports.name;

    static topMember = 0;
    static topCountry = 0;
    static topPort = 0;
    static topVessel = 0;
    static topCategory = 0;
    static topSubCategory = 0;
    static topSupplier = 0;
    static topProduct = 0;

    static topChart = false;

    static fltMember = "";
    static fltCountry = "";
    static fltPort = "";
    static fltVessel = "";
    static fltCategory = "";
    static fltSubCategory = "";
    static fltSupplier = "";
    static ftlProduct = "";

    static fltDateFrom = "";
    static fltDateTo = "";

    static fltOrder = "1";

    static totalPrice = 0;
    static totalQuantity = 0;

    static frmGrType = "Bar";

    async handleFilter(event) {
        let val = event.currentTarget.value;

        if (val === "10")
            AdmReports.fltOrder = "1";
        else if (val === "20")
            AdmReports.fltOrder = "2";
        else if (val === "30")
            AdmReports.fltOrder = "3";
        else if (val === "40")
            AdmReports.fltOrder = "4";
        else if (val === "50")
            AdmReports.fltOrder = "5";
        else if (val === "60")
            AdmReports.fltOrder = "6";


        this.setState({ portrdata: this.portdata});
    }

    constructor(props) {
        super(props);
        this.state = {
            portdata: [], countries: [], vessels: [], categories: [], suppliers: [],
            loading: true, modal: false, page: 0, grChart: "Bar", members: [], cat: [],
            services: [], serv: []
        };
        this.handleApply = this.handleApply.bind(this);
        this.handleChart = this.handleChart.bind(this);
        this.handleGrChart = this.handleGrChart.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    async handleExport(event) {
        // log Report to ReportLog - Get Id
        const token = await authService.getAccessToken();
        var response1 = await fetch('mar1/ExcReportsLog', {
            method: 'POST', headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify( {}
            )
        });

        if (!response1.ok) { alert("failed to log this Report"); return; }
        var res = await response1.json();
        // model: ExcRepItems
        var response = await fetch('mar1/ExcReportsExport', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    RepId: res.exrId,
                    DateFrom: document.getElementById("DateFrom").value ?? "",
                    DateTo: document.getElementById("DateTo").value ?? "",
                    IsCountry: AdmReports.topCountry ? 1 : 0,
                    IsPort: AdmReports.topPort ? 1 : 0,
                    IsMember: AdmReports.topMember ? 1 : 0,
                    IsVessel: AdmReports.topVessel ? 1 : 0,
                    IsCategory: AdmReports.topCategory ? 1 : 0,
                    IsSubCat: AdmReports.topSubCategory ? 1 : 0,
                    IsSupplier: AdmReports.topSupplier ? 1 : 0,
                    IsProduct: AdmReports.topProduct ? 1 : 0,
                    FltOrder: AdmReports.fltOrder
                }
            )
        });
        if (response.ok) {
            // load file to client
            var res = "upload/report" + res.exrId + ".xlsx";
            window.open(res, '_blank', 'noreferrer');
            await sleep(1000);
            /*
            alert("Make sure that your file is uploaded\n" +
                "and Click OK to remove it from the server\n" +
                  "for security reasons");
            */
        }
        else alert("failed to export this Report");
    }

    static renderChart(items, hGrChart) {
        var sitems = items;
        if (items.length > 6)
        {
            sitems = items.slice(0, 6);
            // add others
            var others = items.slice(6, items.length);
            // console.log(others);

            var other = others.reduce((acc, curr) => {
                acc.sumQua += curr.sumQua;
                acc.sumPrice += curr.sumPrice;
                return acc;
            }, { sumQua: 0, sumPrice: 0 });

            sitems.push({
                sumQua: other.sumQua, sumPrice: other.sumPrice,
                memberName: "Others", country: "Others", port: "Others",
                vessel: "Others", category: "Others", subCategory: "Others",
                supplierName: "Others", prodName: "Others"
            });

            // console.log(sitems);
        }
        var chead = [];
        var all = false;
        var comb = "";
        if (AdmReports.topMember) comb = comb.concat("Member ");
        if (AdmReports.topSupplier) comb = comb.concat("Supplier ");
        if (AdmReports.topProduct) comb = comb.concat("Product ");
        if (AdmReports.topSubCategory) comb = comb.concat("SubCat ");
        if (AdmReports.topCategory) comb = comb.concat("Category ");
        if (AdmReports.topVessel) comb = comb.concat("Vessel ");
        if (AdmReports.topPort) comb = comb.concat("Port ");
        if (AdmReports.topCountry) comb = comb.concat("Country ");
        if (comb === "") all = true;
        chead.push(comb);
        chead.push("Price%");
        chead.push("Quantity%");

        var data = [];
        data.push(chead);
        sitems.forEach((item) => {
            var row = [];
            var title = "";
            if (AdmReports.topMember) title = title.concat(item.memberName + "\n");
            if (AdmReports.topCountry) title = title.concat(item.country + "\n");
            if (AdmReports.topPort) title = title.concat(item.port + "\n");
            if (AdmReports.topVessel) title = title.concat(item.vessel + "\n");
            if (AdmReports.topCategory) title = title.concat(item.category + "\n");
            if (AdmReports.topSubCategory) title = title.concat(item.subCategory + "\n");
            if (AdmReports.topSupplier) title = title.concat(item.supplierName + "\n");
            if (AdmReports.topProduct) title = title.concat(item.prodName + "\n");
            if (all) title = "ALL";            
            row.push(title);
            row.push(Math.round((Number.parseFloat(item.sumPrice) / AdmReports.totalPrice * 100)*100)/100);
            row.push(Math.round((Number.parseFloat(item.sumQua) / AdmReports.totalQuantity * 100)*100)/100);
            data.push(row);
        });

        return (
            <div>
                <br />
                <button class="btn btn-outline-secondary" onClick={hGrChart} value="Bar" > 
                <b>Bar</b></button>
                <button class="btn btn-outline-secondary" onClick={hGrChart} value="BarChart" >  
                <b>BarChart</b></button>
                <button class="btn btn-outline-secondary" onClick={hGrChart} value="PieChart" > 
                <b>PieChart</b></button>
                <br />
                {AdmReports.frmGrType} <br />
                <div>
                    <Chart
                        chartType={AdmReports.frmGrType }
                        data={data}
                        width="100%"
                        height="500px"
                        options={{ legendToggle: true, is3D: true }}
                    />
                </div>
            </div>
        );
    }

    static renderopprodrefTable(items, hFilter) {

        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            {AdmReports.topCountry ?
                                <th style={{ "textAlign": "left" }}>
                                <button className={(AdmReports.fltOrder == "3")
                                    ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                    onClick={hFilter} value="30"
                                    ><b>Country</b></button></th>
                                : null}
                            {AdmReports.topPort ? <th>Port</th> : null }
                            {AdmReports.topMember ?
                                <th style={{ "textAlign": "left" }}>
                                    <button className={(AdmReports.fltOrder == "4")
                                        ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                        onClick={hFilter} value="40"
                                    ><b>Member</b></button></th>
                                : null}
                            {AdmReports.topVessel ? <th>Vessel</th> : null}
                            {AdmReports.topCategory ?
                                <th style={{ "textAlign": "left" }}>
                                    <button className={(AdmReports.fltOrder == "5")
                                        ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                        onClick={hFilter} value="50"
                                    ><b>Category</b></button></th>
                                : null}
                            {AdmReports.topSubCategory ? <th>SubCat</th> : null}
                            {AdmReports.topSupplier ?
                                <th style={{ "textAlign": "left" }}>
                                    <button className={(AdmReports.fltOrder == "6")
                                        ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                        onClick={hFilter} value="60"
                                    ><b>Supplier</b></button></th>
                                : null}
                            {AdmReports.topProduct ? <th>Product</th> : null }
                            <th style={{ "textAlign": "right" }}>
                                <button className={(AdmReports.fltOrder == "1")
                                ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                onClick={hFilter} value="10"
                                ><b>Quantity</b></button></th>
                            <th style={{ "textAlign": "right" }}> Quantity%</th>
                            <th style={{ "textAlign": "right" }}> <button className={(AdmReports.fltOrder == "2")
                                ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                onClick={hFilter} value="20"
                            ><b>Price</b></button></th>
                            <th style={{ "textAlign": "right" }}> Price% </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) =>
                            <tr key={item.key}>
                                {AdmReports.topCountry ? <td>{item.country}</td> : null}
                                {AdmReports.topPort ? <td>{item.port}</td> : null }
                                {AdmReports.topMember ? <td>{item.memberName}</td> : null}
                                {AdmReports.topVessel ? <td>{item.vessel}</td> : null}
                                {AdmReports.topCategory ? <td>{item.category}</td> : null}
                                {AdmReports.topSubCategory ? <td>{item.subCategory}</td> : null}
                                {AdmReports.topSupplier ? <td>{item.supplierName}</td> : null}
                                {AdmReports.topProduct ? <td>{item.prodName}</td> : null}
                                <td style={{ "textAlign": "right" }}> {Number.parseFloat(item.sumQua).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                <td style={{ "textAlign": "right" }}> {(Number.parseFloat(item.sumQua)
                                    / AdmReports.totalQuantity * 100).toFixed(2) + "%"}</td>
                                <td style={{ "textAlign": "right" }}> $ {Number.parseFloat(item.sumPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                <td style={{ "textAlign": "right" } }> {(Number.parseFloat(item.sumPrice)
                                    / AdmReports.totalPrice * 100).toFixed(2) + "%"}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    // grouping based on Port, Vessel, Category and Supplier
    static goReduce(items, topCountry, topPort, topVessel, topCategory, topSubCategory, topSupplier, topProduct) {
        var newitems = items.reduce((acc, curr) => {
            const found = acc.find
                (item =>
                    (this.topMember ? (item.memberId === curr.memberId) : true) &&
                    (topCountry ? (item.country === curr.country) : true) &&
                    (topPort ? (item.port === curr.port) : true) &&
                    (topVessel ? (item.vessel === curr.vessel) : true) &&
                    (topCategory ? (item.category === curr.category) : true) &&
                    (topSubCategory ? (item.subCategory === curr.subCategory) : true) &&
                    (topSupplier ? (item.supplier === curr.supplier) : true) &&
                    (topProduct ? (item.prodID === curr.prodID) : true)
                );
            if (!found) {
                curr.sumQua = parseFloat(curr.quantity.replace(",", "."));
                curr.sumPrice = parseFloat(curr.price.replace(",", "."));
                acc.push(curr);
            } else {
                var prevQua = parseFloat(found.sumQua);
                var prevPri = parseFloat(found.sumPrice);
                found.sumQua = prevQua + parseFloat(curr.quantity.replace(",", "."));
                found.sumPrice = prevPri + parseFloat(curr.price.replace(",", "."));
            }
            return acc;
        }, []);
        return newitems;
    }

    render() {
        var opItems = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        var sorted = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading)
        {
            sorted = [...this.state.portdata];
            // filter dates
            var dateFrom = document.getElementById("DateFrom").value;
            var dateTo = document.getElementById("DateTo").value;
            var vdateFrom = parse(dateFrom, 'yyyy-MM-dd', new Date());
            var vdateTo = parse(dateTo, 'yyyy-MM-dd', new Date());
            var fdateFrom = isValid(vdateFrom);
            var fdateTo = isValid(vdateTo);
            if (!fdateFrom) {
                // find the earliest date
                vdateFrom = sorted.reduce((acc, curr) => {
                    var vitemDate = parse(curr.iDate, 'yyyy-MM-dd', new Date());
                    return (vitemDate < acc) ? vitemDate : acc;
                }, new Date());
                document.getElementById("DateFrom").value = format(vdateFrom, 'yyyy-MM-dd');
                fdateFrom = true;
            }
            if (!fdateTo) {
                // find the latest date
                vdateTo = sorted.reduce((acc, curr) => {
                    var vitemDate = parse(curr.iDate, 'yyyy-MM-dd', new Date());
                    return (vitemDate > acc) ? vitemDate : acc;
                }, new Date());
                document.getElementById("DateTo").value = format(vdateTo, 'yyyy-MM-dd');
                fdateTo = true;
            }

            sorted = sorted.filter(function (item) {
                var vitemDate = parse(item.iDate, 'yyyy-MM-dd', new Date());
                return (vitemDate >= vdateFrom) && (vitemDate <= vdateTo);
            })

            sorted = AdmReports.goReduce(sorted, AdmReports.topCountry, AdmReports.topPort, AdmReports.topVessel,
                AdmReports.topCategory, AdmReports.topSubCategory, AdmReports.topSupplier, AdmReports.topProduct);

            // console.log(sorted);
            if (AdmReports.fltOrder === "1")
                sorted = sorted.sort(
                    (a, b) => Number.parseFloat(b.sumQua) - Number.parseFloat(a.sumQua));
            else if (AdmReports.fltOrder === "2")
                sorted = sorted.sort(
                    (a, b) => Number.parseFloat(b.sumPrice) - Number.parseFloat(a.sumPrice));
            else if (AdmReports.fltOrder === "3")
                sorted = sorted.sort(
                    (a, b) => a.country.localeCompare(b.country) ||
                        (Number.parseFloat(b.sumPrice) - Number.parseFloat(a.sumPrice))
                );
            else if (AdmReports.fltOrder === "4")
                sorted = sorted.sort(
                    (a, b) => a.memberName.localeCompare(b.memberName) ||
                        (Number.parseFloat(b.sumPrice) - Number.parseFloat(a.sumPrice))
                );
            else if (AdmReports.fltOrder === "5")
                sorted = sorted.sort(
                    (a, b) => a.category.localeCompare(b.category) ||
                        (Number.parseFloat(b.sumPrice) - Number.parseFloat(a.sumPrice))
                );
            else if (AdmReports.fltOrder === "6")
                sorted = sorted.sort(
                    (a, b) => a.supplierName.localeCompare(b.supplierName) ||
                        (Number.parseFloat(b.sumPrice) - Number.parseFloat(a.sumPrice))
                );


            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opItems = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opItems = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : (AdmReports.topChart) ? AdmReports.renderChart(sorted, this.handleGrChart) :
            AdmReports.renderopprodrefTable(opItems, this.handleFilter,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 border border-dark">
                            <Form>
                                <div class="row" style={{ "paddingBottom": "10px" }}>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topChart) ? "info" : "primary"} style={{ "width": "100%", "margin-top": "7px" }} onClick={this.handleChart}>Chart</Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topCountry)?"info":"secondary" } style={{ "width": "58%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topCountry = !AdmReports.topCountry; this.setState({ page: 0 }) }} > 
                                            Country
                                        </Button>
                                        <span> </span>
                                        <Button color={(AdmReports.topPort) ? "info" : "secondary"} style={{ "width": "38%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topPort = !AdmReports.topPort; this.setState({ page: 0 }) }} >
                                            Port
                                        </Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topMember) ? "info" : "secondary"} style={{ "width": "48%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topMember = !AdmReports.topMember; this.setState({ page: 0 }) }} >
                                            Member
                                        </Button>
                                        <span> </span>
                                        <Button color={(AdmReports.topVessel) ? "info" : "secondary"} style={{ "width": "48%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topVessel = !AdmReports.topVessel; this.setState({ page: 0 }) }} > 
                                            Vessel
                                        </Button>  
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topCategory) ? "info" : "secondary"} style={{ "width": "48%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topCategory = !AdmReports.topCategory; this.setState({ page: 0 }) }} > 
                                            Categ.
                                        </Button>  
                                        <span> </span>
                                        <Button color={(AdmReports.topSubCategory) ? "info" : "secondary"} style={{ "width": "48%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topSubCategory = !AdmReports.topSubCategory; this.setState({ page: 0 }) }} >
                                            Subcat
                                        </Button>  
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topSupplier) ? "info" : "secondary"} style={{ "width": "45%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topSupplier = !AdmReports.topSupplier; this.setState({ page: 0 }) }} > 
                                            Supplier
                                        </Button>
                                        <span> </span>
                                        <Button color={(AdmReports.topProduct) ? "info" : "secondary"} style={{ "width": "45%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topProduct = !AdmReports.topProduct; this.setState({ page: 0 }) }} >
                                            Prods
                                        </Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <div style={{ "marginTop": "15px", "textAlign": "center" }}>Dates From/To</div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div class="row" style={{ "paddingTop": "10px" }}>
                        <div class="col-lg-12" >
                            <div class="row">
                                <div class="col-lg-2">
                                    <Button color="primary" style={{ "width": "100%" }} onClick={this.handleExport}>Excel</Button>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                           onChange={(e) => AdmReports.fltCountry = e.target.value}
                                           id="FltCountry">
                                        <option value="">all</option>
                                        {this.state.countries.map((country) =>
                                        <option value={country.country}>{country.country}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        onChange={(e) => AdmReports.fltMember = e.target.value}
                                        id="FltMember">
                                        <option value="">all</option>
                                        {this.state.members.map((mem) =>
                                            <option value={mem.oprShortName}>{mem.oprShortName}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        onChange={(e) => {
                                            AdmReports.fltCategory = e.target.value;
                                            this.setState({ page: 0 })
                                        }} >
                                        id = "FltCategory"
                                        <option value="All">all</option>
                                        <option value="Products">--- all Products ---</option>
                                        {this.state.cat.map((cat) => 
                                            <option value={"p:" + cat}>{cat}</option>)
                                        }
                                        <option value="Services">--- all Services ---</option>
                                        {this.state.serv.map((serv) =>
                                            <option value={"s:" + serv}>{serv}</option>)
                                        }   
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        onChange={(e) => AdmReports.fltSupplier = e.target.value}
                                        id="FltSupplier">
                                        <option value="">all</option>
                                        {this.state.suppliers.map((sup) =>
                                            <option value={sup.supSupplier}>{sup.supName}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <Input id="DateFrom" type="date" />
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div class="row" style={{ "paddingTop": "10px" }}>
                        <div class="col-lg-2">
                            <Button color="primary" style={{ "width": "100%" }} onClick={this.handleApply}>Apply</Button>
                        </div>
                        <div class="col-lg-2">
                            <select class="form-select"
                                onChange={(e) => AdmReports.fltPort = e.target.value}
                                id="FltPort">
                                <option value="">all</option>
                            </select>
                        </div>
                        <div class="col-lg-2" style={{"textAlign": "Right" }}>
                            <select class="form-select"
                                onChange={(e) => AdmReports.fltVessel = e.target.value}
                                id="FltVessel">
                                <option value="">all</option>
                                {this.state.vessels.map((ves) =>
                                    <option value={ves.vesCurrentName}>{ves.vesCurrentName}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <select class="form-select"
                                onChange={(e) => AdmReports.fltSubCategory = e.target.value}
                                id="FltSubCategory">
                                <option value="">all</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <select class="form-select"
                                onChange={(e) => AdmReports.fltProduct = e.target.value}
                                id="FltProduct">
                                <option value="">all</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Input id="DateTo" type="date" />
                        </div>
                    </div>
                </div>

                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>

            </div>
        );
    }

    async handleChart(event) {
        AdmReports.topChart = !AdmReports.topChart;
        this.setState({ page: 0 });
    }

    async handleGrChart(event) {
        AdmReports.frmGrType = event.currentTarget.value;
        this.setState({ page: 0 });
    }


    async getSubs(event) {
        alert(1);
        if (AdmReports.fltCategory == "Products")
            return null;
        if (AdmReports.fltCategory == "Services")
            return null;
        if (AdmReports.fltCategory == "all")
            return null;
        if (AdmReports.fltCategory.substring(0, 2) == "p:") {
            var cat = AdmReports.fltCategory.substring(2);
            return this.state.categories.reduce((acc, curr) => {
                if (curr.pctMainType == cat)
                    acc.push(curr.pctCategory);
                return acc;
            }, []);
        }
        if (AdmReports.fltCategory.substring(0, 2) == "s:") {
            var cat = AdmReports.fltCategory.substring(2);
            return this.state.services.reduce((acc, curr) => {
                if (curr.scsType == cat)
                    acc.push(curr.scsName);
                return acc;
            }, []);
        }

    }

    async handleApply(event) {
        const data = await AdmReports.dataGet();
        const dbportdata = data.portdata;

        const filtered = dbportdata.filter(function (item) {
            return (
                ((AdmReports.fltMember !== "") ? item.memberName === AdmReports.fltMember : true) &&
                ((AdmReports.fltCountry !== "") ? item.country === AdmReports.fltCountry : true) &&
                ((AdmReports.fltVessel !== "") ? item.vessel === AdmReports.fltVessel : true) &&
                ( (AdmReports.fltCategory == "") ? true :
                    ((AdmReports.fltCategory == "Products") ? (item.prodID !== null) :
                        (AdmReports.fltCategory == "Services") ? (item.prodID == null) : 
                            (AdmReports.fltCategory.substring(0, 2) == "p:") ?
                                item.category == AdmReports.fltCategory.substring(2) :
                                (AdmReports.fltCategory.substring(0, 2) == "s:") ?
                                    item.category == AdmReports.fltCategory.substring(2) : true)
                )    
                &&
                ((AdmReports.fltSupplier !== "") ? item.supplierName === AdmReports.fltSupplier : true)
            );
        });

        this.setState({ portdata: filtered });
    }

    static async dataGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/Reports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbportdata = await response.json();
        // initialize sums
        AdmReports.totalPrice = 0;
        AdmReports.totalQuantity = 0;
        dbportdata.forEach(item => {
            item.sumQua = parseFloat(item.quantity)
            AdmReports.totalQuantity += parseFloat(item.quantity);
            item.sumPrice = parseFloat(item.price);
            AdmReports.totalPrice += Number.parseFloat(item.price);
        });


        // get Ports
        // var dbports = dbportdata.reduce((acc, curr) => {
        //    if (!(acc.find(item => item.port === curr.port)))
        //        acc.push(curr);
        //    return acc;
        // }, []);

        //get Countries
        var dbcountries = dbportdata.reduce((acc, curr) => {
            if (!(acc.find(item => item.country === curr.country)))
                acc.push(curr);
            return acc;
        }, []);


        // get Vessels
        const response3 = await fetch('mar1/Vessels', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvesselstemp = await response3.json();
        const dbvessels = dbvesselstemp.sort(
            (a, b) => a.vesCurrentName.localeCompare(b.vesCurrentName));

        // get Categories
        const response4 = await fetch('mar1/readCategories', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbcategories = await response4.json();
        // console.log(dbcategories);

        var dbcat = dbcategories.reduce((acc, curr) => {
            if (!(acc.find(item => item === curr.pctMainType)))
                acc.push(curr.pctMainType);
            return acc;
        }, []);

        dbcat = dbcat.sort((a, b) => a.localeCompare(b));

        const respond4b = await fetch('mar1/ServCatSys', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbservices = await respond4b.json(); 
        var dbserv = dbservices.reduce((acc, curr) => {
            if (!(acc.find(item => item === curr.scsType)))
                acc.push(curr.scsType);
            return acc;
        }, []);
               

        // get Suppliers
        const response5 = await fetch('mar1/Suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response5.json();

        //get Members
        const response6 = await fetch('mar1/Operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbmembers = await response6.json();

        // reduce vessels


        return {
            portdata: dbportdata, countries: dbcountries, members: dbmembers,
            vessels: dbvessels, categories: dbcategories, suppliers: dbsuppliers, cat: dbcat,
            services: dbservices, serv: dbserv
        };
    }

    async populateData() {
        const data = await AdmReports.dataGet();
        const dbportdata = data.portdata;
        const dbcountries = data.countries;
        const dbvessels = data.vessels;
        const dbcategories = data.categories;
        const dbcat = data.cat;
        const dbsuppliers = data.suppliers;
        const dbmembers = data.members;
        const dbservices = data.services;
        const dbserv = data.serv;

        this.setState({
            portdata: dbportdata, countries: dbcountries, vessels: dbvessels,
            categories: dbcategories, suppliers: dbsuppliers, members: dbmembers,
            loading: false, modal: this.state.modal, cat: dbcat, 
            services: dbservices, serv: dbserv

        });
    }

}

