import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmOperator extends Component {
    static displayName = AdmOperator.name;

    static modId = 0;
    static modName = "";
    static modContact = "";
    static modTelephone = "";
    static modEmail = "";
    static modShortName = "";
    static modRoad = "";
    static modCity = "";
    static modImo = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmOperator.modId = 0;
                AdmOperator.modName = "";
                AdmOperator.modContact = "";
                AdmOperator.modTelephone = "";
                AdmOperator.modEmail = "";
                AdmOperator.modShortName = "";
                AdmOperator.modRoad = "";
                AdmOperator.modCity = "";
                AdmOperator.modImo = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.operators.find(x => x.oprId === parseInt(val));
                AdmOperator.modId = myrec.oprId;
                AdmOperator.modName = myrec.oprName;
                AdmOperator.modContact = myrec.oprContact;
                AdmOperator.modTelephone = myrec.oprTelephone;
                AdmOperator.modEmail = myrec.oprEmail;
                AdmOperator.modShortName = myrec.oprShortName;
                AdmOperator.modRoad = myrec.oprRoad;
                AdmOperator.modCity = myrec.oprCity;
                AdmOperator.modImo = myrec.oprImo;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmOperator.operatorSave();
                await sleep(500);  // let the database catch up
                const data = await AdmOperator.operatorGet();
                const dboperators = data.operators;
                this.setState({ operators: dboperators, loading: false, modal: false });
            } else {
                await AdmOperator.operatorAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmOperator.operatorGet();
                const dboperators = data.operators;
                this.setState({ operators: dboperators, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async operatorSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/operators/' + AdmOperator.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    oprId: AdmOperator.modId, oprName: AdmOperator.modName,
                    oprContact: AdmOperator.modContact, oprTelephone: AdmOperator.modTelephone,
                    oprShortName: AdmOperator.modShortName, oprEmail: AdmOperator.modEmail,
                    oprRoad: AdmOperator.modRoad, oprCity: AdmOperator.modCity,
                    oprImo: AdmOperator.modImo
                }
            )
        };

        console.log(requestOptions.body);

        
        var res = await fetch(callString, requestOptions);
        if (!res.ok) {
            console.log(res.status);
            return "error";
        }
        return "ok";
    }

    static async operatorAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/operators';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    oprName: AdmOperator.modName,
                    oprContact: AdmOperator.modContact, oprTelephone: AdmOperator.modTelephone,
                    oprShortName: AdmOperator.modShortName, oprEmail: AdmOperator.modEmail,
                    oprRoad: AdmOperator.modRoad, oprCity: AdmOperator.modCity,
                    oprImo: AdmOperator.modImo
                }       
            )
        };  
        await fetch(callString, requestOptions);
    }

    static async operatorDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/operators/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        return await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            var ret = await AdmOperator.operatorDel(id);
            if (ret.ok) {
                alert("You submitted a Delete Request to the System\n" +
                    "The system users will process it and they will reply to you\n" +
                    "Please check Your Requests page"); 
            }
            else {
                alert("Record could not be deleted");
            }

            const data = await AdmOperator.operatorGet();
            const dboperators = data.operators;
            this.setState({ operators: dboperators, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { operators: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderoperatorsTable(operators, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th data-sortable="true">Operator Name</th>
                            <th>Path</th>
                            <th>Road</th>
                            <th>City</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {operators.map((operator) =>
                            <tr key={operator.oprId}>
                                <td>{operator.oprName}</td>
                                <td>{operator.oprShortName} </td>
                                <td>{operator.oprRoad}</td>
                                <td>{operator.oprCity}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={operator.oprId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={operator.oprId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = [...this.state.operators].sort((a, b) => a.oprName.localeCompare(b.oprName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmOperator.renderoperatorsTable(oper, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Members &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>Every Member manages a number of Vessels.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>operator</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                Member Name
                            </Label>
                            <Input onChange={(e) => AdmOperator.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmOperator.modName}
                            />
                            <Label for="modShortName" style={{ "padding-top": "15px" }}>
                                Member Path
                            </Label>
                            <Input disabled = {AdmOperator.modId !== 0}
                                id="modShortName"
                                type="text"
                                defaultValue={AdmOperator.modShortName}
                            />
                            <Label for="modImo" style={{ "padding-top": "15px" }}>
                                IMO Number
                            </Label>
                            <Input onChange={(e) => AdmOperator.modImo = e.target.value}
                                id="modImo"
                                type="text"
                                defaultValue={AdmOperator.modImo}
                            />
                            <Label for="modContact" style={{ "padding-top": "15px" }}>
                                Contact Name
                            </Label>
                            <Input onChange={(e) => AdmOperator.modContact = e.target.value}
                                id="modContact"
                                type="text"
                                defaultValue={AdmOperator.modContact}
                            />
                            <Label for="modTelephone" style={{ "padding-top": "15px" }}>
                                Telephone
                            </Label>
                            <Input onChange={(e) => AdmOperator.modTelephone = e.target.value}
                                id="modTelephone"
                                type="text"
                                defaultValue={AdmOperator.modTelephone}
                            />
                            <Label for="modEmail" style={{ "padding-top": "15px" }}>
                                Email 
                            </Label>
                            <Input onChange={(e) => AdmOperator.modEmail = e.target.value}
                                id="modEmail"
                                type="text"
                                defaultValue={AdmOperator.modEmail}
                            />
                            <Label for="modRoad" style={{ "padding-top": "15px" }}>
                                Road
                            </Label>
                            <Input onChange={(e) => AdmOperator.modRoad = e.target.value}
                                id="modRoad"
                                type="text"
                                defaultValue={AdmOperator.modRoad}
                            />
                            <Label for="modCity" style={{ "padding-top": "15px" }}>
                                City
                            </Label>
                            <Input onChange={(e) => AdmOperator.modCity = e.target.value}
                                id="modCity"
                                type="text"
                                defaultValue={AdmOperator.modCity}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmOperator.modId}>
                            { (AdmOperator.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async operatorGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response.json();
        return { operators: dboperators };
    }

    async populateData() {
        const data = await AdmOperator.operatorGet();
        const dboperators = data.operators;
        this.setState({
            operators: dboperators,
            loading: false, modal: this.state.modal
        });
    }

}

