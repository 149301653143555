import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function
export class MemSuppliers extends Component {
    static displayName = MemSuppliers.name;

    static modId = 0;
    static modSupplier = 0;
    static modOperName = "";
    static modContactName = "";
    static modTelephone = "";
    static input1 = "";

    static async newsupsGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/MemNewSups', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response.json();
        return dbsuppliers;
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        console.log("myhandleModal: " + val + " " + mod);
        if (mod === false) {
            if (val === "-1") {
                // find new suppliers
                const newsups = await MemSuppliers.newsupsGet();
                if (newsups.length === 0) {
                    alert("No new Suppliers found");
                    return;
                }
                MemSuppliers.modId = 0;
                MemSuppliers.modOperName = "";
                MemSuppliers.modContactName = "";
                MemSuppliers.modTelephone = "";
                MemSuppliers.modSupplier = newsups[0].supId;
                MemSuppliers.input1 =
                    <td><select onChange={(e) => MemSuppliers.modSupplier = e.target.value}
                        id="modSysPortId"
                        defaultValue={MemSuppliers.modSupplier}>
                    {newsups.map((supplier) =>
                        <option value={supplier.supId}>{supplier.supName}</option>)}
                    </select></td>
                this.setState({ modal: true });
            }
            else {
                console.log(this.state.opSuppliers);
                let myrec = this.state.opSuppliers.find(x => x.osuId == parseInt(val));
                MemSuppliers.modId = myrec.osuId;
                MemSuppliers.modOperName = myrec.osuOperName;
                MemSuppliers.modContactName = myrec.osuContactName;
                MemSuppliers.modTelephone = myrec.osuTelephone;
                MemSuppliers.modSupplier = myrec.osuSupplier;
                var name = myrec.supplierName;
                MemSuppliers.input1 =
                <Input disabled 
                    id="modName"
                    type="text"
                    defaultValue={name}
                />
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await MemSuppliers.supplierSave();
                await sleep(100);  // let the database catch up
                const data = await MemSuppliers.supplierGet();
                const dbsuppliers = data.opSuppliers;
                this.setState({ opSuppliers: dbsuppliers, loading: false, modal: false });
            } else {
                await MemSuppliers.supplierAdd();
                await sleep(500);  // let the database catch up
                const data = await MemSuppliers.supplierGet();
                const dbsuppliers = data.opSuppliers;
                this.setState({ opSuppliers: dbsuppliers, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async supplierSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memsuppliers/' + MemSuppliers.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    osuId: MemSuppliers.modId, osuOperName: MemSuppliers.modOperName,
                    osuContactName: MemSuppliers.modContactName, osuTelephone: MemSuppliers.modTelephone
                }
            )
        };
        const res = await fetch(callString, requestOptions);
        console.log(res);
        if (res.ok) return "ok";
        else return res.status;
    }

    static async supplierAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memsuppliers';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    osuSupplier: MemSuppliers.modSupplier,
                    osuOperName: MemSuppliers.modOperName,
                    osuOperator: null,
                    osuContactName: MemSuppliers.modContactName,
                    osuTelephone: MemSuppliers.modTelephone
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async supplierDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/memsuppliers/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await MemSuppliers.supplierDel(id);
            await sleep(1000);  // let the database catch up
            const data = await MemSuppliers.supplierGet();
            const dbsuppliers = data.opSuppliers;
            this.setState({ opSuppliers: dbsuppliers, loading: false, modal: false });
        }
    }


    constructor(props) {
        super(props);
        this.state = { opSuppliers: [], suppliers:[], loading: true, modal: false };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderSuppliersTable(suppliers, hModal, hDelete) {
        const sorted = [...suppliers].sort((a, b) => a.osuOperName.localeCompare(b.osuOperName));
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>System Name</th>
                            <th>My Name</th>
                            <th>My Contact</th>
                            <th>My Phone</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((supplier) =>
                            <tr key={supplier.osuId}>
                                <td>{supplier.supplierName}</td>
                                <td>{supplier.osuOperName}</td>
                                <td>{supplier.osuContactName}</td>
                                <td>{supplier.osuTelephone}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={supplier.osuId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <Button color="danger" onClick={hDelete} value={supplier.osuId}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : MemSuppliers.renderSuppliersTable( this.state.opSuppliers, this.handleModal, this.handleDelete);
        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Suppliers &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>A Supplier provides supplies the Operator's Vessels.</p>
                {contents1}
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Supplier</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                System Name
                            </Label>
                            {MemSuppliers.input1}
                            <Label for="modOperName" style={{ "padding-top": "15px" }}>
                                My Name
                            </Label>
                            <Input onChange={(e) => MemSuppliers.modOperName = e.target.value}
                                id="modOperName"
                                type="text"
                                defaultValue={MemSuppliers.modOperName}
                            />
                            <Label for="modContactName" style={{ "padding-top": "15px" }}>
                                My Contact
                            </Label>
                            <Input onChange={(e) => MemSuppliers.modContactName = e.target.value}
                                id="modContactName"
                                type="text"
                                defaultValue={MemSuppliers.modContactName}
                            />
                            <Label for="modTelephone" style={{ "padding-top": "15px" }}>
                                My phone
                            </Label>
                            <Input onChange={(e) => MemSuppliers.modTelephone = e.target.value}
                                id="modTelephone"
                                type="text"
                                defaultValue={MemSuppliers.modTelephone}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemSuppliers.modId}>
                            { (MemSuppliers.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async supplierGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/MemSuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopsuppliers = await response.json();

        const response2 = await fetch('mem1/ReadSuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();
        return { opSuppliers: dbopsuppliers, suppliers: dbsuppliers };
    }

    async populateData() {
        const data = await MemSuppliers.supplierGet();
        const dbopsuppliers = data.opSuppliers;
        const dbsuppliers = data.suppliers;
        this.setState({
            opSuppliers: dbopsuppliers, suppliers: dbsuppliers,
            loading: false, modal: this.state.modal
        });
    }

}

