import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AlassiaHome } from "./components/mem-home/Alassia";
import { EfnavHome } from "./components/mem-home/Efnav";
import { NomikosHome } from "./components/mem-home/Nomikos";
import { BlueseasHome } from './components/mem-home/Blueseas';
import { AlcyonHome } from './components/mem-home/Alcyon';
import { MmaritimeHome } from './components/mem-home/Mmaritime';
import { SealestialHome } from './components/mem-home/Sealestial';
import { SafetyHome } from './components/mem-home/Safety';
import { SafebulkersHome } from './components/mem-home/Safebulkers';
import { EquinoxHome } from './components/mem-home/Equinox';
import { GoulandrisHome } from './components/mem-home/Goulandris';
import { EfshippingHome } from './components/mem-home/Efshipping';
import { DianaHome } from './components/mem-home/Diana';
import { FrancoHome } from './components/mem-home/Franco';
import { DexterHome } from './components/mem-home/Dexter';
import { GenoaHome } from './components/mem-home/Genoa';
import { PapaHome } from './components/mem-home/Papa';
import { TestHome } from './components/mem-home/Test';
import { Navigate } from 'react-router-dom';
import { MemSuppliers } from './components/mem-pages/Suppliers';
import { MemOperator } from './components/mem-pages/Operator';
import { MemPorts } from './components/mem-pages/Ports';
import { MemUnitBases } from './components/mem-pages/UnitBases';
import { MemProdRef } from './components/mem-pages/ProdRef';
import { MemItems } from './components/mem-pages/Items';
import { MemVessels } from './components/mem-pages/Vessels';
import { MemHeaders } from './components/mem-pages/Headers';
import { MemUpload } from './components/mem-pages/Upload';
import { MemImport } from './components/mem-pages/Import';
import { MemArchive } from './components/mem-pages/Archive';
import { MemPrice } from './components/mem-pages/Proforma';
import { RepPortsQua } from './components/mem-pages/ReportPortsQua';

const path = sessionStorage.getItem("memberFront") ?? "/";

const MemRoutes = [
    {
        path: "mem-pages/suppliers",
        element: <MemSuppliers />
    },
    {
        path: "mem-pages/rep-ports-qua",
        element: <RepPortsQua />
    },
    {
        path: "mem-pages/headers",
        element: <MemHeaders />
    },
    {
        path: "mem-pages/upload",
        element: <MemUpload />
    },
    {
        path: "mem-pages/import",
        element: <MemImport />
    },
    {
        path: "mem-pages/archive",
        element: <MemArchive />
    },
    {
        path: "mem-pages/headers",
        element: <MemHeaders />
    },
    {
        path: "mem-pages/items",
        element: <MemItems />
    },
    {
        path: "mem-pages/vessels",
        element: <MemVessels />
    },
    {
        path: "mem-pages/prodrefs",
        element: <MemProdRef />
    },
    {
        path: "mem-pages/ports",
        element: <MemPorts />
    },
    {
        path: "mem-pages/unitbases",
        element: <MemUnitBases/>
    },
    {
        path: "mem-pages/proforma",
        element: <MemPrice />
    },
    {
        path: '/alassia',
        element: <AlassiaHome />
    },
    {
        path: '/efnav',
        element: <EfnavHome />
    },
    {
        path: '/nomikos',
        element: <NomikosHome />
    },
    {
        path: '/blueseas',
        element: <BlueseasHome />
    },
{
        path: '/alcyon',
        element: <AlcyonHome />
    },
    {
        path: '/mmaritime',
        element: <MmaritimeHome />
    },
    {
        path: '/sealestial',
        element: <SealestialHome />
    },
{
        path: '/safety',
        element: <SafetyHome />
    },
    {
        path: '/safebulkers',
        element: <SafebulkersHome />
    },
    {
        path: '/equinox',
        element: <EquinoxHome />
    },
    {
        path: '/goulandris',
        element: <GoulandrisHome />
    },
    {
        path: '/efshipping',
        element: <EfshippingHome />
    },
    {
        path: '/diana',
        element: <DianaHome />
    },
    {
        path: '/franco',
        element: <FrancoHome />
    },
    {
        path: '/dexter',
        element: <DexterHome />
    },
    {
        path: '/genoa',
        element: <GenoaHome />
    },
    {
        path: '/papa',
        element: <PapaHome />
    },
    {
        path: '/test',
        element: <TestHome />
    },
    {
        path: '/mem-pages/operator',
        element: <MemOperator />
    },
    {
        path: '/',
        element: <Navigate to={path} />
    },
  ...ApiAuthorzationRoutes
];

export default MemRoutes;
