import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

// const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function
const pageSize = 10;

export class AdmOpServices extends Component {
    static displayName = AdmOpServices.name;

    static modId = 0;
    static modSystemRow = 0;
    static modName = "";
    static input1 = "";
    static fltOperator = 0;

    static async newheadsGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/servcatmem/newSys/' + AdmOpServices.fltOperator, {
            Services: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbServices = await response.json();
        return dbServices;
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                // check filter
                if (parseInt(AdmOpServices.fltOperator) === 0) {
                    alert("Please select a Member first");
                    return;
                }
                // find new Services
                const newheads = await AdmOpServices.newheadsGet();
                if (newheads.length === 0) {
                    alert("There are no new Services available");
                    return;
                }
                AdmOpServices.modId = 0;
                AdmOpServices.modSystemRow = newheads[0].scsId;
                AdmOpServices.modName = "";
                AdmOpServices.input1 =
                    <td><select onChange={(e) => AdmOpServices.modSystemRow = e.target.value}
                        id="modSysRow" >
                        {newheads.map((head) =>
                            <option value={head.scsId}>{head.scsName}</option>)}
                    </select></td>
                this.setState({ modal: true });
            }
            else {
                console.log(this.state.opServices);
                console.log(val);
                let myrec = this.state.opServices.find(x => x.scmId === parseInt(val));
                console.log("myrec: " + myrec.scmId + " " + myrec.scmName + " " + myrec.scmSystemName)
                AdmOpServices.modId = myrec.scmId;
                AdmOpServices.modSystemRow = myrec.scmSystem;
                AdmOpServices.modName = myrec.scmName;
                AdmOpServices.input1 =
                    <Input
                        id="modSysRow"
                        type="text"
                        value={myrec.scmSystemName}
                    />
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                alert(1);
                const res = await AdmOpServices.headSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Header updated successfully");
                }
                const ret = await AdmOpServices.refreshData();
                this.setState({ opServices: ret.opServices, loading: false, modal: false });
            } else {
                const res = await AdmOpServices.headAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Header created successfully");
                }
                const ret = await AdmOpServices.refreshData();
                this.setState({ opServices: ret.opServices, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async headSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ServCatMem/' + AdmOpServices.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    scmId: AdmOpServices.modId, scmMember: parseInt(AdmOpServices.fltOperator),
                    scmSystem: AdmOpServices.modSystemRow, scmName: AdmOpServices.modName
                }
            )
        };
        const res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", status: res.status };
        return { result: "ok", status: "" };
    }

    static async headAdd() {
        if (AdmOpServices.modMemName === "") {
            return { result: "error", reason: "Service Name cannot be blank" };
        }
        const token = await authService.getAccessToken();
        var callString = 'mar1/ServCatMem';
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    scmMember: parseInt(AdmOpServices.fltOperator), 
                    scmSystem: AdmOpServices.modSystemRow, scmName: AdmOpServices.modName
                }
            )
        };
        const res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", status: res.status };
        return { result: "ok", status: "" };
    }

    static async headDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/servcatmem/' + id;
        const requestOptions = {
            method: 'DELETE',
            Services: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmOpServices.headDel(id);
            const ret = await AdmOpServices.refreshData();
            this.setState({ opServices: ret.opServices, loading: false, modal: false });
        }
    }

    async handleOper(event) {
        AdmOpServices.fltOperator = event;
        const data = await AdmOpServices.headGet();
        const dbopServices = data.opServices;
        const dboperators = data.operators;
        this.setState({
            opServices: dbopServices, operators: dboperators, loading: false, modal: this.state.modal
        });
    }

    constructor(props) {
        super(props);
        this.state = { opServices: [], operators: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOper = this.handleOper.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderServicesTable(opServices, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>System Name</th>
                            <th>System Type</th>
                            <th>Member Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opServices.map((head) =>
                            <tr key={head.scmId}>
                                <td>{head.scmSystemName}</td>
                                <td>{head.scmSystemType}</td>
                                <td>{head.scmName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={head.scmId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <Button color="danger" onClick={hDelete} value={head.scmId}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var opServices = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "boxShadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.opServices].sort(
                (a, b) => a.scmName.localeCompare(b.scmName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opServices = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opServices = sorted;
            }
        }

        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmOpServices.renderServicesTable(opServices, this.handleModal, this.handleDelete);

        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 id="tabelLabel" > Services (Member) &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-6 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <Label for="fltCat">
                                            Member Name
                                        </Label><br />
                                        <select onChange={(e) => this.handleOper(e.target.value)}
                                            defaultValue={AdmOpServices.fltOperator}>
                                            <option value="0">--Select--</option>
                                            {this.state.operators.map((operator) =>
                                                <option value={operator.oprId}>{operator.oprName}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Form>

                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Services Mapping</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSysRow">
                                System Name
                            </Label>
                            {AdmOpServices.input1}
                            <Label for="modName" style={{ "padding-top": "15px" }}>
                                Member Name
                            </Label>
                            <Input onChange={(e) => AdmOpServices.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmOpServices.modName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmOpServices.modId}>
                            { (AdmOpServices.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async refreshData(modal) {
        const data = await AdmOpServices.headGet();
        const dbopServices = data.opServices;
        return {
            opServices: dbopServices
        };
    }

    static async headGet() {
        const token = await authService.getAccessToken();
        const response2 = await fetch('mar1/operators', {
            Services: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbOperators = await response2.json();

        const response = await fetch('mar1/servcatmem/mem/' + this.fltOperator, {
            Services: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        var dbopServices = await response.json();
        return { opServices: dbopServices, operators: dbOperators };
    }

    async populateData() {
        const data = await AdmOpServices.headGet();
        const dbopServices = data.opServices;
        const dboperators = data.operators;
        this.setState({
            opServices: dbopServices, operators: dboperators,
            loading: false, modal: this.state.modal
        });
    }

}

