import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import Autosuggest from 'react-autosuggest';
import '../react-autosuggest.css';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 8;
var portList = [];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : portList.filter(port =>
        port.name.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);


export class MemPrice extends Component {
    static displayName = MemPrice.name;

    //lists
    static allproducts = [];
    static suppliers = [];

    //input data
    static frmQuantity = "";
    static frmPack = "Bulk";
    static frmProduct = null;

    // input hidden
    static frmQuarter1 = "";
    static frmQuarter2 = "";
    static frmQuarter3 = "";
    static frmPort1 = 0;
    static frmPort2 = 0;
    static frmPort3 = 0;
    static frmSupplier = 0;
    static frmDate1 = "";
    static frmDate2 = "";
    static frmDate3 = "";


    //header short
    static hdPortName1 = "";
    static hdPortName2 = "";
    static hdPortName3 = "";

    static hdSupplierName = "";
    static hdVesselName = "";
    static resCategory = "";
    static resUnit = "";

    // filter
    static frmProId = 0;

    //result data
    static resMethod1 = "";
    static resBasePrice1 = "";
    static resDiscount1 = "";
    static resPPD1 = "";
    static resPrice1 = "";
    static resRiceNum1 = 0.0;
    static resError1 = "";

    //result data port2
    static resMethod2 = "";
    static resBasePrice2 = "";
    static resDiscount2 = "";
    static resPPD2 = "";
    static resPrice2 = "";
    static resRiceNum2 = 0.0;
    static resError2 = "";

    //result data port3
    static resMethod3 = "";
    static resBasePrice3 = "";
    static resDiscount3 = "";
    static resPPD3 = "";
    static resPrice3 = "";
    static resRiceNum3 = 0.0;
    static resError3 = "";

    //modal data
    static modId = 0;
    static modName = "";
    static modPort1 = 0;
    static modPort2 = 0;
    static modPort3 = 0;
    static modSupplier = 0;
    static modMember = 0;
    static modVessel = 0;
    static modDate1 = "";
    static modDate2 = "";
    static modDate3 = "";

    // handle to handle
    static isCalc = false;

    constructor(props) {
        super(props);
        this.state = {
            ports: [], suppliers: [], products: [],
            loading: true, val: "", value: '', suggestions: [], headers: [],
            modal: false, page: 0, dets: [], vessels: [], curVes: 0, periods: [], cport: 0
        };
        this.handleCalc = this.handleCalc.bind(this);
        this.handleModal = this.handleModal.bind(this);
        //Proforma header
        this.handleFilter = this.handleFilter.bind(this);
        // this.handleMemVessels = this.handleMemVessels.bind(this);
        // handle CRUD
        this.handleDetAdd = this.handleDetAdd.bind(this);
        this.handleHeadDel = this.handleHeadDel.bind(this);
        this.handleHeaders = this.handleHeaders.bind(this);
        this.handleDetDelete = this.handleDetDelete.bind(this);
        // export
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static writePort(id, name, vl) {
        if ((id == 0) || (id == null))
            return (<div></div>);
        else return (
            <option value={vl} >{name}</option>
        );
    }

    static writeTotals(cc, len, t1, t2, t3) {
        if (cc == 1) {
            return (
                <div class="row">
                    <div class="col-lg-6">
                    </div>
                    <div class="col-lg-2">
                    Lines: <b>{len }</b>
                    </div>
                    <div class="col-lg-4">
                        Total: $ <b>{t1}</b>
                    </div>
                </div>
            );
        } else {
            return (
                <div class="row">
                    <div class="col-lg-1">
                    </div>
                    <div class="col-lg-2">
                    Lines: <b>{len}</b>
                    </div>
                    <div class="col-lg-3">
                        Total1: $ <b>{t1}</b>
                    </div>
                    {t2 != 0 ? (
                        <div class="col-lg-3">
                            Total2: $ <b>{t2}</b>
                        </div>) :
                        (<div></div>)
                    }
                    {t3 != 0 ? (
                        <div class="col-lg-3">
                            Total3: $ <b>{t3}</b>
                        </div>) :
                        (<div></div>)
                    }
                </div>
            );
        }
    }

    static displayone(cport, det, hDetDelete) {
        if (cport != 0) {
            var pdetId = det.pdetId;
            var pdetPackaging = det.pdetPackaging;
            var pdetProductName = det.pdetProductName;
            var pdetUnit = det.pdetUnit;
            var pdetQuantity = det.pdetQuantity;
            if (cport == 1) {
                var pdetBasePrice = det.pdetBasePrice1;
                var pdetDiscount = det.pdetDiscount1;
                var pdetPpd = det.pdetPpd1;
                var pdetPrice = det.pdetPrice1;
            } else if (cport == 2) {
                var pdetBasePrice = det.pdetBasePrice2;
                var pdetDiscount = det.pdetDiscount2;
                var pdetPpd = det.pdetPpd2;
                var pdetPrice = det.pdetPrice2;
            } else // cport = 3
            {
                var pdetBasePrice = det.pdetBasePrice3;
                var pdetDiscount = det.pdetDiscount3;
                var pdetPpd = det.pdetPpd3;
                var pdetPrice = det.pdetPrice3;
            }
            return (
                < tr key={pdetId} >
                    <td>{pdetQuantity}</td>
                    <td>{pdetPackaging}</td>
                    <td>{pdetProductName}</td>
                    <td>{pdetUnit}</td>
                    <td>{pdetBasePrice}</td>
                    <td>{pdetDiscount}</td>
                    <td>{pdetPpd}</td>
                    <td>{pdetPrice}</td>
                    <td>
                        <Button color="danger"
                            onClick={hDetDelete} value={pdetId} >
                            Delete
                        </Button>
                    </td>
                </tr>
            )
        }
    }

    static renderDets(dets, that, hDetDelete) {
        var val = MemPrice.frmProId;
        let myrec = that.headers.find(x => x.prohId === parseInt(val));
        if (myrec == null) return;
        var port1id = myrec.prohPort1;
        var port2id = myrec.prohPort2 ?? 0;
        var port3id = myrec.prohPort3 ?? 0;
        var port1 = that.ports.find(x => x.prtId === port1id).prtName;
        var port2 = (port2id != 0) ? that.ports.find(x => x.prtId === port2id).prtName : "";
        var port3 = (port3id != 0) ? that.ports.find(x => x.prtId === port3id).prtName : "";
        var pcount = 1 + ((port2id != 0) ? 1 : 0) + ((port3id != 0) ? 1 : 0);
        console.log("pcount: " + pcount);
        console.log("cport: " + that.cport);
        if (pcount === 1) that.cport = 1;
        if ((pcount === 1) || (that.cport != 0)) {
            return (
                <div>
                    <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Pack.</th>
                                <th>Product</th>
                                <th>Unit</th>
                                <th>BPrice100</th>
                                <th>Dics.</th>
                                <th>PPD</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dets.map((det) => MemPrice.displayone(that.cport, det, hDetDelete))}
                        </tbody>
                    </table>
                </div>
            );
        }
        else {
            return (
                <div>
                    <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Pack.</th>
                                <th>Product</th>
                                <th>Unit</th>
                                <th>port:{port1}</th>
                                {(port2 != "") ? (<th>port:{port2}</th>) : (<th></th>)}
                                {(port3 != "") ? (<th>port:{port3}</th>) : (<th></th>)}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dets.map((det) =>
                                <tr key={det.pdettId}>
                                    <td>{det.pdetQuantity}</td>
                                    <td>{det.pdetPackaging}</td>
                                    <td>{det.pdetProductName}</td>
                                    <td>{det.pdetUnit}</td>
                                    <td>{det.pdetPrice1}</td>
                                    {(port2 != "") ? (<td>{det.pdetPrice2}</td>) : (<td></td>)}
                                    {(port3 != "") ? (<td>{det.pdetPrice3}</td>) : (<td></td>)}
                                    <td>
                                        <Button color="danger"
                                            onClick={hDetDelete} value={det.pdetId} >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            );
        }
    }


    async handleCalc(event) {
        MemPrice.isCalc = true;
        var ret = await this.doCalc();
        // if (!MemPrice.isCalc) return;
        this.setState({ loading: false });
    }

    async handleHeaders(event) {
        let val = event.target.value;
        /*
        MemPrice.frmProId = val;
        // set frmPort, frmSupplier, frmDate & curSup
        var myrec = this.state.headers.find(x => x.prohId === parseInt(val));
        MemPrice.frmPort1 = myrec.prohPort1;
        MemPrice.frmPort2 = myrec.prohPort2 ?? 0;
        MemPrice.frmPort3 = myrec.prohPort3 ?? 0;
        var pcount = 1 + ((MemPrice.frmPort2 != 0) ? 1 : 0) + ((MemPrice.frmPort3 != 0) ? 1 : 0);
        MemPrice.frmSupplier = myrec.prohSupplier;
        MemPrice.frmDate1 = myrec.prohDate1;
        MemPrice.hdPortName1 = this.state.ports.find(x => x.prtId === myrec.prohPort1).prtName;
        MemPrice.hdPortName2 = (MemPrice.frmPort2 != 0) ? this.state.ports.find(x => x.prtId === myrec.prohPort2).prtName : "";
        MemPrice.hdPortName3 = (MemPrice.frmPort3 != 0) ? this.state.ports.find(x => x.prtId === myrec.prohPort3).prtName : "";
        MemPrice.hdSupplierName = this.state.suppliers.find(x => x.supId === myrec.prohSupplier).supName;
        MemPrice.hdVesselName = this.state.vessels.find(x => x.vesId === myrec.prohVessel).vesCurrentName;
        var cport = (pcount == 1) ? 1: 0;
        */
        this.setState({ hpoint: val, cport:0 });
    }

    async handleExport(event) {
        // get list to export
        if (this.state.headers.length === 0) { alert("No List to Export"); return; }
        if (MemPrice.frmProId == 0) MemPrice.frmProId = this.state.headers[0].prohId;
        // send header id to server
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListExport/' + MemPrice.frmProId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
            // load file to client
            var res = "upload/proforma" + MemPrice.frmProId + ".xlsx";
            window.open(res, '_blank', 'noreferrer');
            await sleep(1000);
            /*
            alert("Make sure that your file is uploaded\n" +
                "and Click OK to remove it from the server\n" +
                  "for security reasons");
            */
        }
        else alert("failed to export this list");
    }

    async doCalc() {
        if (MemPrice.frmQuantity == null) MemPrice.frmQuantity = 0;

        if (MemPrice.frmPort1 == null) MemPrice.frmPort1 = 0;
        if (MemPrice.frmPort2 == null) MemPrice.frmPort2 = 0;
        if (MemPrice.frmPort3 == null) MemPrice.frmPort3 = 0;


        if (MemPrice.frmProduct == null) {
            alert("Please select a Product");
            MemPrice.isCalc = false;
            return;
        }

        if ((MemPrice.frmQuantity == "") || (MemPrice.frmQuantity == 0)) {
            alert("Please insert a Quantity");
            MemPrice.isCalc = false;
            return;
        }

        // get periods

        var myDate = Date.parse(MemPrice.frmDate1);
        var formDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
            .format(myDate);

        var period = this.state.periods.find
            (x => ((Date.parse(x.perStart) <= myDate) && (Date.parse(x.perEnd) >= myDate)
                && (x.perSupplier == MemPrice.frmSupplier))
            );

        if (period == null) {
            alert("No prices for the selected date: " + formDate);
            MemPrice.isCalc = false;
            return;
        }
        MemPrice.frmQuarter1 = period.perName;


        if (MemPrice.frmPort2 != 0) {
            // check empty date
            if (MemPrice.frmDate2 == "") {
                alert("Please enter a date for Port2");
                MemPrice.isCalc = false;
                return;
                }
            myDate = Date.parse(MemPrice.frmDate2);
            formDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
                .format(myDate);
            period = this.state.periods.find
                (x => ((Date.parse(x.perStart) <= myDate) && (Date.parse(x.perEnd) >= myDate)
                    && (x.perSupplier == MemPrice.frmSupplier))
                );

            if (period == null) {
                alert("No prices for the selected date: " + formDate);
                MemPrice.isCalc = false;
                return;
            }
            MemPrice.frmQuarter2 = period.perName;
        }

        if (MemPrice.frmPort3 != 0) {
            // check empty date
            if (MemPrice.frmDate3 == "") {
                alert("Please enter a date for Port3");
                MemPrice.isCalc = false;
                return;
            }
            myDate = Date.parse(MemPrice.frmDate2);
            formDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
                .format(myDate);
            period = this.state.periods.find
                (x => ((Date.parse(x.perStart) <= myDate) && (Date.parse(x.perEnd) >= myDate)
                    && (x.perSupplier == MemPrice.frmSupplier))
                );

            if (period == null) {
                alert("No prices for the selected date: " + formDate);
                MemPrice.isCalc = false;
                return;
            }
            MemPrice.frmQuarter3 = period.perName;
        }


        const token = await authService.getAccessToken();
        var response = await fetch('mar1/getPrice', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    Supplier: MemPrice.frmSupplier,
                    Quantity: MemPrice.frmQuantity,
                    Pack: MemPrice.frmPack,
                    Product: MemPrice.frmProduct,
                    Port: MemPrice.frmPort1,
                    Period: MemPrice.frmQuarter1
                }
            )
        });

        if (!response.ok) {
            alert("Bad Input. Please correct");
            MemPrice.isCalc = false;
            return;
        }

        var res = await response.json();
        if (res.resError != "ok") {
            alert(res.resError);
            MemPrice.isCalc = false;
        }

        MemPrice.resCategory = res.resCategory;
        MemPrice.resUnit = res.resUnit;

        MemPrice.resMethod1 = res.resMethod;
        MemPrice.resBasePrice1 = res.resBasePrice;
        MemPrice.resDiscount1 = res.resDiscount;
        MemPrice.resPPD1 = res.resPPD;
        MemPrice.resPrice1 = res.resPrice;
        MemPrice.resPriceNum1 = res.resPriceNum;

        //price port2
        MemPrice.resMethod2 = null;
        MemPrice.resBasePrice2 = null;
        MemPrice.resDiscount2 = null;
        MemPrice.resPPD2 = null;
        MemPrice.resPrice2 = null;
        MemPrice.resPriceNum2 = null;
        if (MemPrice.frmPort2 != 0) {
            const token = await authService.getAccessToken();
            var response2 = await fetch('mar1/getPrice', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        Supplier: MemPrice.frmSupplier,
                        Quantity: MemPrice.frmQuantity,
                        Pack: MemPrice.frmPack,
                        Product: MemPrice.frmProduct,
                        Port: MemPrice.frmPort2,
                        Period: MemPrice.frmQuarter2
                    }
                )
            });
            if (!response2.ok) {
                alert("Bad Input (2). Please correct");
                MemPrice.isCalc = false;
                return;
            }

            var res2 = await response2.json();
            if (res2.resError != "ok") {
                alert(res2.resError);
                MemPrice.isCalc = false;
            }


            MemPrice.resMethod2 = res2.resMethod;
            MemPrice.resBasePrice2 = res2.resBasePrice;
            MemPrice.resDiscount2 = res2.resDiscount;
            MemPrice.resPPD2 = res2.resPPD;
            MemPrice.resPrice2 = res2.resPrice;
            MemPrice.resPriceNum2 = res2.resPriceNum;
        }


        //price port3
        MemPrice.resMethod3 = null;
        MemPrice.resBasePrice3 = null;
        MemPrice.resDiscount3 = null;
        MemPrice.resPPD3 = null;
        MemPrice.resPrice3 = null;
        MemPrice.resPriceNum3 = null;

        if (MemPrice.frmPort3 != 0) {
            const token = await authService.getAccessToken();
            var response3 = await fetch('mar1/getPrice', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        Supplier: MemPrice.frmSupplier,
                        Quantity: MemPrice.frmQuantity,
                        Pack: MemPrice.frmPack,
                        Product: MemPrice.frmProduct,
                        Port: MemPrice.frmPort3,
                        Period: MemPrice.frmQuarter3
                    }
                )
            });
            if (!response3.ok) {
                alert("Bad Input (2). Please correct");
                MemPrice.isCalc = false;
                return;
            }

            var res3 = await response3.json();
            if (res3.resError != "ok") {
                alert(res3.resError);
                MemPrice.isCalc = false;
            }


            MemPrice.resMethod3 = res3.resMethod;
            MemPrice.resBasePrice3 = res3.resBasePrice;
            MemPrice.resDiscount3 = res3.resDiscount;
            MemPrice.resPPD3 = res3.resPPD;
            MemPrice.resPrice3 = res3.resPrice;
            MemPrice.resPriceNum3 = res3.resPriceNum;
        }
    }

    handleFilter(event) {
        let val = event.target.value;
        MemPrice.modSupplier = val;
        // this.setState({ curSup: val });
    }

    // handleMemVessels(event) {
    //    let val = event.target.value;
    //    MemPrice.modMember = val;
    //    this.setState({ curVes: val });
    // }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                MemPrice.modId = 0;
                MemPrice.modName = "";
                MemPrice.modPort1 = 0;
                MemPrice.modPort2 = 0;
                MemPrice.modPort3 = 0;
                this.onChange1(event, { newValue: "" });
                this.onChange2(event, { newValue: "" });
                this.onChange3(event, { newValue: "" });
                MemPrice.modSupplier = 0;
                MemPrice.modMember = 0;
                MemPrice.modVessel = 0;
                MemPrice.modDate1 = "";
                MemPrice.modDate2 = "";
                MemPrice.modDate3 = "";
                this.setState({ modal: true, curVes: 0 });
            }
            else {
                if (this.state.headers.length === 0) {
                    alert("No Proformas found");
                    return;
                }
                if (MemPrice.frmProId == 0) MemPrice.frmProId = this.state.headers[0].prohId;
                val = MemPrice.frmProId;
                let myrec = this.state.headers.find(x => x.prohId === parseInt(val));
                MemPrice.modId = myrec.prohId;
                MemPrice.modName = myrec.prohName;
                MemPrice.modPort1 = myrec.prohPort1;
                MemPrice.modPort2 = myrec.prohPort2 ?? 0;
                MemPrice.modPort3 = myrec.prohPort3 ?? 0;
                const portName1 = this.state.ports.find(x => x.prtId === myrec.prohPort1).prtName;
                const portName2 = (MemPrice.modPort2 != 0) ?
                    this.state.ports.find(x => x.prtId === myrec.prohPort2).prtName : "";
                const portName3 = (MemPrice.modPort3 != 0) ?
                    this.state.ports.find(x => x.prtId === myrec.prohPort3).prtName : "";
                this.onChange1(event, { newValue: portName1 });
                this.onChange2(event, { newValue: portName2 });
                this.onChange3(event, { newValue: portName3 });
                MemPrice.modSupplier = myrec.prohSupplier;
                MemPrice.modMember = myrec.prohMember;
                MemPrice.modVessel = myrec.prohVessel;
                MemPrice.modDate1 = myrec.prohDate1;
                MemPrice.modDate2 = myrec.prohDate2;
                MemPrice.modDate3 = myrec.prohDate3;
                //const dd = date.slice(0, 2), dm = date.slice(3, 5), dy = date.slice(6, 10);
                //MemPrice.modDate = dy+"-" + dm + "-" + dd;
                this.setState({ modal: true, curVes: myrec.prohMember });
            }
        }
        else {
            if (val !== "-1") {
                if (val !== "0") {
                    if (window.confirm("This may delete other Suppliers items \n\r" +
                        "and may affect prices for all lines \n\r " +
                        "Are you sure? ") === false) {
                        return;
                    }
                    MemPrice.frmProId = val;
                    // check no Ports without date
                    if ((MemPrice.modPort1 == 0)) {
                        alert("Port1 cannot be emty");
                        return;
                    }
                    if (MemPrice.modDate1 == "") {
                        alert("Please enter a date for Port1");
                        return;
                    }
                    if ((MemPrice.modPort2 != 0) && (MemPrice.modDate2 == "")) {
                        alert("Please enter a date for Port2");
                        return;
                    }
                    if ((MemPrice.modPort3 != 0) && (MemPrice.modDate3 == "")) {
                        alert("Please enter a date for Port3");
                        return;
                    }
                    const res = await MemPrice.headUpdate();
                    if (res.result !== "ok") {
                        alert("Error: " + res.result);
                        this.setState({ modal: false });
                        return;
                    }

                    // header updated
                    // update dets

                    const token = await authService.getAccessToken();
                    var response = await fetch('mar1/getprice/' + MemPrice.frmProId, {
                        method: 'PUT',
                        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
                    });

                    // alert("ProForma updated successfully");
                    // await sleep(500);
                    // this.populateData();
                    // this.setState({ modal: false });
                    const response2 = await fetch('mar1/proListHeads', {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    const dbheaders = await response2.json();
                    const response3 = await fetch('mar1/proListDets', {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    });
                    const dbdets = await response3.json();
                    this.setState({
                        headers: dbheaders, dets: dbdets, hpoint: this.state.hpoint,
                        cport: 0, modal: false
                    });
                    return;
                    // let the database catch up
                    //    const token = await authService.getAccessToken();
                    //    const response = await fetch('mar1/proListHeads', {
                    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    //    });
                    //    const dbheaders = await response.json();
                    //    this.setState({ headers: dbheaders, modal: false });
                    //    return;                        

                } else {
                    const res = await MemPrice.headAdd();
                    if (res.result !== "ok") {
                        alert("Error: " + res.result);
                        return;
                    } else {
                        alert("Proforma created successfully");
                        // await sleep(1000);
                        // let the database catch up
                        // this.populateData();
                        const token = await authService.getAccessToken();
                        const response = await fetch('mar1/proListHeads', {
                            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                        });
                        const dbheaders = await response.json();
                        const rec = res.rec;
                        this.setState({
                            headers: dbheaders, hpoint: rec.prohId, modal: false, cport: 0
                        });
                        return;
                    }

                }
            }
            else {
                this.setState({ modal: false });
            }
        }
    }

    static async headAdd() {
        const token = await authService.getAccessToken();
        var response = await fetch('mem1/MemListHeads', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    proUser: null,
                    ProhName: MemPrice.modName,
                    ProhMember: null,
                    prohSupplier: MemPrice.modSupplier,
                    prohPort1: MemPrice.modPort1,
                    prohPort2: MemPrice.modPort2,
                    prohPort3: MemPrice.modPort3,
                    ProhVessel: MemPrice.modVessel,
                    ProhDate1: MemPrice.modDate1,
                    ProhDate2: MemPrice.modDate2,
                    ProhDate3: MemPrice.modDate3
                }
            )
        });
        var res = await response.json();
        if (response.status === 201) // created
            return { result: "ok", rec: res };
        else {
            return { result: res }
        }
    }

    static async headUpdate() {
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListHeads/' + MemPrice.frmProId, {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    ProhId: MemPrice.frmProId,
                    ProhName: MemPrice.modName,
                    ProhMember: MemPrice.modMember,
                    prohSupplier: MemPrice.modSupplier,
                    prohPort1: MemPrice.modPort1,
                    prohPort2: MemPrice.modPort2,
                    prohPort3: MemPrice.modPort3,
                    ProhVessel: MemPrice.modVessel,
                    ProhDate1: MemPrice.modDate1,
                    ProhDate2: MemPrice.modDate2,
                    ProhDate3: MemPrice.modDate3
                }
            )
        });
        if (response.status == 204) // nocontent cannot be read by json
            return { result: "ok" };
        else {
            return { result: "error " + response.status }
        }
    }

    async handleDetAdd(event) {
        if (this.state.headers.length === 0) {
            alert("Please create a List"); return;
        }


        if (MemPrice.frmProId == 0) MemPrice.frmProId = this.state.headers[0].prohId;
        MemPrice.isCalc = true;
        var ret = await this.doCalc();
        if (!MemPrice.isCalc) {
            return;
        }


        var prod = this.state.products.find(x => x.prdId == MemPrice.frmProduct);
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListDets', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    PdetHead: MemPrice.frmProId,
                    PdetProduct: MemPrice.frmProduct,
                    PdetQuantity: parseFloat(MemPrice.frmQuantity),
                    PdetPackaging: MemPrice.frmPack,
                    PdetPeriod: MemPrice.frmQuarter,
                    PdetCategory: MemPrice.resCategory,
                    PdetUnit: MemPrice.resUnit,

                    PdetPrice1: MemPrice.resPrice1,
                    PdetDiscount1: MemPrice.resDiscount1,
                    PdetPpd1: MemPrice.resPPD1,
                    PdetMethod1: MemPrice.resMethod1,
                    PdetBasePrice1: MemPrice.resBasePrice1,
                    PdetPriceNum1: MemPrice.resPriceNum1,
                    PdetPort1: MemPrice.frmPort1,
                    PdetDate1: MemPrice.frmDate1,

                    PdetPrice2: MemPrice.resPrice2,
                    PdetDiscount2: MemPrice.resDiscount2,
                    PdetPpd2: MemPrice.resPPD2,
                    PdetMethod2: MemPrice.resMethod2,
                    PdetBasePrice2: MemPrice.resBasePrice2,
                    PdetPriceNum2: MemPrice.resPriceNum2,
                    PdetPort2: MemPrice.frmPort2,
                    PdetDate2: MemPrice.frmDate2,

                    PdetPrice3: MemPrice.resPrice3,
                    PdetDiscount3: MemPrice.resDiscount3,
                    PdetPpd3: MemPrice.resPPD3,
                    PdetMethod3: MemPrice.resMethod3,
                    PdetBasePrice3: MemPrice.resBasePrice3,
                    PdetPriceNum3: MemPrice.resPriceNum3,
                    PdetPort3: MemPrice.frmPort3,
                    PdetDate3: MemPrice.frmDate3,

                    PdetUser: null,
                    PdetProductName: prod.prdRefCode
                }
            )
        });
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();
        this.setState({ dets: dbdets });
    }

    async handleHeadDel(event) {
        if (this.state.headers.length === 0) {
            alert("No List to Delete"); return;
        }
        if (window.confirm("Are you sure you want to delete this item?") === false) {
            return;
        }
        if (MemPrice.frmProId == 0) MemPrice.frmProId = this.state.headers[0].prohId;
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListHeads/' + MemPrice.frmProId, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        const response4 = await fetch('mar1/proListHeads', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbheaders = await response4.json();
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();

        var hpoint = 0;
        if (dbheaders.length > 0) {
             MemPrice.frmProId = dbheaders[0].prohId;
             hpoint = dbheaders[0].prohId;
             }
        else {
            MemPrice.frmProId = 0;
            MemPrice.frmDate1 = "";
            MemPrice.frmDate2 = "";
            MemPrice.frmDate3 = "";
            MemPrice.hdPortName1 = "";
            MemPrice.hdPortName2 = "";
            MemPrice.hdPortName3 = "";
            MemPrice.hdSupplierName = "";
            MemPrice.hdVesselName = "";
        }
        this.setState({ headers: dbheaders, dets: dbdets, hpoint: hpoint });
    }

    async handleDetDelete(event) {
        let val = event.target.value;
        if (window.confirm("Are you sure you want to delete this item?") === false) {
            return;
        }
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/ProListDets/' + val, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();
        this.setState({ dets: dbdets });
    }


    onChange1 = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for 1: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            MemPrice.modPort1 = myport.prtId;
        }
        else {
            MemPrice.modPort1 = 0;
        }
        this.setState({
            value: newValue,
        });
    }

    onChange2 = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for 2: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            MemPrice.modPort2 = myport.prtId;
        }
        else {
            MemPrice.modPort2 = 0;
        }
        this.setState({
            value2: newValue,
        });
    }

    onChange3 = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for 2: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            MemPrice.modPort3 = myport.prtId;
        }
        else {
            MemPrice.modPort3 = 0;
        }
        this.setState({
            value3: newValue,
        });
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, value2, value3, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps =
        {
            placeholder: 'Type a port',
            value: value,
            onChange: this.onChange1
        };

        const inputProps2 =
        {
            placeholder: 'Type a port',
            value: value2,
            onChange: this.onChange2
        };

        const inputProps3 =
        {
            placeholder: 'Type a port',
            value: value3,
            onChange: this.onChange3
        };


        var ports = [];
        var products = [];
        // var memVessels = [];
        var curSup = 1;
        var curVes = this.state.curVes;
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        var lines = [];
        var total1 = 0;
        var total2 = 0;
        var total3 = 0;

        if (!this.state.loading) {
            if (this.state.headers.length > 0) {
                var res = this.state.headers.find(x => x.prohId == this.state.hpoint);
                MemPrice.frmProId = res.prohId;
                MemPrice.frmPort1 = res.prohPort1;
                MemPrice.frmPort2 = res.prohPort2 ?? 0;
                MemPrice.frmPort3 = res.prohPort3 ?? 0;
                MemPrice.frmSupplier = res.prohSupplier;
                MemPrice.frmDate1 = res.prohDate1;
                MemPrice.frmDate2 = res.prohDate2;
                MemPrice.frmDate3 = res.prohDate3;
                MemPrice.hdPortName1 = this.state.ports.find(x => x.prtId === res.prohPort1).prtName;
                MemPrice.hdPortName2 = (MemPrice.frmPort2 != 0) ? this.state.ports.find(x => x.prtId === res.prohPort2).prtName : "";
                MemPrice.hdPortName3 = (MemPrice.frmPort3 != 0) ? this.state.ports.find(x => x.prtId === res.prohPort3).prtName : "";

                MemPrice.hdSupplierName = this.state.suppliers.find(x => x.supId === res.prohSupplier).supName;
                MemPrice.hdVesselName = this.state.vessels.find(x => x.vesId === res.prohVessel).vesCurrentName;
                curSup = MemPrice.frmSupplier;
            }

            ports = this.state.ports;
            MemPrice.suppliers = this.state.suppliers;
            MemPrice.allproducts = this.state.products;

            products = MemPrice.allproducts.filter(function (product) {
                return product.prdSupplier == curSup;
            });
            //memVessels = this.state.vessels.filter(function (vessel) {
            //    return vessel.vesOperator == curVes;
            //});

            if (MemPrice.frmProduct == null)
                MemPrice.frmProduct = products[0].prdId;
            if (MemPrice.frmSupplier == null)
                MemPrice.frmSupplier = MemPrice.suppliers[0].supId;
            portList = [];
            ports.forEach((port) => {
                portList.push({ name: port.prtName, id: port.ptrId });
            });
            // filter
            lines = this.state.dets;
            if ((this.state.headers.length > 0) && (MemPrice.frmProId == 0))
                MemPrice.frmProId = this.state.headers[0].prohId;
            lines = lines.filter(function (line) {
                return line.pdetHead == MemPrice.frmProId;
            });
            for (let i = 0; i < lines.length; i++) {
                total1 += lines[i].pdetPriceNum1;
                if (lines[i].pdetPort2 != 0) total2 += lines[i].pdetPriceNum2;
                if (lines[i].pdetPort3 != 0) total3 += lines[i].pdetPriceNum3;
            }
            total1 = total1.toFixed(2);
            total2 = total2.toFixed(2);
            total3 = total3.toFixed(2);
            //pages
            const sorted = lines;
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                lines = sorted.slice((page) * pageSize, (page + 1) * pageSize);
            } else {
                lines = sorted;
            }
        }

        const contents1 = this.state.loading
            ? <p><em>...loading </em></p>
            : MemPrice.renderDets(lines, this.state, this.handleDetDelete);

        if ((!this.state.loading) && (pages > 1)) {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc + 1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                <PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }


        var pcount = 1 + (((MemPrice.frmPort2 == null) || (MemPrice.frmPort2 == 0)) ? 0 : 1)
                       + (((MemPrice.frmPort3 == null) || (MemPrice.frmPort3 == 0)) ? 0 : 1);
        console.log("rendering");
        console.log("port1:" + MemPrice.frmPort1);
        console.log("port2:" + MemPrice.frmPort2);
        console.log("port3:" + MemPrice.frmPort3);

        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2">
                            <h2 id="tabelLabel" > Find Prices
                            </h2>
                        </div>
                        <div class="col-lg-4">
                        Proforma Name
                            <select style={{
                                "width": "100%", "height": "37px",
                                "margin-top": "7px", "background": "aliceblue" }}
                                onChange={this.handleHeaders}
                                defaultValue={MemPrice.frmProId}
                                id="frmProId">
                                {this.state.headers.map((head) =>
                                    <option value={head.prohId}
                                    >{head.prohName}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Button color="info" onClick={this.handleModal} value={-1}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                New Header
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="info" onClick={this.handleModal} value={MemPrice.frmProId}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                Edit Header
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="danger" onClick={this.handleHeadDel}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                Delete Header
                            </Button>
                        </div>
                    </div>

                    <div class="row" style={{ "background": "beige", "height": "40px", "margin-top": "10px" }}>
                        <div class="col-lg-2">
                            Supplier: <b>{MemPrice.hdSupplierName}</b>
                        </div>
                        <div class="col-lg-4">
                            <div style={{ "display": "inline-block" }}>Port:&nbsp;</div>
                            {(pcount == 1) ?
                                (
                                    <div style={{ "display": "inline" }}>
                                        <b>{MemPrice.hdPortName1}</b></div>
                                ) :
                                (
                                    <select style={{ "display": "inline", "height": "30px", "background-color": "#e9ecef" }}
                                        defaultValue="0" onChange={(e) => this.setState({ cport: e.target.value }) } >
                                        <option value="0">All</option>
                                        {MemPrice.writePort(MemPrice.frmPort1, MemPrice.hdPortName1, 1)}
                                        {MemPrice.writePort(MemPrice.frmPort2, MemPrice.hdPortName2, 2)}
                                        {MemPrice.writePort(MemPrice.frmPort3, MemPrice.hdPortName3, 3)}
                                    </select>
                                )
                            }
                        </div>
                        {(pcount == 1) ?
                            (
                                <div class="col-lg-2">
                                    Date: <b>{MemPrice.frmDate1}</b>
                                </div>
                            ) :
                            (
                                (this.state.cport == 0) ?
                                    (
                                        <div class="col-lg-2">
                                        </div>
                                    ) :
                                    (
                                        <div class="col-lg-2">
                                            Date: <b>
                                                {(this.state.cport == 1) ? MemPrice.frmDate1
                                                    : (this.state.cport == 2) ? MemPrice.frmDate2
                                                        : MemPrice.frmDate3
                                                }
                                            </b>
                                        </div>
                                    )
                            )
                        }
                        <div class="col-lg-4">
                            Vessel: <b>{MemPrice.hdVesselName.substring(0,
                                Math.min(MemPrice.hdVesselName.length, 26))}</b>
                        </div>

                    </div>

                    <div class="row" style={{ "margin-top": "5px" }}>
                        <div class="col-lg-4">
                            <Label><b>Product</b></Label>
                            <select style={{ "width": "100%", "height": "35px", "background-color": "#e9ecef" }}
                                onChange={(e) => MemPrice.frmProduct = e.target.value}>
                                id = "frmproduct"
                                {products.map((product) =>
                                    <option value={product.prdId}
                                    >{product.prdRefCode}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Label><b>Packaging</b></Label>
                            <select style={{ "width": "100%", "height": "35px", "background-color": "#e9ecef" }}
                                onChange={(e) => MemPrice.frmPack = e.target.value}>
                                <option value="Bulk">Bulk</option>
                                <option value="Ibc">IBC</option>
                                <option value="Drum">Drum</option>
                                <option value="Pail">Pail</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Label><b>Quantity</b></Label>
                            <Input onChange={(e) => MemPrice.frmQuantity = e.target.value}
                                id="frmQuantity"
                                type="text" defaultValue = "0"
                                style={{ "background-color": "#e9ecef", "text-align":"right" }}
                            />
                        </div>
                        <div class="col-lg-2">
                            <Button color="success" onClick={this.handleDetAdd }
                                style={{ "width": "100%", "margin-top": "30px" }}>
                                Add Line
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="warning" onClick={this.handleExport} value={MemPrice.frmProId}
                                style={{ "width": "100%", "margin-top": "30px" }}>
                                Excel Export
                            </Button>
                        </div>
                    </div>
                </div>
                <br />
                {contents1}

                <div class="container">
                        {MemPrice.writeTotals(pcount, lines.length, total1, total2, total3)}
                </div>


                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages - 1, page + 1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })} />  </PaginationItem>
                </Pagination>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Proforma</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName" style={{ "padding-top": "15px" }}>
                            <b>Name</b>
                            </Label>
                            <Input onChange={(e) => MemPrice.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={MemPrice.modName}
                            />
                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port 1 / Date 1</b></Label>
                            <Autosuggest
                                id="modPort1"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                            <Input onChange={(e) => MemPrice.modDate1 = e.target.value}
                                id="DateFrom" type="date"
                                dateformat='DD/M/YYYY'
                                style={{
                                    "height": "35px",
                                    "background-color": "#e9ecef"
                                }} defaultValue={MemPrice.modDate1}
                            />
                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port 2 / Date 2</b></Label>
                            <Autosuggest
                                id="modPort2"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps2}
                            />
                            <Input onChange={(e) => MemPrice.modDate2 = e.target.value}
                                id="DateFrom" type="date"
                                dateformat='DD/M/YYYY'
                                style={{
                                    "height": "35px",
                                    "background-color": "#e9ecef"
                                }} defaultValue={MemPrice.modDate2}
                            />
                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port 3 / Date 3</b></Label>
                            <Autosuggest
                                id="modPort3"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps3}
                            />
                            <Input onChange={(e) => MemPrice.modDate3 = e.target.value}
                                id="DateFrom" type="date"
                                dateformat='DD/M/YYYY'
                                style={{
                                    "height": "35px",
                                    "background-color": "#e9ecef"
                                }} defaultValue={MemPrice.modDate3}
                            />

                            <Label style={{ "padding-top": "15px" }}>
                                <b>Supplier</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={this.handleFilter}
                                id = "modSupplier"
                                defaultValue={MemPrice.modSupplier}>
                                <option value={0}></option>
                                {MemPrice.suppliers.map((supplier) =>
                                    <option value={supplier.supId}
                                    >{supplier.supName}</option>)}
                            </select>











                            <Label for="modVessel" style={{ "padding-top": "15px" }}>
                                <b>Vessel</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={(e) => MemPrice.modVessel = e.target.value}
                                id="modVessel"
                                defaultValue={MemPrice.modVessel}>
                                <option value={0}></option>
                                {this.state.vessels.map((vessel) =>
                                    <option value={vessel.vesId}>{vessel.vesCurrentName}</option>)}
                            </select>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemPrice.modId}>
                            {(MemPrice.modId === 0) ? "Add New" : "Apply Changes"}
                            </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}
                            style={{ "width" : "100px"} }
                        >
                            Exit
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async dataGet() {
        console.log("getdata");
        const token = await authService.getAccessToken();

        const response1 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response1.json();

        const response2 = await fetch('mar1/ports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbports = await response2.json();

        const response3 = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response3.json();

        const response4 = await fetch('mar1/proListHeads', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
          });
        const dbheaders = await response4.json();

        // const response5 = await fetch('mar1/operators', {
        //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //});
        //const dboperators = await response5.json();

        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();

        const response7 = await fetch('mem1/memvessels', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvessels = await response7.json();

        const response8 = await fetch('mar1/periods', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbperiods = await response8.json();


        return {
            ports: dbports, suppliers: dbsuppliers, products: dbproducts,
            headers: dbheaders, dets: dbdets, vessels: dbvessels,
            periods: dbperiods
        };
    }

    async populateData() {
        const data = await MemPrice.dataGet();
        const dbsuppliers = data.suppliers;
        const dbports = data.ports;
        const dbproducts = data.products;
        const dbheaders = data.headers;
        const dbdets = data.dets;

        const dbvessels = data.vessels;
        const dbperiods = data.periods;
        var hpoint = 0;
        if (dbheaders.length > 0) hpoint = dbheaders[0].prohId;
        this.setState({
            ports: dbports, suppliers: dbsuppliers, products: dbproducts,
           loading: false, headers: dbheaders, dets: dbdets, vessels: dbvessels, curVes: 0, periods: dbperiods,
            cport: 0, hpoint:hpoint
        });
    }
}


