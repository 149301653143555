import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class MemArchive extends Component {
    static displayName = MemArchive.name;

    static modId = 0;
    static modOperator = 0;
    static modRef = 0;
    static modOperName = "";   

    static fltOperator = 0;
    static ftlRef = "";

    async handleFilter(event) {
        const data = await MemArchive.opRefGet();
        const dbopRefs = data.opRefs;
        const dbprodRefs = data.prodRefs;
        const dboperators = data.operators;
        const filtered = dbopRefs.filter(function (item) {
            return (item.oprsOperator === parseInt(MemArchive.fltOperator));
        });
        this.setState({
            oprefs: filtered, prodrefs: dbprodRefs, operators:dboperators,
                loading: false, modal: false
        });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                MemArchive.modId = this.state.opprodref[val].oprsId;
                MemArchive.modRef = this.state.opprodref[val].oprsRef;
                MemArchive.modOperName = this.state.opprodref[val].oprsOperName;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.opprodref.find(x => x.oprsId === parseInt(val));
                MemArchive.modId = myrec.oprsId;
                MemArchive.modRef = myrec.oprsRef;
                MemArchive.modOperName = myrec.oprsOperName;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await MemArchive.opRefSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("Product created successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await MemArchive.opRefGet();
                const dbopRefs = data.opRefs;
                const dbprodRefs = data.prodRefs;
                const dboperators = data.operators;
                const filtered = dbopRefs.filter(function (item) {
                    return (item.oprsOperator === MemArchive.fltOperator);
                });
                this.setState({
                    oprefs: filtered, prodrefs: dbprodRefs, operators: dboperators,
                    loading: false, modal: false
                });

            } else {
                const res = await MemArchive.opRefSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("product updated successfully");
                }
                await sleep(500);  // let the database catch up
                const data = await MemArchive.opRefGet();
                const dbopRefs = data.opRefs;
                const dbprodRefs = data.prodRefs;
                const dboperators = data.operators;
                const filtered = dbopRefs.filter(function (item) {
                    return (item.oprsOperator === MemArchive.fltOperator);
                });
                this.setState({
                    oprefs: filtered, prodrefs: dbprodRefs, operators: dboperators,
                    loading: false, modal: false
                });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async opRefSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/opprodrefs/' + MemArchive.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    oprsId: MemArchive.modId, oprsOperator: MemArchive.fltOperator,
                    oprsRef: MemArchive.modRef, oprsOperName: MemArchive.modOperName
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                   return  { result: "error", reason: response.status } ;
                }
            })
            .catch(error => {
                return { result: "error", reason: error};
            });
        return { result: "ok", reason: "" };
    }

    static async productAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/opprodrefs';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    oprsId: MemArchive.modId, oprsOperator: MemArchive.fltOperator,
                    oprsRef: MemArchive.modRef, oprsOperName: MemArchive.modOperName
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async productDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/opprodrefs/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await MemArchive.productDel(id);
            await sleep(1000);  // let the database catch up
            const data = await MemArchive.opRefGet();
            const dbopRefs = data.opRefs;
            const dbprodRefs = data.prodRefs;
            const dboperators = data.operators;
            const filtered = dbopRefs.filter(function (item) {
                return (item.oprsOperator === MemArchive.fltOperator);
            });
            this.setState({
                oprefs: filtered, prodrefs: dbprodRefs, operators: dboperators,
                    loading: false, modal: false
            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            oprefs: [], prodrefs: [], operators: [],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderopprodrefTable(oprefs, prodrefs, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>System Reference</th>
                            <th>Operator Reference</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oprefs.map((opref) =>
                            <tr key={opref.oprsId}>
                                <td><select defaultValue={opref.oprsRef} disabled
                                    style={{
                                        color: "black", "-webkit-appearance": "none", "border": "none",
                                        "-moz-appearance": "none", "background": "transparent"
                                    }}>
                                    {prodrefs.map((prodref) =>
                                        <option value={prodref.prrsId}>{prodref.prrsName}</option>)}
                                </select></td>
                                <td>{opref.oprsOperName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opref.oprsId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={opref.oprsId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oprefs = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.oprefs].sort(
                (a, b) => a.oprsOperName.localeCompare(b.oprsOperName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oprefs = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oprefs = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : MemArchive.renderopprodrefTable(oprefs, this.state.prodrefs,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <h1 id="tabelLabel" >Operator Reference &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-8 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <Label for="fltCat">
                                            Operator Name
                                        </Label><br />
                                        <select onChange={(e) => MemArchive.fltOperator = e.target.value}
                                            defaultValue={MemArchive.fltOperator}>
                                            <option value="0"></option>
                                            {this.state.operators.map((operator) =>
                                                <option value={operator.oprId}>{operator.oprName}</option>)}
                                        </select>
                                    </div>
                                    <div class="col-lg-4">
                                        <Label for="fltRef">
                                            Reference Code
                                        </Label>
                                        <Input onChange={(e) => MemArchive.fltRef = e.target.value}
                                            id="fltRef"
                                            type="text"
                                            defaultValue={MemArchive.fltRef}
                                        />
                                    </div>
                                    <div class="col-lg-2">
                                        <br />
                                        <Button color="secondary" style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={this.handleFilter}>
                                            Filter
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>product</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                product Name
                            </Label>
                            <Input onChange={(e) => MemArchive.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={MemArchive.modName}
                            />
                            <Label for="modCountry" style={{ "padding-top": "15px" }}>
                                product Country
                            </Label>
                            <Input onChange={(e) => MemArchive.modCountry = e.target.value}
                                id="modCountry"
                                type="text"
                                defaultValue={MemArchive.modCountry}
                            />
                            <Label for="modZone" style={{ "padding-top": "15px" }}>
                                Zone
                            </Label>
                            <Input onChange={(e) => MemArchive.modZone = e.target.value}
                                id="modZone"
                                type="text"
                                defaultValue={MemArchive.modZone}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemArchive.modId}>
                            { (MemArchive.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async opRefGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/opprodrefs', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopRefs = await response.json();
        const response2 = await fetch('mar1/prodrefsystems', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodRefs = await response2.json();
        const response3 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response3.json();
        return { opRefs: dbopRefs, prodRefs: dbprodRefs, operators: dboperators };
    }

    async populateData() {
        const data = await MemArchive.opRefGet();
        const dbopRefs = data.opRefs;
        const dbprodRefs = data.prodRefs;
        const dboperators = data.operators;
        this.setState({
            oprefs: dbopRefs, prodrefs: dbprodRefs,
            operators: dboperators, loading: false, modal: this.state.modal
        });
    }

}

