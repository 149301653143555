import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmPeriod extends Component {
    static displayName = AdmPeriod.name;

    static modId = 0;
    static modName = "";
    static modStart = "";
    static modEnd = "";
    static modSupplier = 0;
    static modBasedOn = 0;

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmPeriod.modId = 0;
                AdmPeriod.modName = "";
                AdmPeriod.modStart = "";
                AdmPeriod.modEnd = "";
                AdmPeriod.modSupplier = this.state.suppliers[0].supId;
                AdmPeriod.modBasedOn = 0;
                this.setState({ pointSup: this.state.suppliers[0].supId, modal: true });
            }
            else {
                let myrec = this.state.periods.find(x => x.perId === parseInt(val));
                AdmPeriod.modId = myrec.perId;
                AdmPeriod.modName = myrec.perName;
                AdmPeriod.modStart = myrec.perStart.substring(0, 10);
                AdmPeriod.modEnd = myrec.perEnd.substring(0, 10);
                AdmPeriod.modSupplier = myrec.perSupplier;
                AdmPeriod.modBasedOn = (myrec.perBasedOn == null) ? 0 :
                    (this.state.periods.find(x => x.perId == myrec.perBasedOn)).perName ;
                this.setState({ pointSup: myrec.perSupplier, modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmPeriod.periodSave();
                await sleep(500);  // let the database catch up
                const data = await AdmPeriod.periodGet();
                const dbperiods = data.periods;
                this.setState({ periods: dbperiods, loading: false, modal: false });
            } else {
                if (await AdmPeriod.periodAdd(this.state.periods, this.state.suppliers) !== "ok") return;  //stay on the popup
                const data = await AdmPeriod.periodGet();
                const dbperiods = data.periods;
                this.setState({ periods: dbperiods, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async periodSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/periods/' + AdmPeriod.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    perId: AdmPeriod.modId, perName: AdmPeriod.modName, perSupplier: AdmPeriod.modSupplier,
                    perStart: AdmPeriod.modStart, perEnd: AdmPeriod.modEnd
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async periodAdd(periods, suppliers) {
        // first lets calculate the perBasedOn value
        var newBasedOn;
        if (AdmPeriod.modBasedOn === 0) {
            newBasedOn = null;
        }
        else {
            // check if can find a value
            console.log(periods);
            console.log("modBasedOn: " + AdmPeriod.modBasedOn + " modSupplier: " + AdmPeriod.modSupplier);
            try {
                newBasedOn = periods.find(x => (
                    (x.perName === AdmPeriod.modBasedOn) && (x.perSupplier === parseInt(AdmPeriod.modSupplier))
                )).perId;
            } catch {
                alert("error: BasedOn period [" + AdmPeriod.modBasedOn + "] cannot be not found for " +
                    suppliers.find(x => x.supId == parseInt(AdmPeriod.modSupplier)).supName);
                return "error";
            }
        }
        // do not repeat period names for the same Supplier
        if (periods.find(x => (x.perName === AdmPeriod.modName) && (x.perSupplier == AdmPeriod.modSupplier))) {
            alert("error: period name [" + AdmPeriod.modName + "] already exists for " +
                suppliers.find(x => x.supId == parseInt(AdmPeriod.modSupplier)).supName);
            return "error";
        }

        // do not allow periods to overlap
        if (periods.find(x =>
            (
              (x.perSupplier == AdmPeriod.modSupplier) &&
              (AdmPeriod.modStart >= x.perStart) && (AdmPeriod.modStart <= x.perEnd)
            )
            ||
            (
              (x.perSupplier == AdmPeriod.modSupplier) &&
              (AdmPeriod.modEnd >= x.perStart) && (AdmPeriod.modEnd <= x.perEnd)
            ) ))
        {
            alert("error: period overlaps with existing period for " +
                suppliers.find(x => x.supId == parseInt(AdmPeriod.modSupplier)).supName);
            return "error";
        }



        const token = await authService.getAccessToken();
        var callString = 'mar1/periods';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    perName: AdmPeriod.modName,  
                    perSupplier: AdmPeriod.modSupplier,
                    perStart: AdmPeriod.modStart,
                    perEnd: AdmPeriod.modEnd,
                    perBasedOn : newBasedOn
                }       
            )
        };  
        var ret = await fetch(callString, requestOptions);
        if (ret.ok) return "ok";
        else {
            alert("error: please check your dates");
            return "error";
        }
    }

    static async periodDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/periods/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmPeriod.periodDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmPeriod.periodGet();
            const dbperiods = data.periods;
            this.setState({ periods: dbperiods, loading: false, modal: false });
        }
    }

    async setPointSup(event) {
       AdmPeriod.modSupplier = event.target.value;
        this.setState({ pointSup: event.target.value });
    }


    constructor(props) {
        super(props);
        this.state = { periods: [], suppliers: [], pointSup: 1, loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setPointSup = this.setPointSup.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderperiodsTable(pageperiods, suppliers, periods, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th data-sortable="true">Period Name</th>
                            <th>Supplier</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Based On</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageperiods.map((period) =>
                            <tr key={period.perId}>
                                <td>{period.perName}</td>
                                <td>{suppliers.find(x => x.supId === period.perSupplier).supName}</td>
                                <td>{period.perStart.substring(0,10)}</td>
                                <td>{period.perEnd.substring(0, 10)}</td>
                                <td>{(period.perBasedOn == null) ? "pricelist" : periods.find(x => x.perId == period.perBasedOn).perName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={period.perId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger" disabled
                                        onClick={hDelete} value={period.perId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = [...this.state.periods].sort((a, b) => a.perName.localeCompare(b.perName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmPeriod.renderperiodsTable(oper, this.state.suppliers, this.state.periods, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        return (
            <div>
                <h1 id="tabelLabel" >Periods &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>An period specifies the time period of a pricelist.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>period</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                period Name
                            </Label>
                            <Input onChange={(e) => AdmPeriod.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmPeriod.modName}
                            />
                            <Label for="modSupplier" style={{ "padding-top": "15px" }}>
                                Supplier
                            </Label>
                            <Input onChange={this.setPointSup}
                            id="modSupplier"
                                type="select"
                                defaultValue={AdmPeriod.modSupplier}>
                                {this.state.suppliers.map((supplier) =>
                                    <option key={supplier.supId} value={supplier.supId}>
                                        {supplier.supName}
                                    </option>
                                )}
                            </Input>
                            <Label for="modStart" style={{ "padding-top": "15px" }}>
                                Date Start
                            </Label>
                            <Input onChange={(e) => AdmPeriod.modStart = e.target.value}
                                id="modStart"
                                type="text"
                                defaultValue={AdmPeriod.modStart}
                            />
                            <Label for="modEnd" style={{ "padding-top": "15px" }}>
                                Date End
                            </Label>
                            <Input onChange={(e) => AdmPeriod.modEnd = e.target.value}
                                id="modEnd"
                                type="text"
                                defaultValue={AdmPeriod.modEnd}
                            />
                            <Label for="modBasedOn" style={{ "padding-top": "15px" }}>
                                Based On
                            </Label>
                            <Input onChange={(e) => AdmPeriod.modBasedOn = e.target.value}
                                id="modBasedOn"
                                type="select"
                                defaultValue={AdmPeriod.modBasedOn}>
                                <option key="0" value="0">
                                    pricelist
                                </option>
                                {this.state.periods.filter((period) => period.perSupplier == this.state.pointSup)
                                    .map((period) =>
                                        <option key={period.perId} value={period.perName}>
                                                {period.perName}
                                            </option>
                                )}
                            </Input>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmPeriod.modId}>
                            { (AdmPeriod.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async periodGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/periods', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbperiods = await response.json();
        const response2 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();

        return { periods: dbperiods, suppliers: dbsuppliers  };
    }

    async populateData() {
        const data = await AdmPeriod.periodGet();
        const dbperiods = data.periods;
        const dbsuppliers = data.suppliers;
        this.setState({
            periods: dbperiods, suppliers: dbsuppliers, pointSup: dbsuppliers[0].supId,
            loading: false, modal: this.state.modal
        });
    }

}

