import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes1 from './AppRoutes1';
import AppRoutes2 from './AppRoutes2';
import MemRoutes1 from './MemRoutes1';
import MemRoutes2 from './MemRoutes2';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { Layout1 } from './components/Layout1';
import { Layout2 } from './components/Layout2';
import { Layout3 } from './components/Layout3';
import { Layout4 } from './components/Layout4';

import './custom.css';
import authService from './components/api-authorization/AuthorizeService';

export default class App extends Component {
    static displayName = App.name;
    static layout;
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    async prepareData() {
        const members = ["efnav", "alassia", "nomikos", "blueseas", "safety", "equinox", "safebulkers", "franco", "papa",
            "goulandris", "efshipping", "diana", "alcyon", "mmaritime", "sealestial", "test", "dexter", "genoa"];
        App.layout = 1;
        var syspath = window.location.pathname;
        syspath = syspath.substring(1).toLowerCase();
        var role = "";
        var member = "";

        // 1) check if authenticated
        var auth = await authService.isAuthenticated();
 

        if ((!auth) & (syspath.indexOf("authentication") >= 0)) {
            role = sessionStorage.getItem("roleFront");
            member = sessionStorage.getItem("memberFront");
            // update back end
            if (role !== "") {
                const formData = new FormData();
                formData.append("roleFront", role);
                formData.append("memberFront", member);
                await fetch('mar1/datastore', { method: 'POST', body: formData });
            }
        } else
        {
            if (!auth) { // not authenticated, get them from syspath
                if (members.indexOf(syspath) >= 0) {
                    role = "member";
                    member = syspath;
                } else {
                    if (syspath.length >= 4) {
                        if (syspath.substring(0, 4) === "adm-") {
                            role = "admin";
                            member = "none";
                        }
                    } else if (syspath.length === 0) {
                        role = "admin";
                        member = "none";
                    }
                }
                // update back end
                if (role !== "") {
                    const formData = new FormData();
                    formData.append("roleFront", role);
                    formData.append("memberFront", member);
                    await fetch('mar1/datastore', { method: 'POST', body: formData });
                }
            } else {
                 
                // alert("system = " + sessionStorage.getItem("system"));
                // check if authenticated from back end
                
                const token = await authService.getAccessToken();
                const bearer = `Bearer ${token}`;
                const response = await fetch('mar1/dataAuth', { headers: { "Authorization": bearer } });
                // console.log(response);
                if (!response.redirected) {
                    // ok we are authenticated
                    const data = await response.json();
                    // console.log(data);
                    role = data.role.toLowerCase();
                    member = data.member;
                }
                else {
                    auth = false;
                    if (members.indexOf(syspath) >= 0) {
                        role = "member";
                        member = syspath;
                    } else {
                        if (syspath.length >= 4) {
                            if (syspath.substring(0, 4) === "adm-") {
                                role = "admin";
                                member = "none";
                            }
                        } else if (syspath.length === 0) {
                            role = "admin";
                            member = "none";
                        }
                    }
                }
                
            }
        }
        // allow admins to see member pages
        /* do not allow this
        if (auth) {
            if ((role !== "member") && (members.indexOf(syspath) >= 0)) {
                role = "member";
                member = syspath;
            }
        }
        */

        // update session storage
        sessionStorage.setItem("system", "normal");
        sessionStorage.setItem("roleFront", role);
        sessionStorage.setItem("memberFront", member);
        sessionStorage.setItem("authFront", auth);

        // select layout
        if ((role !== "member") && !auth) App.layout = 1; else
            if ((role !== "member") && auth) App.layout = 2; else
                if ((role === "member") && !auth) App.layout = 3; else
                    if ((role === "member") && auth) App.layout = 4

        // debug log
        console.log("role = " + role);
        console.log("member = " + member);
        console.log("auth = " + auth);
        console.log("layout = " + App.layout);
        sessionStorage.setItem("system", "loaded");

        // done
        this.setState({ loading: false });
    }


    componentDidMount() {
        this.prepareData();
    }


    render() {
        if (this.state.loading) {
            return (<div>Loading...</div>);
        }

        if (App.layout === 1) {
            return (
                <Layout1>
                    <Routes>
                        {AppRoutes1.map((route, index) => {
                            const { element, requireAuth, ...rest } = route;
                            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                        })}
                    </Routes>
                </Layout1>
            )
        } else if (App.layout === 2) {
            return (
                <Layout2>
                    <Routes>
                        {AppRoutes2.map((route, index) => {
                            const { element, requireAuth, ...rest } = route;
                            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                        })}
                    </Routes>
                </Layout2>
            )
        } else if (App.layout === 3) {
            return (
                <Layout3>
                    <Routes>
                        {MemRoutes1.map((route, index) => {
                            const { element, requireAuth, ...rest } = route;
                            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                        })}
                    </Routes>
                </Layout3>
            )
        } else if (App.layout === 4) {
            return (
                <Layout4>
                    <Routes>
                        {MemRoutes2.map((route, index) => {
                            const { element, requireAuth, ...rest } = route;
                            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                        })}
                    </Routes>
                </Layout4>
            )
        }
    }
}
