import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmAssociation extends Component {
    static displayName = AdmAssociation.name;

    static modId = 0;
    static modName = "";
    static mod2products = [];

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmAssociation.modId = 0;
                AdmAssociation.modName = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.associations.find(x => x.pasId === parseInt(val));
                AdmAssociation.modId = myrec.pasId;
                AdmAssociation.modName = myrec.pasName;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmAssociation.associationSave();
                await sleep(500);  // let the database catch up
                const data = await AdmAssociation.associationGet();
                const dbassociations = data.associations;
                this.setState({ associations: dbassociations, loading: false, modal: false });
            } else {
                await AdmAssociation.associationAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmAssociation.associationGet();
                const dbassociations = data.associations;
                this.setState({ associations: dbassociations, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    async handleModal2(event) {
        let val = event.target.value;
        let mod = this.state.modal2;
        if (mod === false) {
            const dbproducts = await AdmAssociation.prodCategoryGet(val);
            AdmAssociation.mod2products = dbproducts.sort((a, b) => a.supplier.localeCompare(b.Supplier));
            // alert(JSON.stringify(AdmAssociation.mod2products));
            this.setState({ modal2: true });
        }
        else if (val !== "-1") {
            // cancel
            this.setState({ modal2: false });
        }
        else {
            // delete
            this.setState({ modal2: false });
        }
    }


    static async associationSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/associations/' + AdmAssociation.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    pasId: AdmAssociation.modId, pasName: AdmAssociation.modName
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async associationAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/associations';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    pasName: AdmAssociation.modName,  
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async associationDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/associations/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmAssociation.associationDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmAssociation.associationGet();
            const dbassociations = data.associations;
            this.setState({ associations: dbassociations, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { associations: [], loading: true, modal: false, modal2: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleModal2 = this.handleModal2.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderassociationsTable(associations, hModal, hModal2, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {associations.map((association) =>
                            <tr key={association.pasId}>
                                <td>{association.pasName}</td>
                                <td >
                                    <Button color="primary" onClick={hModal} value={association.pasId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button class="btn btn-danger"
                                        onClick={hDelete} value={association.pasId}>
                                        Delete
                                    </button>   &nbsp;&nbsp;&nbsp;
                                    <button class="btn btn-secondary" onClick={hModal2}
                                        value={association.pasId}>
                                        Products
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    static async prodCategoryGet(val) {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/prodasgroup/' + val, {
            method: "GET", headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodCategories = await response.json();
        return  dbprodCategories;
    }

    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = [...this.state.associations].sort((a, b) => a.pasName.localeCompare(b.pasName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmAssociation.renderassociationsTable(oper, this.handleModal, this.handleModal2, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }



        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Associations &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>An associated product can replace the other one.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>association</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                association Name
                            </Label>
                            <Input onChange={(e) => AdmAssociation.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmAssociation.modName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmAssociation.modId}>
                            { (AdmAssociation.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal2}>
                    <ModalHeader>Associated Group</ModalHeader>
                    <ModalBody>
                        <table id="prodTable" className='table table-striped' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th>Supplier</th>
                                    <th>Code</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AdmAssociation.mod2products.map((product) =>
                                    <tr key={product.assocId}>
                                        <td> {product.supplier} </td>
                                        <td> {product.refCode} </td>
                                        <td>
                                            <Button color="danger" onClick={this.handleModal2} value={AdmAssociation.modId}>
                                                Delete
                                            </Button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.handleModal2} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async associationGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/associations', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbassociations = await response.json();
        return { associations: dbassociations };
    }

    async populateData() {
        const data = await AdmAssociation.associationGet();
        const dbassociations = data.associations;
        this.setState({
            associations: dbassociations,
            loading: false, modal: this.state.modal
        });
    }

}

