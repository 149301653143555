import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu1 } from './NavMenu1';

export class Layout1 extends Component {
  static displayName = Layout1.name;

  render() {
    return (
        <div>
        <meta httpEquiv="cache-control" content="no-cache" />
        <NavMenu1 />
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
