import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const pageSize = 10;

export class AdmOpSupplier extends Component {
    static displayName = AdmOpSupplier.name;

    static modId = 0;
    static modOperator = 0;
    static modSupplier = 0;
    static modOperName = "";   

    static fltOperator = 0;

    async handleFilter(event) {
        AdmOpSupplier.fltOperator = event.target.value;

        const filtered = this.state.opSuppliers.filter(function (item) {
            return (item.osuOperator === parseInt(AdmOpSupplier.fltOperator));
        });
        this.setState({ vwSuppliers: filtered });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmOpSupplier.modId = 0;
                AdmOpSupplier.modOperator = (parseInt(AdmOpSupplier.fltOperator) === 0)
                ? this.state.operators[0].oprId : parseInt(AdmOpSupplier.fltOperator);
                AdmOpSupplier.modSupplier = this.state.suppliers[0].supId;
                AdmOpSupplier.modOperName = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.vwSuppliers.find(x => x.osuId === parseInt(val));
                AdmOpSupplier.modId = myrec.osuId;
                AdmOpSupplier.modOperator = myrec.osuOperator;
                AdmOpSupplier.modSupplier = myrec.osuSupplier;
                AdmOpSupplier.modOperName = myrec.osuOperName;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val == "0") {
                const res = await AdmOpSupplier.supAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                   alert("Product created successfully");
                }
                const data = await AdmOpSupplier.opSupplierGet();
                const filtered = data.opSuppliers.filter(function (item) {
                    return (item.osuOperator === parseInt(AdmOpSupplier.fltOperator));
                });
                this.setState({
                    vwSuppliers: filtered, opSuppliers: data.opSuppliers, modal: false
                });

            } else {
                const res = await AdmOpSupplier.opSuppliersave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("product updated successfully");
                }
                const data = await AdmOpSupplier.opSupplierGet();
                const filtered = data.opSuppliers.filter(function (item) {
                    return (item.osuOperator === parseInt(AdmOpSupplier.fltOperator));
                });
                this.setState({
                    vwSuppliers: filtered, opSuppliers: data.opSuppliers, modal: false
                });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async opSuppliersave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/opSuppliers/' + AdmOpSupplier.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    osuId: AdmOpSupplier.modId, osuOperName: AdmOpSupplier.modOperName,
                    osuOperator: parseInt(AdmOpSupplier.modOperator), osuSupplier: parseInt(AdmOpSupplier.modSupplier)
                }
            )
        };
        const res = await fetch(callString, requestOptions);
        if (!res.ok) {
            return { result: "error", reason: res.status };
        }
        return { result: "ok", reason: "" };
    }

    static async supAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/opSuppliers';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    osuOperator: parseInt(AdmOpSupplier.modOperator),
                    osuSupplier: parseInt(AdmOpSupplier.modSupplier),
                    osuOperName: AdmOpSupplier.modOperName
                }       
            )
        };  
        const res = await fetch(callString, requestOptions);
        if (!res.ok) {
            return { result: "error", reason: res.status };
        }
        return { result: "ok", reason: "" };
    }

    static async supDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/opSuppliers/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmOpSupplier.supDel(id);
            const data = await AdmOpSupplier.opSupplierGet();
            const dbopSuppliers = data.opSuppliers;
            const filtered = dbopSuppliers.filter(function (item) {
                return (item.osuOperator === parseInt(AdmOpSupplier.fltOperator));
            });
            this.setState({
                vwSuppliers: filtered, opSuppliers: dbopSuppliers, modal: false
            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            vwSuppliers: [], suppliers: [], operators: [], opSuppliers:[],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderopprodrefTable(opSuppliers, suppliers, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>System Supplier</th>
                            <th>Operator Supplier</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opSuppliers.map((opSupplier) =>
                            <tr key={opSupplier.osuId}>
                                <td><select defaultValue={opSupplier.osuSupplier} disabled
                                    style={{
                                        color: "black", "-webkit-appearance": "none", "border": "none",
                                        "-moz-appearance": "none", "background": "transparent"
                                    }}>
                                    {suppliers.map((supplier) =>
                                        <option value={supplier.supId}>{supplier.supName}</option>)}
                                </select></td>
                                <td>{opSupplier.osuOperName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opSupplier.osuId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={opSupplier.osuId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var vwSuppliers = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.vwSuppliers].sort(
                (a, b) => a.osuOperName.localeCompare(b.osuOperName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                vwSuppliers = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                vwSuppliers = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmOpSupplier.renderopprodrefTable(vwSuppliers, this.state.suppliers,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 id="tabelLabel" >Supplier (Member) &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-6 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <Label for="fltSup">
                                            Member Name
                                        </Label><br />
                                        <select onChange={this.handleFilter}
                                            id="fltSup"
                                            defaultValue={AdmOpSupplier.fltOperator}>
                                            <option value="0">Select one</option>
                                            {this.state.operators.map((operator) =>
                                                <option value={operator.oprId}>{operator.oprName}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Member Supplier Name</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modOperator">
                                Member
                            </Label>
                            <Input type="select" name="modOperator" id="modOperator"
                                defaultValue={AdmOpSupplier.modOperator}
                                onChange={(e) => { AdmOpSupplier.modOperator = e.target.value; }}>
                                {this.state.operators.map((operator) =>
                                    <option value={operator.oprId}>{operator.oprName}</option>)}
                            </Input>
                            <Label for="modSupplier" style={{"paddingTop":"15px"} }>
                                Supplier
                            </Label>
                            <Input type="select" name="modSupplier" id="modSupplier"
                            defaultValue={AdmOpSupplier.modSupplier}
                                onChange={(e) => { AdmOpSupplier.modSupplier = e.target.value; }}>
                                {this.state.suppliers.map((supplier) =>
                                    <option value={supplier.supId}>{supplier.supName}</option>)}
                            </Input>
                            <Label for="modOperName" style={{ "paddingTop": "15px" }}>
                                Member Name
                            </Label>
                            <Input type="text" name="modOperName" id="modOperName"
                                defaultValue={AdmOpSupplier.modOperName}
                                onChange={(e) => { AdmOpSupplier.modOperName = e.target.value; }}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmOpSupplier.modId}>
                            { (AdmOpSupplier.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }


    static async opSupplierGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/opsuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopSuppliers = await response.json();
        return { opSuppliers: dbopSuppliers };

    }

    static async dataGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/opsuppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopSuppliers = await response.json();
        const response2 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();
        const response3 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response3.json();
        return { opSuppliers: dbopSuppliers, suppliers: dbsuppliers, operators: dboperators };
    }

    async populateData() {
        const data = await AdmOpSupplier.dataGet();
        const dbopSuppliers = data.opSuppliers;
        const dbsuppliers = data.suppliers;
        const dboperators = data.operators;
        this.setState({
            vwSuppliers: [], opSuppliers: dbopSuppliers, suppliers:dbsuppliers, 
            operators: dboperators, loading: false, modal: this.state.modal
        });
    }

}

