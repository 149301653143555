import React, { Component } from 'react';
export class Home extends Component {
    static displayName = Home.name;
    static lastauth = "";

    render() {
        return (
            <div className="container px-4 py-5" id="hanging-icons">
                <h2 className="pb-2 border-bottom">John Kalafatides Marine Reports</h2>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div className="col d-flex align-items-start">
                        <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3" style={{ width: '48px', color: '#000', backgroundColor: '#e2e3e5', borderRadius: '.75rem', height: '48px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-check" viewBox="0 0 16 16">
                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                            </svg>
                        </div>
                        <div>
                            <h2>Your data are safe</h2>
                            <p>We put safety first and we have the technology to do it. State-of-the-art identification servers, secure mobile application (using JSON Web Tokens and SMS), push notifications, managed private servers and frequent backups. </p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                        <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3" style={{ width: '48px', color: '#000', backgroundColor: '#e2e3e5', borderRadius: '.75rem', height: '48px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-graph-down" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 11.887a.5.5 0 0 0 .07-.704l-4.5-5.5a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61 4.15 5.073a.5.5 0 0 0 .704.07Z" />
                            </svg>
                        </div>
                        <div>
                            <h2>Graphics</h2>
                            <p>We have the experience to convert archived data into meaningful graphical representations.</p>
                            <p>Let us give you the whole picture. It is worth a thousand words. </p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                        <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3" style={{ width: '48px', color: '#000', backgroundColor: '#e2e3e5', borderRadius: '.75rem', height: '48px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>                       </div>
                        <div>
                            <h2>Immediate Assistance</h2>
                            <p>We are always here to help. You do not need to worry whether your data are incomplete or contain new classNameifications. If an error appears, ask for help and your personal assistant will take care of it.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
