import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function

export class AdmProdSysCategories extends Component {
    static displayName = AdmProdSysCategories.name;

    static modId = 0;
    static modName = "";
    static modSubname = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmProdSysCategories.modId = 0;
                AdmProdSysCategories.modName = "";
                AdmProdSysCategories.modSubname = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.ProdSysCategories.find(x => x.pscId === parseInt(val));
                AdmProdSysCategories.modId = myrec.pscId;
                AdmProdSysCategories.modName = myrec.pcsCategory;
                AdmProdSysCategories.modSubname = myrec.pcsSubCat;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmProdSysCategories.ProdSysCategoriesave();
                const data = await AdmProdSysCategories.prodsyscatGet();
                const dbProdSysCategories = data.ProdSysCategories;
                this.setState({ ProdSysCategories: dbProdSysCategories, loading: false, modal: false });
            } else {
                await AdmProdSysCategories.prodsyscatAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmProdSysCategories.prodsyscatGet();
                const dbProdSysCategories = data.ProdSysCategories;
                this.setState({ ProdSysCategories: dbProdSysCategories, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async ProdSysCategoriesave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdSysCategories/' + AdmProdSysCategories.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    pscId: AdmProdSysCategories.modId, pcsCategory: AdmProdSysCategories.modName,
                    pcsSubCat: AdmProdSysCategories.modSubName                }
            )
        };
        var response = await fetch(callString, requestOptions).catch(error => {
            console.error('There was an error!', error);
            return "error";
        });
        if (!response.ok) {
            console.log(response.status);
            return "error";
        }
        return "ok";
    }

    static async prodsyscatAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdSysCategories';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    pcsCategory: AdmProdSysCategories.modName,
                    pcsSubCat: AdmProdSysCategories.modSubName
                }       
            )
        };  
        await fetch(callString, requestOptions);
    }

    static async prodsyscatDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdSysCategories/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        var ret = await fetch(callString, requestOptions);
        return ret.ok;
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            var ret = await AdmProdSysCategories.prodsyscatDel(id);
            if (!ret) {
                    alert("Delete failed. The prodsyscat is in use.");
            }
            const data = await AdmProdSysCategories.prodsyscatGet();
            const dbProdSysCategories = data.ProdSysCategories;
            this.setState({ ProdSysCategories: dbProdSysCategories, loading: false, modal: false });
        }
    }


    constructor(props) {
        super(props);
        this.state = { ProdSysCategories: [], loading: true, modal: false };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderProdSysCategoriesTable(ProdSysCategories, hModal, hDelete) {
        const sorted = [...ProdSysCategories];
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>SubCategory</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((prodsyscat) =>
                            <tr key={prodsyscat.pscId}>
                                <td>{prodsyscat.pcsCategory}</td>
                                <td>{prodsyscat.pcsSubCat}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={prodsyscat.pscId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={prodsyscat.pscId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : AdmProdSysCategories.renderProdSysCategoriesTable( this.state.ProdSysCategories, this.handleModal, this.handleDelete);
        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Product System Categories &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>A product grouping by the Manager.</p>
                {contents1}
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Prod Sys Category</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                Category
                            </Label>
                            <Input onChange={(e) => AdmProdSysCategories.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmProdSysCategories.modName}
                            />
                            <Label for="modSubName">
                                SubCategory
                            </Label>
                            <Input onChange={(e) => AdmProdSysCategories.modSubName = e.target.value}
                                id="modSubName"
                                type="text"
                                defaultValue={AdmProdSysCategories.modSubName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmProdSysCategories.modId}>
                            { (AdmProdSysCategories.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async prodsyscatGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/ProdSysCategories', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbProdSysCategories = await response.json();
        return { ProdSysCategories: dbProdSysCategories };
    }

    async populateData() {
        const data = await AdmProdSysCategories.prodsyscatGet();
        const dbProdSysCategories = data.ProdSysCategories;
        this.setState({
            ProdSysCategories: dbProdSysCategories,
            loading: false, modal: this.state.modal
        });
    }

}

