import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
        Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmProdRefSys extends Component {
    static displayName = AdmProdRefSys.name;

    static modId = 0;
    static modName = "";

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmProdRefSys.modId = 0;
                AdmProdRefSys.modName = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.prodReferences.find(x => x.prrsId === parseInt(val));
                AdmProdRefSys.modId = myrec.prrsId;
                AdmProdRefSys.modName = myrec.prrsName;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmProdRefSys.prodReferenceSave();
                await sleep(500);  // let the database catch up
                const data = await AdmProdRefSys.prodReferenceGet();
                const dbprodReferences = data.prodReferences;
                this.setState({ prodReferences: dbprodReferences, loading: false, modal: false });
            } else {
                await AdmProdRefSys.prodReferenceAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmProdRefSys.prodReferenceGet();
                const dbprodReferences = data.prodReferences;
                this.setState({ prodReferences: dbprodReferences, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async prodReferenceSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/prodrefsystems/' + AdmProdRefSys.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    prrsId: AdmProdRefSys.modId, prrsName: AdmProdRefSys.modName,
                    prrsContact: AdmProdRefSys.modContact, prrsTelephone: AdmProdRefSys.modTelephone
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        return "ok";
    }

    static async prodReferenceAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/prodrefsystems';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    prrsName: AdmProdRefSys.modName,  
                }       
            )
        };  
        fetch(callString, requestOptions);
    }

    static async prodReferenceDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/prodrefsystems/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmProdRefSys.prodReferenceDel(id);
            await sleep(1000);  // let the database catch up
            const data = await AdmProdRefSys.prodReferenceGet();
            const dbprodReferences = data.prodReferences;
            this.setState({ prodReferences: dbprodReferences, loading: false, modal: false });
        }
    }

    constructor(props) {
        super(props);
        this.state = { prodReferences: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderprodReferencesTable(prodReferences, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Reference System</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prodReferences.map((prodReference) =>
                            <tr key={prodReference.prrsId}>
                                <td>{prodReference.prrsName}</td>
                                <td><Input type="switch" checked={prodReference.prrsItem ? true : false } disabled /></td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={prodReference.prrsId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={prodReference.prrsId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var oper = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = [...this.state.prodReferences].sort((a, b) => a.prrsName.localeCompare(b.prrsName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                oper = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                oper = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmProdRefSys.renderprodReferencesTable(oper, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Invoice Item Application &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "font-size": "20px", "font-weight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>A Product Application Reference provides some indication of the product use.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>prodReference</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                prodReference Name
                            </Label>
                            <Input onChange={(e) => AdmProdRefSys.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmProdRefSys.modName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmProdRefSys.modId}>
                            { (AdmProdRefSys.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async prodReferenceGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/prodrefsystems', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbprodReferences = await response.json();
        return { prodReferences: dbprodReferences };
    }

    async populateData() {
        const data = await AdmProdRefSys.prodReferenceGet();
        const dbprodReferences = data.prodReferences;
        this.setState({
            prodReferences: dbprodReferences,
            loading: false, modal: this.state.modal
        });
    }

}

