import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function

export class AdmProdTypes extends Component {
    static displayName = AdmProdTypes.name;

    static modId = 0;
    static modName = "";


    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmProdTypes.modId = 0;
                AdmProdTypes.modName = "";
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.ProdTypes.find(x => x.ptyId === parseInt(val));
                AdmProdTypes.modId = myrec.ptyId;
                AdmProdTypes.modName = myrec.ptyName;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                await AdmProdTypes.ProdTypesave();
                const data = await AdmProdTypes.ptypeGet();
                const dbProdTypes = data.ProdTypes;
                this.setState({ ProdTypes: dbProdTypes, loading: false, modal: false });
            } else {
                await AdmProdTypes.ptypeAdd();
                await sleep(500);  // let the database catch up
                const data = await AdmProdTypes.ptypeGet();
                const dbProdTypes = data.ProdTypes;
                this.setState({ ProdTypes: dbProdTypes, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async ProdTypesave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdTypes/' + AdmProdTypes.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {  ptyId: AdmProdTypes.modId, ptyName: AdmProdTypes.modName   }
            )
        };
        var response = await fetch(callString, requestOptions).catch(error => {
            console.error('There was an error!', error);
            return "error";
        });
        if (!response.ok) {
            console.log(response.status);
            return "error";
        }
        return "ok";
    }

    static async ptypeAdd() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdTypes';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    ptyName: AdmProdTypes.modName
                }       
            )
        };  
        await fetch(callString, requestOptions);
    }

    static async ptypeDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/ProdTypes/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        var ret = await fetch(callString, requestOptions);
        return ret.ok;
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            var ret = await AdmProdTypes.ptypeDel(id);
            if (!ret) {
                    alert("Delete failed.");
            }
            const data = await AdmProdTypes.ptypeGet();
            const dbProdTypes = data.ProdTypes;
            this.setState({ ProdTypes: dbProdTypes, loading: false, modal: false });
        }
    }


    constructor(props) {
        super(props);
        this.state = { ProdTypes: [], loading: true, modal: false };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderProdTypesTable(ProdTypes, hModal, hDelete) {
        const sorted = [...ProdTypes].sort((a, b) => a.ptyName.localeCompare(b.ptyName));
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Product Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((ptype) =>
                            <tr key={ptype.ptyId}>
                                <td>{ptype.ptyName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={ptype.ptyId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-danger"
                                        onClick={hDelete} value={ptype.ptyId}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : AdmProdTypes.renderProdTypesTable( this.state.ProdTypes, this.handleModal, this.handleDelete);
        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Product Types &nbsp;
                    <Button color="success" onClick={this.handleModal} value={-1}
                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}
                    >+</Button></h1>
                <p>Any product may be of one of the types below.</p>
                {contents1}
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Product Type</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName">
                                Name
                            </Label>
                            <Input onChange={(e) => AdmProdTypes.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmProdTypes.modName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmProdTypes.modId}>
                            { (AdmProdTypes.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async ptypeGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/ProdTypes', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbProdTypes = await response.json();
        return { ProdTypes: dbProdTypes };
    }

    async populateData() {
        const data = await AdmProdTypes.ptypeGet();
        const dbProdTypes = data.ProdTypes;
        this.setState({
            ProdTypes: dbProdTypes,
            loading: false, modal: this.state.modal
        });
    }

}

