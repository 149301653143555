import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';


export class EfnavHome extends Component {
    static displayName = EfnavHome.name;

    constructor(props) {
        super(props);
        this.state = { user: "", isAuth: false, loading: true };
        this.hLogin = this.hLogin.bind(this);
    }

    async populateData() {
        const [_isAuthenticated, _user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        sessionStorage.setItem("authFront", _isAuthenticated);
        console.log("EfnavHome:populateUser:isAuth:" + _isAuthenticated);
        this.setState({ user: (_user && _user.name), isAuth: _isAuthenticated, loading: false });
    }

    componentDidMount() {
        var auth = sessionStorage.getItem("authFront") ?? "false";
        this.populateData();
        if (auth !== sessionStorage.getItem("authFront") ?? "false")
            window.location.reload();
    }


    async hLogin() {
        window.location = "authentication/login?returnUrl=" + encodeURIComponent(window.location.href);
    }


    render() {
        if (this.state.loading) {
            return (<div>Loading...</div>);
        }
        console.log("EfnavHome");
        var loginButton = "";
        if (!this.state.isAuth) loginButton = 
            <div class="container">
               <div class="row">
                   <div class="col-lg-4">
                           Please log in to see Prices & Reports. 
                   </div>
                   <div class="col-lg-2">
                        <button className="btn btn-primary" onClick={this.hLogin}> Click Here to Login </button>
                   </div>
               </div>
            </div>
        else loginButton =
            <div>
            <h2> Welcome Back. Please select a page from the menu</h2>
            </div>;
        return (
        <div>

        <section id="about" class="about">

            <div class="container aos-init aos-animate" data-aos="fade-up">

                <div>
                            <center><h2>About EFNAV</h2></center>
                            <p>Quisque fringilla dolor id mi tempor vehicula. Vestibulum pharetra est sed lectus finibus consectetur. Nulla quis consequat justo, eget volutpat enim. Mauris fringilla vestibulum lacus, vel eleifend nibh. Aliquam ex mi, posuere non nulla ut, laoreet semper dolor. Suspendisse in metus sed diam congue ultricies eu a elit. Donec congue risus a leo sagittis, eget tincidunt arcu elementum. it suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.
                            </p>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <br />
                        <br />
                        <br />
                        <br />
                                <img src="/mempics/efnav.png" style={{ "width": "200px" }} alt=""/>
                    </div>
                    <div class="col-lg-8 pt-4 pt-lg-0 content">
                                <h3>Company information</h3>
                        <p class="fst-italic">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua.
                        </p>
                        <div class="row">
                            <div class="col-lg-6">
                                <ul>
                                    <li><i class="bi bi-rounded-right"></i> <strong>IMO number:</strong> 1623039 </li>
                                            <li><i class="bi bi-rounded-right"></i> <strong>Name:</strong> EFNAV CO LTD</li>
                                            <li><i class="bi bi-rounded-right"></i> <strong>Road:</strong> 2-4, Vasileos Pavlou Avenue</li>
                                            <li><i class="bi bi-rounded-right"></i> <strong>City:</strong> 185 34 Piraeus. Greece</li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <ul>
                                            <li><i class="bi bi-rounded-right"></i> <strong>Number of ships:</strong> 15</li>
                                    <li><i class="bi bi-rounded-right"></i> <strong>Contact:</strong> Someone Special</li>
                                    <li><i class="bi bi-rounded-right"></i> <strong>Emai:</strong> email@example.com</li>
                                    <li><i class="bi bi-rounded-right"></i> <strong>Phone:</strong> Not Available</li>
                                </ul>
                            </div>
                        </div>
                        <p>
                            Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
                            Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque. Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium dolores.
                        </p>
                    </div>
                </div>
            </div>
        </section>
                <br />
                <br />
            {loginButton}
                 
        </div>
        );
    }
}
