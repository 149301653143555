import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AlassiaHome } from "./components/mem-home/Alassia";
import { EfnavHome } from "./components/mem-home/Efnav";
import { NomikosHome } from "./components/mem-home/Nomikos";
import { BlueseasHome } from "./components/mem-home/Blueseas";
import { AlcyonHome } from "./components/mem-home/Alcyon";
import { MmaritimeHome } from "./components/mem-home/Mmaritime";
import { SealestialHome } from "./components/mem-home/Sealestial";
import { SafetyHome } from "./components/mem-home/Safety";
import { SafebulkersHome } from "./components/mem-home/Safebulkers";
import { EquinoxHome } from "./components/mem-home/Equinox";
import { GoulandrisHome } from "./components/mem-home/Goulandris";
import { EfshippingHome } from "./components/mem-home/Efshipping";
import { DianaHome } from "./components/mem-home/Diana";
import { FrancoHome } from "./components/mem-home/Franco";
import { DexterHome } from "./components/mem-home/Dexter";
import { GenoaHome } from "./components/mem-home/Genoa";
import { PapaHome } from "./components/mem-home/Papa";
import { TestHome } from "./components/mem-home/Test";
import { Navigate } from 'react-router-dom';

const path = sessionStorage.getItem("memberFront") ?? "/";

const MemRoutes = [
    {
        path: '/alassia',
        element: <AlassiaHome />
    },
    {
        path: '/efnav',
        element: <EfnavHome />
    },
    {
        path: '/nomikos',
        element: <NomikosHome />
    },
    {
        path: '/blueseas',
        element: <BlueseasHome />
    },
{
        path: '/alcyon',
        element: <AlcyonHome />
    },
    {
        path: '/mmaritime',
        element: <MmaritimeHome />
    },
{
        path: '/sealestial',
        element: <SealestialHome />
    },
    {
        path: '/safety',
        element: <SafetyHome />
    },
    {
        path: '/safebulkers',
        element: <SafebulkersHome />
    },
    {
        path: '/equinox',
        element: <EquinoxHome />
    },
    {
        path: '/goulandris',
        element: <GoulandrisHome />
    },
    {
        path: '/efshipping',
        element: <EfshippingHome />
    },
    {
        path: '/diana',
        element: <DianaHome />
    },
    {
        path: '/franco',
        element: <FrancoHome />
    },
    {
        path: '/dexter',
        element: <DexterHome />
    },
    {
        path: '/genoa',
        element: <GenoaHome />
    },
    {
        path: '/papa',
        element: <PapaHome />
    },
    {
        path: '/test',
        element: <TestHome />
    },
    {
        path: '/',
        element: <Navigate to={path} />
    },
  ...ApiAuthorzationRoutes
];

export default MemRoutes;
