import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;

export class AdmOpPackaging extends Component {
    static displayName = AdmOpPackaging.name;

    static modId = 0;
    static modUnitName = "";
    static modSystem = "";
    static modQua = 1;
    static modIsItem = false;

    static fltOperator = 0;

    static async refreshData(modal) {
        const data = await AdmOpPackaging.opPackagingGet();
        const dbopPackaging = data.opPackaging;
        return {
            opPackaging: dbopPackaging
        };
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        console.log("modal: " + mod + " val: " + val);
        if (mod === false) {
            if (val === "-1") {
                AdmOpPackaging.modId = 0;
                AdmOpPackaging.modUnitName = "";
                AdmOpPackaging.modSystem = "KGR";
                AdmOpPackaging.modQua = 1;
                AdmOpPackaging.modIsItem = false;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.opPackaging.find(x => x.unbId === parseInt(val));
                AdmOpPackaging.modId = myrec.unbId;
                AdmOpPackaging.modUnitName = myrec.unbBaseUnit;
                AdmOpPackaging.modQua = myrec.unbQuantity;
                AdmOpPackaging.modSystem = myrec.unbSystem;
                AdmOpPackaging.modIsItem = myrec.unbItem;
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await AdmOpPackaging.opPackagingave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Unit Conversion updated successfully");
                }
                const ret = await AdmOpPackaging.refreshData();
                this.setState({ opPackaging: ret.opPackaging, loading: false, modal: false });
            } else {
                const res = await AdmOpPackaging.opUnitBaseAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Unit Conversion created successfully");
                }
                await sleep(50);  // let the database catch up
                const ret = await AdmOpPackaging.refreshData();
                this.setState({ opPackaging: ret.opPackaging, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async opPackagingave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/UnitBases/' + AdmOpPackaging.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UnbID: AdmOpPackaging.modId,
                    UnbOperator: AdmOpPackaging.fltOperator,
                    UnbBaseUnit: AdmOpPackaging.modUnitName,
                    UnbSystem: AdmOpPackaging.modSystem,
                    UnbQuantity: AdmOpPackaging.modQua,
                    UnbItem: AdmOpPackaging.modIsItem
                }
            )
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok) {
            return { result: "error", reason: res.status };
        }
        return { result: "ok", reason: "" };
    }

    static async opUnitBaseAdd() {

        if (AdmOpPackaging.modUnitName === "") {
            return { result: "error", reason: "Unit Name cannot be blank" };
        }
        const token = await authService.getAccessToken();
        var callString = 'mar1/UnitBases';
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UnbBaseUnit: AdmOpPackaging.modUnitName,
                    UnbOperator: AdmOpPackaging.fltOperator,
                    UnbSystem: AdmOpPackaging.modSystem,
                    UnbQuantity: AdmOpPackaging.modQua,
                    UnbItem: AdmOpPackaging.modIsItem
                }
            )
        };
        await fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    return { result: "error", reason: response.status };
                }
            })
            .catch(error => {
                return { result: "error", reason: error };
            });
        return { result: "ok", reason: "" };
    }

    static async unitBaseDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mar1/UnitBases/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await AdmOpPackaging.unitBaseDel(id);
            const ret = await AdmOpPackaging.refreshData();
            this.setState({ opPackaging: ret.opPackaging, loading: false, modal: false });
        }
    }

    async handleOper(event) {   
        AdmOpPackaging.fltOperator = event;
        const data = await AdmOpPackaging.opPackagingGet();
        const dbopPackaging = data.opPackaging;
        this.setState({
            opPackaging: dbopPackaging, operators: this.state.operators, loading: false, modal: this.state.modal
        });
    }

    constructor(props) {
        super(props);
        this.state = { opPackaging: [], operators: [], UnitBases: [], loading: true, modal: false, page: 0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOper = this.handleOper.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderOpPortTable(opPackaging, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Unit Name</th>
                            <th>System Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opPackaging.map((opunit) =>
                            <tr key={opunit.opkId}>
                                <td>{opunit.opkOpPack}</td>
                                <td>{opunit.opkSysPack}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={opunit.unbId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <Button color="danger" onClick={hDelete} value={opunit.unbId}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var opPackaging = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "boxShadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.opPackaging].sort(
                (a, b) => a.opkOpPack.localeCompare(b.opkOpPack));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opPackaging = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opPackaging = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmOpPackaging.renderOpPortTable(opPackaging, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h1 id="tabelLabel" >Packaging (Member) &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                        </div>
                        <div class="col-lg-8 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-5">
                                        <Label for="fltCat">
                                            Operator Name
                                        </Label><br />
                                        <select onChange={(e) => this.handleOper(e.target.value)}
                                            defaultValue={AdmOpPackaging.fltOperator}>
                                            <option value="0">--Select--</option>
                                            {this.state.operators.map((operator) =>
                                                <option value={operator.oprId}>{operator.oprName}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Unit Conversion</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modUnitName">
                                Member Name
                            </Label>
                            <Input onChange={(e) => AdmOpPackaging.modUnitName = e.target.value}
                                id="modUnitName"
                                type="text"
                                defaultValue={AdmOpPackaging.modUnitName}
                            />
                            <Label for="modSystem" style={{ "paddingTop": "15px" }}>
                                System Name
                            </Label>
                            <Input onChange={(e) => AdmOpPackaging.modSystem = e.target.value}
                                id="modSystem"
                                type="select"
                                defaultValue={AdmOpPackaging.modSystem}>
                                <option value="KGR">KGR</option>
                                <option value="LTR">LTR</option>
                                <option value="PC">PC</option>
                            </Input>

                            <Label for="modQua" style={{ "paddingTop": "15px" }}>
                                Quantity
                            </Label>
                            <Input onChange={(e) => AdmOpPackaging.modQua = e.target.value}
                                id="modQua"
                                type="number"
                                defaultValue={AdmOpPackaging.modQua}
                            />
                            <Input onChange={(e) => AdmOpPackaging.modIsItem = e.target.checked}
                                id="modIsItem"
                                type="checkbox"
                                style={{ "marginTop": "17px", "background-color": "#1d5cf2", "scale": "1.2" }}
                                defaultChecked={AdmOpPackaging.modIsItem}
                            />  &nbsp; &nbsp;
                            <Label for="modIsItem" style={{ "paddingTop": "15px" }}>
                                Is Product
                            </Label>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmOpPackaging.modId}>
                            { (AdmOpPackaging.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async opPackagingGet() {
        const token = await authService.getAccessToken();
        const response2 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbOperators = await response2.json();

        const response = await fetch('mar1/OpPackagings/'+this.fltOperator, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbopPackaging = await response.json();
        return { opPackaging: dbopPackaging, operators: dbOperators };
    }

    async populateData() {
        const data = await AdmOpPackaging.opPackagingGet();
        const dbopPackaging = data.opPackaging;
        const dboperators = data.operators;
        this.setState({
            opPackaging: dbopPackaging, operators: dboperators, loading: false, modal: this.state.modal
        });
    }

}

