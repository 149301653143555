import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink
    , UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu4.css';

export class NavMenu4 extends Component {
    static displayName = NavMenu4.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = { collapsed: true, isAuth: false, loading: true };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }


    render() {
        var val = sessionStorage.getItem("memberFront");
        var path = "/" + val;
        val = val.charAt(0).toUpperCase() + val.substring(1);
        return (
            <header style={{ background: "beige" }} >
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to={path}>{val} Marine Reports</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to={path}>Home</NavLink>
                            </NavItem>

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav style={{ color: "black" }}>
                                    Requests
                                </DropdownToggle>
                                <DropdownMenu start>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/rep-ports-qua">Requests</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/rep-ports-qua">Messages</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/rep-ports-qua">Create User</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav style={{ color: "black" }}>
                                    Prices & Reports
                                </DropdownToggle>
                                <DropdownMenu start>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/Proforma">Proforma</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/rep-ports-qua">Report</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav style={{ color: "black" }}>
                                    Imports
                                </DropdownToggle>
                                <DropdownMenu start>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/upload">Excel Import</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/import">Raw Data</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/archive">Invoices List</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav style={{ color: "black" }}>
                                    Basic Data
                                </DropdownToggle>
                                <DropdownMenu start>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/suppliers">Suppliers</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/ports">Ports</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="mem-pages/unitbases">Unit Conversion</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/items">Item Names</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/operator">My Data</NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/vessels">Vessels</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/mem-pages/headers">Headers</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <LoginMenu>
                            </LoginMenu>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );


    }
}
