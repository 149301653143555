import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Pagination, PaginationItem, PaginationLink }   from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 10;
export class AdmRegUsers extends Component {
    static displayName = AdmRegUsers.name;

    constructor(props) {
        super(props);
        this.state = { regUsers: [], loading: true, modal: false, page: 0 };
    }

    componentDidMount() {
        this.populateData();
    }

    static renderregUsersTable(users, hModal, hDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Member</th>
                            <th>Email</th>
                            <th>Telephone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) =>
                            <tr key={user.id}>
                                <td>{user.fullName}</td>
                                <td>{user.member} </td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() {
        var users = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        if (!this.state.loading) {
            const sorted = this.state.regUsers;
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                users = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                users = sorted;
            }
        }
        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
        : AdmRegUsers.renderregUsersTable(users, this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            paginationItems = Array(pages).fill('').map((i, index) => (
                <PaginationItem active={page === index}>
                    <PaginationLink tag="button" onClick={() => this.setState({ page: index })}>{index + 1}</PaginationLink>
                </PaginationItem >
            ));
        }

        console.log("rendering");
        return (
            <div>
                <h1 id="tabelLabel" >Registered Users &nbsp;</h1>
                <p>The registered users are given access to this application.</p>
                {contents1}
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button"
                        onClick={() => this.setState({ page: 0 })} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button"
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button"
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button"
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>
            </div>
        );
    }

    static async RegUserGet() {

        const token = await authService.getAccessToken();
        const response = await fetch('mar1/regUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbregUsers = await response.json();
        return { regUsers: dbregUsers }
    }

    async populateData() {
        const data = await AdmRegUsers.RegUserGet();
        const dbregUsers = data.regUsers;

        this.setState({
            regUsers: dbregUsers, 
            loading: false, modal: this.state.modal
        });
    }

}

