import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
    } from 'reactstrap';

// const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function
const pageSize = 10;

export class MemHeaders extends Component {
    static displayName = MemHeaders.name;

    static modId = 0;
    static modSystemRow = 0;
    static modName = "";
    static input1 = "";
    //

    static async newheadsGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/MemNewExcs', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbheaders = await response.json();
        return dbheaders;
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                // check filter -- only on Admin




                // find new headers
                const newheads = await MemHeaders.newheadsGet();
                if (newheads.length === 0) {
                    alert("There are no new Headers available");
                    return;
                }
                MemHeaders.modId = 0;
                MemHeaders.modSystemRow = newheads[0].xsrId;
                MemHeaders.modName = "";
                MemHeaders.input1 =
                    <td><select onChange={(e) => MemHeaders.modSystemRow = e.target.value}
                        id="modSysRow" >
                    {newheads.map((head) =>
                        <option value={head.xsrId}>{head.xsrName}</option>)}
                    </select></td>
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.opHeaders.find(x => x.xmrId === parseInt(val));
                MemHeaders.modId = myrec.xmrId;
                MemHeaders.modSystemRow = myrec.xmrSystemRow;
                MemHeaders.modName = myrec.xmrName;
                MemHeaders.input1 =
                    <Input
                        id="modSysRow"
                        type="text"
                        value={myrec.xmrSystemName }
                />
                this.setState({ modal: true });
            }
        }
        else if (val !== "-1") {
            if (val !== "0") {
                const res = await MemHeaders.headSave();
                if (res.result !== "ok") {
                    alert("Error: " + res.result);
                    return;
                } else {
                    alert("Header updated successfully");
                }
                const ret = await MemHeaders.refreshData();
                this.setState({ opHeaders: ret.opHeaders, loading: false, modal: false });
            } else {
                const res = await MemHeaders.headAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Header created successfully");
                }
                const ret = await MemHeaders.refreshData();
                this.setState({ opHeaders: ret.opHeaders, loading: false, modal: false });
            }
        }
        else {
            this.setState({ modal: false });
        }
            
    }

    static async headSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/memheaders/' + MemHeaders.modId;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    XmrId: MemHeaders.modId,
                    XmrSystemRow: MemHeaders.modSystemRow, XmrName: MemHeaders.modName
                }
            )
        };
        const res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", status: res.status };
        return { result: "ok", status: ""};
    }

    static async headAdd() {
        if (MemHeaders.modMemName === "") {
            return { result: "error", reason: "Header Name cannot be blank" };
        }
        const token = await authService.getAccessToken();
        var callString = 'mem1/memheaders';
        const requestOptions = {
            method: 'POST',     
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {   
                    XmrSystemRow: MemHeaders.modSystemRow,
                    XmrName: MemHeaders.modName
                }       
            )
        };  
        const res = await fetch(callString, requestOptions);
        if (!res.ok)
            return { result: "error", status: res.status };
        return { result: "ok", status: "" };
    }

    static async headDel(id) {  
        const token = await authService.getAccessToken();
        var callString = 'mem1/memheaders/' + id;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        };  
        await fetch(callString, requestOptions);
    } 

    async handleDelete(event) 
    {
        if ( window.confirm("Are you sure you want to delete this item?") === true )
        {
            let id = event.target.value;
            await MemHeaders.headDel(id);
            const ret = await MemHeaders.refreshData();
            this.setState({ opHeaders: ret.opHeaders, loading: false, modal: false });
        }
    }

    // handle filter
    // only Admin








    constructor(props) {
        super(props);
        this.state = { opHeaders: [], loading: true, modal: false, page:0 };
        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }

    componentDidMount() {
        this.populateData();
    }

    static renderHeadersTable(opHeaders, hModal, hDelete) {
        const sorted = [...opHeaders].sort((a, b) => a.xmrName.localeCompare(b.xmrName));
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>System Name</th>
                            <th>My Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((head) =>
                            <tr key={head.XmrId}>
                                <td>{head.xmrSystemName}</td>
                                <td>{head.xmrName}</td>
                                <td colSpan={2}>
                                    <Button color="primary" onClick={hModal} value={head.xmrId}>
                                        Edit
                                    </Button>  &nbsp;&nbsp;&nbsp;
                                    <Button color="danger" onClick={hDelete} value={head.xmrId}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    
    render() { 
        var opHeaders = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "boxShadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.opHeaders].sort(
                (a, b) => a.xmrSystemName.localeCompare(b.xmrSystemName));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opHeaders = sorted.slice((page) * pageSize, (page + 1) * pageSize);
            } else {
                opHeaders = sorted;
            }
        }

        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : MemHeaders.renderHeadersTable(opHeaders, this.handleModal, this.handleDelete);

        if ((!this.state.loading) && (pages >= 1))
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc + 1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                <PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1 id="tabelLabel" >Headers (per Member) &nbsp;
                                <Button color="success" onClick={this.handleModal} value={-1}
                                    style={{ "fontSize": "20px", "fontWeight": "bolder", "height": "auto" }}>+
                                </Button>
                            </h1>
                            <p>The headers of the Member Excel File</p>
                        </div>
                    </div>
                </div>


















                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages - 1, page + 1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })} />  </PaginationItem>
                </Pagination>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Header</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modSysRow">
                                System Name
                            </Label>
                            {MemHeaders.input1}
                            <Label for="modName" style={{ "padding-top": "15px" }}>
                                My Name
                            </Label>
                            <Input onChange={(e) => MemHeaders.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={MemHeaders.modName}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={MemHeaders.modId}>
                            { (MemHeaders.modId === 0)? "Add New" :"Apply Changes"}
                        </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async refreshData(modal) {
        const data = await MemHeaders.headGet();
        const dbopHeaders = data.opHeaders;
        return {
            opHeaders: dbopHeaders
        };
    }

    static async headGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/MemHeaders/ext', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }); 
        const dbopheaders = await response.json();

        // operators

        // only aui


        return { opHeaders: dbopheaders };
    }

    async populateData() {
        const data = await MemHeaders.headGet();

        const dbopheaders = data.opHeaders;
        this.setState({
            opHeaders: dbopheaders,
            loading: false, modal: this.state.modal
        });
    }

}

