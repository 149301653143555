import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Button, Label, Input } from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms)); //the famous delay function
export class MemOperator extends Component {
    static displayName = MemOperator.name;

    static frmId = 0;
    static frmName = "";
    static frmContact = "";
    static frmTelaphone = "";
    static frmEmail = "";
    static frmShortName = "";
    static frmImo = "";
    static frmRoad = "";
    static frmCity = "";

    static async operatorSave() {
        const token = await authService.getAccessToken();
        var callString = 'mem1/MemOperator';
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    oprId: MemOperator.frmId,
                    oprName: MemOperator.frmName,
                    oprContact: MemOperator.frmContact,
                    oprTelephone: MemOperator.frmTelephone,
                    oprEmail: MemOperator.frmEmail,
                    oprShortName: MemOperator.frmShortName,
                    oprImo: MemOperator.frmImo,
                    oprRoad: MemOperator.frmRoad,
                    oprCity: MemOperator.frmCity
                }
            )
        };
        fetch(callString, requestOptions)
            .then(response => {
                response.json();
                if (!response.ok) {
                    console.log(response.status);
                    return "error";
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                return "error";
            });
        alert("Data Saved");
        return "ok";
    }

    handleSave()
    {
        MemOperator.operatorSave();
    }

    constructor(props) {
        super(props);
        this.state = { loading: true, modal: false };
    }

    componentDidMount() {
        this.populateData();
    }
    
    render() {
        if (this.state.loading) {
            return <p><em>Loading...</em></p>;
        }
        console.log("rendering " + MemOperator.frmName);
        return (
            <div class="container">
                <div class="row">
                    <h1 id="tabelLabel" >Member Info &nbsp; </h1>
                    <p>This is your Data!</p>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <Label for="frmName">
                            Name
                        </Label>
                        <Input onChange={(e) => MemOperator.frmName = e.target.value}
                            id="frmName"
                            type="text"
                            defaultValue={MemOperator.frmName}
                        />
                    </div>
                        <div class="col-lg-6">
                            <Label for="frmContact">
                                Contact
                            </Label>
                            <Input onChange={(e) => MemOperator.frmContact = e.target.value}
                                id="frmContact"
                                type="text"
                                defaultValue={MemOperator.frmContact}
                            />
                        </div>
                        <div class="col-lg-6">
                            <Label for="frmTelephone">
                                Telephone
                            </Label>
                            <Input onChange={(e) => MemOperator.frmTelephone = e.target.value}
                                id="frmTelephone"
                                type="text"
                                defaultValue={MemOperator.frmTelephone}
                        />
                        </div>
                        <div class="col-lg-6">
                            <Label for="frmEmail">
                                    Email
                                </Label>
                                <Input onChange={(e) => MemOperator.frmEmail = e.target.value}
                                    id="frmEmail"
                                    type="text"
                                    defaultValue={MemOperator.frmEmail}
                                />
                        </div>
                        <div class="col-lg-6">
                                <Label for="frmShortName">
                            Short Name
                        </Label>
                                <Input disabled onChange={(e) => MemOperator.frmShortName = e.target.value}
                                    id="frmShortName"
                                    type="text"
                                    defaultValue={MemOperator.frmShortName}
                                />
                        </div>
                        <div class="col-lg-6">
                            <Label for="frmImo">
                                IMO
                            </Label>
                            <Input onChange={(e) => MemOperator.frmImo = e.target.value}
                                id="frmImo"
                                type="text"
                                defaultValue={MemOperator.frmImo}
                            />
                        </div>
                        <div class="col-lg-6">
                            <Label for="frmRoad">
                                Road
                            </Label>
                            <Input onChange={(e) => MemOperator.frmRoad = e.target.value}
                                id="frmRoad"
                                type="text"
                                defaultValue={MemOperator.frmRoad}
                            />
                        </div>
                        <div class="col-lg-6">
                            <Label for="frmCity">
                                City
                            </Label>
                            <Input onChange={(e) => MemOperator.frmCity = e.target.value}
                                id="frmCity"
                                type="text"
                                defaultValue={MemOperator.frmCity}
                            />
                        </div>
                </div>
                <br />
                <br />
                <div class="row">
                    <div class="col-lg-6">
                        <Button onClick={this.handleSave}>Save</Button>
                    </div>
                </div>
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('mem1/MemOperator', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperator = await response.json();
        MemOperator.frmId = dboperator.oprId;
        MemOperator.frmName = dboperator.oprName;
        MemOperator.frmContact = dboperator.oprContact;
        MemOperator.frmTelephone = dboperator.oprTelephone;
        MemOperator.frmEmail = dboperator.oprEmail;
        MemOperator.frmShortName = dboperator.oprShortName;
        MemOperator.frmImo = dboperator.oprImo;
        MemOperator.frmRoad = dboperator.oprRoad;
        MemOperator.frmCity = dboperator.oprCity;
        this.setState({ loading: false, modal: this.state.modal });
    }

}

